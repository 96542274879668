<template>
  <div>
    <div class="container" >

      <button @click="goBack()" v-if="selectedPart === 'all'">
        <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
      </button>

      <h3>{{selectedEntity.entity_name}} : {{selectedEntity.entity_id}}</h3>

      <b-card-group fluid v-if="selectedPart === 'all'" >
        <menu-entry v-for="item in menuItems" :title="item.title" :description="item.description" :img-name="item.imgName" :key="item.id"  @entryClicked="entryClicked"></menu-entry>
      </b-card-group>

      <customers :entity-id="selectedEntity.id" :user-type="'book-keeper'" v-if="selectedPart==='customers'" @goToBookKeeperMain="selectedPart = 'all'"></customers>
      <suppliers :entity-id="selectedEntity.id" :user-type="'book-keeper'" v-if="selectedPart==='suppliers'" @goToBookKeeperMain="selectedPart = 'all'"></suppliers>
      <accountant-docs v-if="selectedPart==='documents'" @goToBookKeeperMain="selectedPart = 'all'"></accountant-docs>
      <employees :entity-id="selectedEntity.id" v-if="selectedPart==='employees'" @goToBookKeeperMain="selectedPart = 'all'"></employees>
    </div>

  </div>
</template>

<script>
import Customers from "@/screens/Income/IncomeManagement.vue"
import Suppliers from "@/screens/Procurement/ProcurementManagement.vue"
import Employees from "@/screens/Employees.vue"
import AccountantDocs from "@/components/bookkeepers/AccountantDocs.vue"
import MenuEntry from "@/components/bookkeepers/MenuEntry.vue"
import {useAuthStore} from "@/stores/auth"
export default {
  name: "BookKeepersMain",

  components: {
    Employees,
    Customers,
    Suppliers,
    AccountantDocs,
    MenuEntry
  },

  props: {
    selectedEntity: Object
  },

  data(){
    return{
      selectedPart: 'all',
      authStore: useAuthStore(),
      menuItems: [
        {id: 0, title: 'Customers', description: 'Manage Your Customers', imgName: 'customers-icon.jpg'},
        {id: 1, title: 'Suppliers', description: 'Manage Your Suppliers', imgName: 'suppliers-icon.jpg'},
        // {id: 2, title: 'Documents', description: 'Manage Your Documents', imgName: 'documents-icon.jpg'},
        {id: 3, title: 'Employees', description: 'Manage Your Employees', imgName: 'employees.png'},
      ]
    }
  },

  mounted() {
    console.log(this.authStore.getUserData.entityId)
  },

  methods: {
    entryClicked(args){
      this.selectedPart = args.toLowerCase()
    },
    goBack(){
      this.$emit('backToEntities')
    }
  }
}
</script>


<style>
.card-img, .card-img-top, .card-img-bottom {
  width: 50%;
  margin-bottom: 20px;
}
</style>