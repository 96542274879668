<template>
  <div class="docz-component">
    <change-password v-if="showPasswordModal" :selected-user="selectedUser" @closeModal="showPasswordModal = false"></change-password>
    <div class="table-area">
      <table id="datatable" class="table table-hover non-editable"
      ></table>
    </div>

  </div>
</template>

<script>
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Swal from "sweetalert2";
import $ from "jquery";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faUserSecret,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import Vue from "vue";
import BootstrapVue, { BootstrapVueIcons } from "bootstrap-vue";

/* add icons to the library */
library.add(faUserSecret, faArrowUpFromBracket);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;


//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-dt";

import authDal from "@/dal/auth-dal"
import UserGroupDal from "@/dal/user-group-dal"
import UserDal from "@/dal/user-dal";
import { useLoadStore } from "@/stores/load"
import { useUserStore } from "@/stores/user";
import {useAuthStore} from "@/stores/auth"
import UserForm from "@/components/users/UserForm.vue"
import UserOtpQrCode from "@/components/users/UserOtpQrCode.vue";
import ChangePassword from "@/components/users/ChangePassword.vue"
import userDal from "@/dal/user-dal";

export default {
  name: "SystemUsers",
  components: {
    UserForm,
    ChangePassword
  },
  props:{
    userId: String
  },
  data() {
    return {
      showPasswordModal : false,
      selectedUser: null,
      storeLoad: useLoadStore(),
      userStore: useUserStore(),
      authStore: useAuthStore(),
      allUsersData: null
    };
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getUserList(0);
  },
  methods: {
    getUserList(mode) {
      if(this.authStore.getUserData.userType === "account-manager"){
        UserDal.getUsersListByAccount(this.authStore.getUserData.accountId).then( data=>{
          this.createUsersGrid(data, mode)
        })
      }else{
        UserDal.getUsersList().then((data) => {
          this.createUsersGrid(data, mode)
        })
      }
    },

    createUsersGrid(data, mode){

      if(data.result !== 'SUCCESS'){
        this.storeLoad.setVisible(false);
        return
      }

      this.allUsersData = data.users.users;
      let dataColumns = [];
      let dataRows = [];
      data.users.columnList.forEach((title) => {
        dataColumns.push({
          title: title
              .split("_")
              .join(" ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
              ),
        });
      });
      dataColumns.push({ title: "" }); // add column for buttons

      this.allUsersData.forEach((row) => {

        if(this.userId == "0" || row.id == this.userId){
          let dataRow = [];
          data.users.columnList.forEach((value) => {
            dataRow.push(row[value]);
          });
          dataRows.push(dataRow);
        }
      });

      this.storeLoad.setVisible(false);

      if (mode === 1) {
        $("#datatable").DataTable().clear().rows.add(dataRows).draw();
      } else {
        let that = this;
        $("#datatable").DataTable({
          data: dataRows,
          columns: dataColumns,
          pageLength: 5,
          stateSave: true,
          columnDefs: [
            {
              targets: -1,
              width: "120px",
              render: function (data, type, row) {
                return `<button class="icon-btn grid-btn" value="edit"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                          <button class="icon-btn grid-btn" value="password"><i class='fa fa-lock' style='font-size: 16px; color: #334249'></i></button>
                          <button class="icon-btn grid-btn" value="deactivate"><i class='fa fa-times-circle' style='font-size: 16px; color: #334249'></i></button>
                          <button class="icon-btn grid-btn" value="qrcode"><i class='fa fa-qrcode' style='font-size: 18px; color: #334249'></i></button>`;
              },
            },
            // {
            //   targets: 6,
            //   render: function (data, type, row) {
            //     return `<a class="grid-link" style="color: red" href="#">${row[6]}</a>`;
            //   },
            // },
            {
              targets: [ 10, 11,12,13],
              visible: false,
              searchable: false,
            },
          ],
          drawCallback: function (settings) {
              that.initButtons();
          },
          "createdRow": function( row, data, dataIndex){
            if( data[10] == 0){
              // $(row).removeClass('odd');
              Array.prototype.slice.call(row.cells).forEach( (cell) => {
                $(cell).addClass('redClass');
              })
            }
          },
          dom: "Blfrtip",
          buttons: [
            "copy",
            "csv",
            "print",
            "excel",
            {
              text: "New User",
              action: function (e, dt, node, config) {
                that.proceedAction("add", 0);
              },
            },
          ],
          bDestroy: true,
        });
      }
    },

    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        if(!element.classList.contains('attached-listener')){
          element.addEventListener("click", function (event) {
            that.proceedAction(
                element.value,
                event.target.closest("tr").cells[0].innerText
            );
          });
        }

        element.classList.add('attached-listener');
      });

      let allLinks = document.getElementsByClassName('grid-link')
      Array.from(allLinks).forEach(element => {
        element.addEventListener('click', (event)=>{
          that.openUserGroup(event.target.closest("tr").cells[0].innerText)
        })
      })
    },
    proceedAction(actionType, id) {
      let that = this
      this.selectedUser = that.allUsersData.find((user) => user.id == id);

      let modalTitle = (() => {
        switch (actionType){
          case 'add':
            return 'Add New User'
            break
          case 'edit':
            return 'Edit User ' + this.selectedUser.user_name
            break
          case 'deactivate':
            return 'Deactivate User ' +  this.selectedUser.user_name
            break
        }
      })()

      switch (actionType){
        case 'password':
          this.showPasswordModal = true
          break
        case 'deactivate':
          Swal.fire({
            title: modalTitle,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Update",
            html: ''
          }).then((result) => {
            if (result.isConfirmed) {
              UserDal.deactivateUser(id)
                  .then(data => {
                    if(data.result === 'SUCCESS'){
                      Swal.fire(`User ${this.selectedUser.user_name} deactivated successfully `,'', 'success')
                          .then( () => that.getUserList(1))
                    }else{
                      Swal.fire(data.message, '', 'error')
                    }
                  })
            }
          })
          break
        case 'qrcode':
          let dataUrl = null
          userDal.getUserQrCode(id)
              .then(data => {
                if(data.result === 'SUCCESS'){
                  dataUrl = data.data.dataURL
                  Swal.fire({
                    title: `User ${this.selectedUser.user_name} QR-code`,
                    html: `<div id="modal-content"></div>`,
                    willOpen: () => {
                      let ComponentClass = Vue.extend(
                          Vue.component("UserOtpQrCode", UserOtpQrCode)
                      );
                      let instance = new ComponentClass({
                        propsData: { imageUrl: dataUrl },
                      });

                      instance.$mount();
                      document.getElementById("modal-content").appendChild(instance.$el);
                    }
                  })
                }else{
                  Swal.fire(data.message, '', 'error')
                }
              })
          break
        case 'edit':
        case 'add':
          Swal.fire({
            title: modalTitle,
            showDenyButton: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: "Update",
            html: '<div id="modal-content"></div>',
            didOpen: () => {
              this.storeLoad.setVisible(true);
              if(this.authStore.getUserData.userType === "account-manager"){
                UserGroupDal.getAccountUserGroups(this.authStore.getUserData.accountId).then((data) => {
                  this.populateUserGroups(data, actionType)
                });
              }else{
                UserGroupDal.getUserGroupsList().then((data) => {
                  this.populateUserGroups(data, actionType)
                });
              }
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: modalTitle,
                html: `Please approve to ${actionType == 'edit' ? 'update' : 'add'} user`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Approve'
              })
                  .then(result => {
                    if(result.isConfirmed){
                      if(that.userStore.getUserObject.id !== 0){
                        this.storeLoad.setVisible(true);
                        UserDal.updateUserConfiguration(that.userStore.getUserObject).then(
                            (data) => {
                              this.storeLoad.setVisible(false);
                              if(data.result === 'SUCCESS'){
                                Swal.fire({
                                  title: actionType == 'edit'  ? 'User Update' : 'Create New User',
                                  html: actionType == 'edit'  ? 'Updated Successfully' : 'User Added Successfully',
                                  icon: 'success'
                                }).then(()=> {
                                  this.storeLoad.setVisible(true);
                                  this.getUserList(0);
                                })

                              }
                            }
                        )
                      }else{
                        UserDal.addNewUser(that.userStore.getUserObject).then(
                            (data) => {
                              if(data.result === 'SUCCESS'){
                                Swal.fire('SUCCESS', 'User created successfully !', 'success')
                                    .then(()=> {
                                      this.getUserList(0);
                                    })
                              }else{
                                Swal.fire('FAIL', 'User creation failed', 'error')
                              }

                            }
                        )
                      }
                    }
                  })
            }
          });
          break
        default:
          break
      }


    },

    populateUserGroups(data, actionType){
      this.selectedUser = this.selectedUser ? this.selectedUser : null;
      let ComponentClass = Vue.extend(
          Vue.component("UserForm", UserForm)
      );
      let instance = new ComponentClass({
        propsData: { selectedUser: this.selectedUser, userGroupsProps: data.userGroups.data, actionType },
      });

      instance.$mount();
      document.getElementById("modal-content").appendChild(instance.$el);
      this.storeLoad.setVisible(false);
    },

    openUserGroup(userId){
      this.selectedUser = this.allUsersData.find((user) => user.id == userId)
      this.$emit("openUserGroup", this.selectedUser.user_group_id);
    },
    goBack() {
      this.$emit("goBack");
    }
  },
};
</script>

<style scoped>
div.dt-buttons {
  text-align: left;
}

#input-user-otp-status{
  width: 20px;
}

.viewz-app-body h2 {
  font-size: 18px;
}
</style>
