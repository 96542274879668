<template>
<div style="padding: 30px;">
  <h3 style="margin-bottom: 30px; text-decoration: underline;">Edit profile permissions : {{ProfileName}}</h3>
  <div class="tree-div">
    <LiquorTree
        :data="ProfileConfig"
        :options="options"
        ref="tree"
        @node:selected="nodeSelected"
        @node:checked="nodeChecked"
        @node:unchecked="nodeChecked"
    ></LiquorTree>
  </div>

  <b-button class="form-button" type="submit" variant="primary" @click="saveConfiguration">Update Data</b-button>
  <b-button class="form-button" variant="secondary" @click="goBack">Back to profiles</b-button>
</div>
</template>

<script>
import LiquorTree from 'liquor-tree'
import Swal from "sweetalert2"
import {useLoadStore} from "@/stores/load"
import ProfileDal from "@/dal/profile_dal";

export default {
  name: "ProfileConfigTree",
  components: {
    LiquorTree
  },
  props:{
    ProfileConfig: Array,
    ProfileName: String,
    profileId: Number
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      options: {
        checkbox: true,
        parentSelect: true
      },
      checkedEntries: [],
      uncheckedEntries: []
    }
  },
  mounted() {
    this.getCurrentProfileState()
  },
  methods: {
    nodeSelected(node){
    },
    nodeChecked(node){
      this.ProfileConfig.forEach( parent => {
        parent.children.forEach( child => {
          if(child.id === node.id ){
            child.state.checked = node.states.checked
          }
        })
      })
    },
    saveConfiguration(){
      Swal.fire({
        title: `Please approve to update profile "${this.ProfileName}"`,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: 'Approve'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.getCurrentProfileState()
          this.storeLoad.setVisible(true)
          ProfileDal.updateProfileConf(this.profileId , this.checkedEntries.join('|') , this.uncheckedEntries.join('|'))
              .then( data => {
                this.storeLoad.setVisible(false)
                Swal.fire(data.result === 'SUCCESS' ? 'Profile updated successfully !' : 'Profile update failed . Please contact your system administrator ').then(()=> {
                  this.storeLoad.setVisible(true)
                  this.$emit('backToProfiles')
                })
              })
        }
      })

    },
    getCurrentProfileState(){
      this.checkedEntries = []
      this.uncheckedEntries = []
      this.ProfileConfig.forEach( parent => {
        parent.children.forEach( child => {
          if(child.state.checked){
            this.checkedEntries.push(child.id)
          }else{
            this.uncheckedEntries.push(child.id)
          }
        })
      })
    },
    goBack(){
      Swal.fire({
        title: 'Back to profile',
        html: 'Please confirm to back to the profiles list',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true
      })
          .then(result => {
            if(result.isConfirmed){
              this.storeLoad.setVisible(true)
              this.$emit('backToProfiles')
            }
          })
    }
  }
}

</script>

<style scoped>

.tree-div{
  max-height: 800px;
  overflow: auto;
}

.form-button{
  margin: 10px;
}

</style>