<template>
  <div>
    <nav-bar v-if="subMenuItems" :menu-items="subMenuItems" @goBack="hidePart()" @showPart="(part) => showPart(part)" :class="menuClass"></nav-bar>
<!--
    <system-users :user-id="currentUser" v-if="currentPart === 'users'" @goBack="hidePart()" @openUserGroup="openUserGroup" ></system-users>
    <system-user-groups :user-group-id="currentUserGroup" v-if="currentPart === 'user-groups'" @goBack="hidePart()" @openUser="openUser"></system-user-groups>
    <system-profiles v-if="currentPart === 'profiles'"  @goBack="hidePart()"></system-profiles>
    <accounts  v-if="currentPart === 'accounts'" @goBack="hidePart()"></accounts>
    <account-config v-if="currentPart === 'account-config'" @goBack="hidePart()"></account-config>
    <entities v-if="currentPart === 'entities'" @goBack="hidePart()"></entities>
    <docz v-if="currentPart === 'docz' || currentPart === 'procurement_data_on_boarding'" @goBack="hidePart()" ></docz>
    <customers v-if="currentPart === 'customers'" @goBack="hidePart()"></customers>
    <suppliers v-if="currentPart === 'suppliers'" @goBack="hidePart()"></suppliers>
-->

    <employees v-if="currentPart === 'employees'" @goBack="hidePart()" ></employees>
    <book-keepers v-if="currentPart === 'book-keepers'" @goBack="hidePart()"></book-keepers>

    <b-card-group  v-if="currentPart === ''" ref="cardGroup" >
      <menu-entry v-for="element in elements"
                  :key="element.name.toLowerCase()"
                  :title="element.desc"
                  :description="element.name"
                  @entryClicked="showPart(element.part)"
                  style="margin: 20px; border: 1px solid grey; border-radius: 0px"
      ></menu-entry>

    </b-card-group>

  </div>
</template>

<script>

import systemUsers from "@/screens/Settings/SettingsUsers.vue"
import systemUserGroups from "@/screens/Settings/SettingsUserGroups.vue"
import ProcurementDataOnboarding from "@/screens/Procurement/ProcurementDataOnboarding.vue";
import navBar from "@/components/NavBar.vue"
import Entities from "@/screens/Settings/SettingsEntities.vue"
import Accounts from "@/screens/Settings/SettingsAccounts.vue"
import Customers from "@/screens/Income/IncomeManagement.vue"
import Suppliers from "@/screens/Procurement/ProcurementManagement.vue"
import AccountConfig from "@/screens/Business/AccountConfig.vue"
import BookKeepers from "@/screens/Business/BookKeepers.vue";
import systemProfiles from "@/screens/Settings/SettingsProfiles.vue"
import MenuEntry from "@/components/bookkeepers/MenuEntry.vue"
import Employees from "@/screens/Employees.vue"
import LeftSideNavBar from "@/components/LeftSideNavBar.vue";

import ProfileDal from '@/dal/profile_dal'

import {useLoadStore} from "@/stores/load"
import {useAuthStore} from "@/stores/auth"
import Vue from "vue"
import BootstrapVue, {BootstrapVueIcons} from "bootstrap-vue"


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

export default {
  name: 'SettingsSystem',
  components: {
    Suppliers,
    Customers,
    Entities,
    Accounts,
    systemUsers,
    systemUserGroups,
    systemProfiles,
    navBar,
    AccountConfig,
    BookKeepers,
    ProcurementDataOnboarding,
    MenuEntry,
    Employees,
    LeftSideNavBar
  },
  data() {
    return {
      subMenuItems: null,
      menuClass: 'menu-closed',
      storeLoad: useLoadStore(),
      authStore: useAuthStore(),
      currentPart: '',
      currentUserGroup: 0,
      currentUser: "0",
      elements:[
        {
          "name":"Book-keepers",
          "desc":"book-keepers main",
          "part":"book-keepers"
        },
        {
          "name":"Employees",
          "desc":"Employees",
          "part":"employees"
        },
      ]
    }
  },

  methods:{
    menuItemClicked(args){

      if(this.$refs.cardGroup){
        if(this.$refs.cardGroup.className.includes('menu-closed')){
          this.$refs.cardGroup.className='menu-opened card-group'
          this.menuClass = 'menu-opened'
        }else{
          this.$refs.cardGroup.className='menu-closed card-group'
          this.menuClass = 'menu-closed'
        }
      }else{
        if(this.menuClass === 'menu-opened'){
          this.menuClass = 'menu-closed'
        }else{
          this.menuClass = 'menu-opened'
        }
      }
      if(args){
        this.subMenuItems = args
      }

    },
    showPart(part){

      if(this.currentPart !== part){
        this.currentPart = part
        this.menuItemClicked(null)
        let sidebar = document.querySelector(".sidebar");
        sidebar.classList.toggle("open");
      }
    },
    hidePart() {
      this.currentPart = ''
      this.currentUserGroup = 0
      this.currentUser = "0"
    },
    openUserGroup(userGroupId){
      this.currentUserGroup = userGroupId
      this.currentPart = 'user-groups'
    },
    openUser(userId){
      this.currentUser = userId
      this.currentPart = 'users'
    }
  }
}
</script>

<style scoped>

</style>