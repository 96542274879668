import { defineStore } from "pinia"

export const useUserStore = defineStore({
    id: 'userStore',
    state: () => ({
        userName: null,
        userObject: null
    }),
    getters:{
        getUserName:(state) => state.userName,
        getUserObject: (state) => state.userObject
    },
    actions:{
        setUserName(userName){
            this.userName = userName
        },
        setUserObject(userObject){
            this.userObject = userObject
        }
    }
})