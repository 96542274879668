<template>
  <div class="form-div">
    <b-form >
      <b-form-group id="input-group-user-group-name" label="User Group Name :" label-for="input-user-group-name" style="margin: 20px">
        <b-form-input type="text" id="input-user-group-name" v-model="form.user_group_name" placeholder="Enter user group-name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-user-group-type" label="User Group Type :" label-for="input-user-group-type">
        <b-form-select id="input-user-group-type" v-model="form.type_id" :options="userGroupTypes" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-profile" label="Profile Name :" label-for="input-user-group-profile">
        <b-form-select id="input-user-group-profile" v-model="form.profile_id" :options="userGroupProfiles" required></b-form-select>
      </b-form-group>
    </b-form>
    <b-button variant="primary" class="form-btn" @click="updateUserGroup">{{actionName}}</b-button>
    <b-button variant="outline-primary" @click="backToGroups">Cancel</b-button>
  </div>
</template>

<script>

import Swal from "sweetalert2"
import UserGroupDal from "@/dal/user-group-dal"
import { useLoadStore } from "@/stores/load"
export default {
  name: "UserForm",
  props:{
    SelectedUserGroup: Object,
    allProfiles: Array
  },
  mounted() {
    let that = this
    this.storeLoad.setVisible(true)

    //GenericDal.execGenericApi('GetUserGroupTypes',[])
    UserGroupDal.getUserGroupTypes()
        .then( data => {
          that.form.user_group_name = that.SelectedUserGroup.user_group_name
          that.form.type_name = that.SelectedUserGroup.type_name
          that.form.id = that.SelectedUserGroup.id
          that.form.type_id = that.SelectedUserGroup.type_id
          that.form.profile_id = that.SelectedUserGroup.profile_id
          that.actionName = that.SelectedUserGroup.id ? 'Update' : 'Create'
          that.userGroupProfiles = that.allProfiles
          that.userGroupTypes = data.data.map(groupType => { return {value: groupType.id , text: groupType.type_name }})
          that.storeLoad.setVisible(false)
        })
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      userGroupTypes: null,
      userGroupProfiles: null,
      actionName: '',
      form: {
        user_group_name: '',
        type_name: '',
        id: '',
        type_id: null,
        profile_id: null
      }
    }
  },
  methods:{
    backToGroups(){
      this.$emit('show-all')
    },
    updateUserGroup(){
      let that = this
      Swal.fire({
        title: 'Are you sure?',
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        // confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: that.form.id ? 'Yes, update it!' : 'Yes, create it!'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    }
  }
}
</script>

<style scoped>
  .form-div{
    text-align: left;
  }

  .form-btn{
    margin-right: 10px;
  }
</style>