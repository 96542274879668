import $ from "jquery";

export class entityManagerClass{
    getNewEntityModalHtml(){
        return `<input id="new-company-id" class="swal2-input required-input" placeholder="Company ID">
            <input id="new-company-name" class="swal2-input required-input" placeholder="Company Name">
            <input id="new-company-city-name" class="swal2-input required-input" placeholder="City Name">
            <input id="new-company-street-name" class="swal2-input required-input" placeholder="Street Name">
            <input id="new-company-house-number" class="swal2-input required-input" placeholder="House Number">
            <input id="new-company-zip-code" class="swal2-input" placeholder="ZIP Code">
            <input id="new-company-country-name" class="swal2-input required-input" placeholder="Country Name">
            <select style="width: 62%;height: 50px;border: 1px solid #d9d9d9;border-radius: 3px;margin-top: 24px;" id="new-company-type" class="required-input" placeholder="Company Type">
                <option value="" disabled selected >Company Type</option>
                <option value="private company">Private Company</option>
                <option value="public company">Public Company</option>
            </select>
            <label id="errorMessage" style="color: red"></label>`
    }

    preConfirmNewEntity(){
        let formCompleted = true
        Array.from(document.getElementsByClassName('required-input')).every((input,index) => {
            if(input.value === ''){
                input.style.borderColor = "red"
                $('#errorMessage').html(`Please Set ${input.getAttribute('placeholder')}`)
                formCompleted = false
                return false
            }else{
                input.style.borderColor = '#d9d9d9'
            }
            return true
        })

        if(!formCompleted){
            return false
        }else{
            $('#errorMessage').html('')
            return new Promise(function (resolve) {
                resolve([
                    $('#new-company-id').val(),
                    $('#new-company-name').val(),
                    $('#new-company-city-name').val(),
                    $('#new-company-street-name').val(),
                    $('#new-company-house-number').val(),
                    $('#new-company-zip-code').val(),
                    $('#new-company-country-name').val(),
                    $('#new-company-type').val()
                ])
            })
        }
    }
}