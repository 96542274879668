
<template>
  <figure class="lineTypes-area field-area">

    <h3 >Line Type</h3>
    <ul class="lineTypes-radio" v-if="lineTypes.length>0">
      <li v-for="lineType in lineTypes" :key="lineType.name">
        <input type="radio" :id="lineType.name" :value="lineType.id" name="line-types" v-model="selectedLineType">
        <label :for="lineType.name">{{lineType.name}}</label>
      </li>
    </ul>
  </figure>
</template>

<script>

import wizardDal from "@/dal/wizard_dal"
import {useClassificationStore} from "@/stores/classification"

export default {
  name: 'LineTypes',
  props: {
    existingLineType: String
  },
  data(){
    return {
      lineTypes : ['Expense', 'Asset', 'Mix'],
      selectedLineType: null,
      classificationStore: useClassificationStore()
    }
  },
  mounted() {
    wizardDal.getLineTypes().then(data=> {
      this.lineTypes =  data.data.map(x => {return {id: x.id , name: x.component_value}})

      if(this.existingLineType && this.existingLineType != ''){
        this.selectedLineType = this.existingLineType
      }
    })
  },
  watch: {
    selectedLineType: function (newVal, oldVal) {
      this.classificationStore.setLineType(newVal)
      this.classificationStore.resetClassificationData()
      this.$emit('line-type-selected' , this.lineTypes.find(x=>x.id === newVal))
    }
  }
}

</script>


<style scoped>

.main-wrapper{
  margin: 20px;
}

/* Style for horizontal radio buttons with labels on the left */
.horizontal-radio-list {
  list-style: none;
  display: flex;
}

.horizontal-radio-list li {
  display: flex;
  align-items: center;
  margin-right: 20px; /* Adjust the spacing between radio buttons */
}

.horizontal-radio-list label {
  margin-right: 5px; /* Adjust the spacing between label and radio button */
  white-space: nowrap; /* Prevent line breaks within labels */
}
.horizontal-radio-list input{
  margin-right: 50px;
}
</style>