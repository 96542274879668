import Vue from 'vue'
import App from './App.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'

const pinia = createPinia()
Vue.use(PiniaVuePlugin)

Vue.config.productionTip = false
Vue.config.devtools = true

export const eventBus = new Vue()

export const bus = new Vue()

new Vue({
  pinia,
  render: h => h(App),
}).$mount('#app')


