import axios from "axios";
import VueAxios from 'vue-axios'
import Vue from "vue";

axios.defaults.withCredentials = true
Vue.use(VueAxios, axios)

import env from '../env'

export class ApiDal {
    constructor() {
        this.config = {
            method: 'post',
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'application/json'
            },
        }
        this.url = env.getPortalUrl()
    }

    async callApi(method , api, keyName, dataParam){
        let resObject = {}
        this.config.method = method
        if(dataParam){
            this.config.data = JSON.stringify(dataParam)
        }
        this.config.url = `${this.url}${api}`
        try{
            let res = await Vue.axios(this.config)
            if (res.status !== 200) {
                resObject.result = 'FAILED'
                if(keyName){
                    resObject[keyName] = null
                }
                return resObject
            }

            if(keyName){
                resObject[keyName] = res.data
                resObject.result = res.data.result
                return resObject
            }else{
                return res.data
            }

        }catch (err) {
            console.log(err)
            resObject.result = 'FAILED'
            resObject[keyName] = null
            return resObject
        }
    }
}