<template>
  <div class="image-container" v-if="imageUrl">
    <img :src="imageUrl" :alt="altText">
  </div>
</template>

<script>
export default {
  name: 'UserOtpQrCode',
  props: {
    imageUrl: String,
  },
  data() {
    return {
      base64Code: "",
      altText: "QR Code"
    }
  },
  watch: {
    base64Code: function (val) {
      if (val) {
        this.imageUrl = this.convertBase64ToImage(val);
      }
    }
  },
  methods: {
    convertBase64ToImage(base64) {
      const image = new Image();
      image.src = base64;
      return URL.createObjectURL(this.base64ToBlob(base64));
    },
    base64ToBlob(base64) {
      const byteString = Buffer.from(base64.split(',')[1], 'base64').toString('ascii') //atob(base64.split(',')[1]);
      const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
  }
}
</script>

<style scoped>
.image-container {
  width: 60%;
  margin: auto;
  display: flex;
  justify-content: center;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}
</style>