import {ApiDal} from './api_dal'
class AuthDal extends ApiDal{
    async login(username, password) {
        return this.callApi('post', '/api/auth/login', null, {username, password})
    }

    async validateOtp(userId , otpCode){
        return this.callApi('post', '/api/auth/validateOtp', null, {userId , otpCode})
    }

    async updatePassword(userId, password) {
        return this.callApi('post', '/api/auth/update-password', null, {userId, password})
    }

    async logout(userName) {
        return this.callApi('post', '/api/auth/logout', null, {userName})
    }

    async signUpNewAccount(accountName, email, phoneNumber,vatNumber, accountType, cityName, streetName, houseNumber, firstName, lastName, password){
        return this.callApi('post', '/api/auth/signUpNewAccount', null, {accountName, email, phoneNumber,vatNumber, accountType, cityName, streetName, houseNumber, firstName, lastName, password})
    }

    async checkNewAccount(vatNumber){
        return this.callApi('post', '/api/auth/checkNewAccount', null, {vatNumber})
    }
}

export default new AuthDal()