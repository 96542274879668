
<template>
  <figure class="topPage">
    <h1 ><breadcrumbs  :bread-crumbs-data="breadCrumbsData" @breadCrumbMenuElementClicked="breadCrumbMenuElementClicked" @breadCrumbSubMenuElementClicked="breadCrumbSubMenuElementClicked"></breadcrumbs></h1>

    <ul class="userActionPage">
      <li  style="width: unset;" v-if="storeAuth.getUserData.userType === 'book-keeper' ">
        <select ref="available-entities" v-model="selectedEntity" style="border: 1px solid grey; border-radius: 7px">
          <option v-for="(entity,index) in availableEntities" :key="entity.id" :entity_id="entity.entity_id" :value="entity.id" :kkk="Number(entity.unclassified_accounts_count)" :unclassified_accounts_count="entity.unclassified_accounts_count" :class="{'unclassified_accounts': Number(entity.unclassified_accounts_count) != 0}">{{(entity.entity_name_en && entity.entity_name_en) != '' ? entity.entity_name_en : entity.entity_name}}</option>
        </select>
      </li>
      <li v-if="storeAuth.getUserData.userType === 'book-keeper' ">
        <div class="numberCircle">{{accountsWithInclassifiedGls}}</div>
      </li>
      <li>
        <button>
          <img src="@/assets/icons/search.svg" alt="icon for Search">
        </button>
      </li>
      <li>
        <button>
          <img src="@/assets/icons/Bell.svg" alt="icon for Notification">
        </button>
      </li>
      <li>
        <button>
          <i class="userFirstLetter">{{userAcronym}}</i>
        </button>
        <figure class="dropMenu">
          <a>Settings</a>
          <a @click="logOut">Logout</a>
        </figure>
      </li>
    </ul>

  </figure>
</template>

<script>
import Breadcrumbs from "@/components/page/Breadcrumbs.vue"
import Swal from "sweetalert2"
import AuthDal from "@/dal/auth-dal"
import EntityDal from "@/dal/entity_dal"
import { useAuthStore } from "@/stores/auth"
import {useLoadStore} from "@/stores/load"
import {useEntityStore} from "@/stores/entity"

export default {
  name: 'TopPage',
  components: {
    Breadcrumbs
  },
  props: {
    pageName: String,
    breadCrumbsData: Array,
    navigationMenuIsToggled: Boolean
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      storeAuth: useAuthStore(),
      entityStore : useEntityStore(),
      userAcronym: '',
      availableEntities: [],
      selectedEntity: null,
      accountsWithInclassifiedGls: 0
    }
  },
  watch: {
    "selectedEntity" : function( newVal, oldVal) {
      this.entityStore.setEntityId(newVal)
    }
  },
  mounted() {
    let firstName = this.storeAuth.getUserData.firstName
    let lastName = this.storeAuth.getUserData.lastName
    let matches = (firstName + ' ' + lastName).match(/\b(\w)/g)
    this.userAcronym = matches.join('')
    if(this.storeAuth.getUserData.userType === 'account-manager' || this.storeAuth.getUserData.userType === 'book-keeper'){
      this.getAvailableEntities()
    }
  },
  methods:{
    getAvailableEntities(){
      this.storeLoad.setVisible(true)
      EntityDal.getUserGroupEntities(this.storeAuth.getUserData.accountId, this.storeAuth.getUserData.userGroupId)
          .then(data => {
            this.availableEntities = data.entities.data
            this.accountsWithInclassifiedGls = data.entities.data.reduce((accumulator, currentValue) => {  return accumulator + Number(currentValue.unclassified_accounts_count) } ,0)

            this.selectedEntity =  this.availableEntities.length> 0 ? this.availableEntities[0].id : null
            this.storeLoad.setVisible(false)
          })
    },

    breadCrumbMenuElementClicked(args){
      this.$emit('breadCrumbMenuElementClicked', args)
    },

    breadCrumbSubMenuElementClicked(args){
      this.$emit('breadCrumbSubMenuElementClicked', args)
    },

    recalculateGlAccounts(){
      EntityDal.getUserGroupEntities(this.storeAuth.getUserData.accountId, this.storeAuth.getUserData.userGroupId)
          .then(data => {
            this.availableEntities = data.entities.data
            this.accountsWithInclassifiedGls = data.entities.data.reduce((accumulator, currentValue) => {  return accumulator + Number(currentValue.unclassified_accounts_count) } ,0)
          })
    },

    async logOut(){
      Swal.fire({
        title : 'Logout',
        html: 'Logout from ViewZ',
        icon: 'question',
        showCancelButton: true
      })
          .then(async result => {
            if (result.isConfirmed) {
              await AuthDal.logout(this.storeAuth.getUserName).then((response) => {
                this.storeAuth.setAuthorisation(!response.result === "SUCCESS");
              });
            }
          })
    }
  }
}
</script>


<style scoped>
.numberCircle {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 7px 1px 7px 1px;

  background: #fff;
  border: 2px solid #666;
  color: white;
  background-color: #816DF8;
  text-align: center;

  font: 16px Arial, sans-serif;
}

.unclassified_accounts{
  color: white;
  background-color: #816df8;
}
</style>