<template>
  <div class="DocumentsEmptyClass">
    <img src="@/assets/images/mainImage.png" alt="main image" style="height: 500px; width: unset">
    <h2 style="width: 100%; text-align: center">Documents lines classifications</h2>
    <p>Simply click a single or multiple lines from the detailed info table to easily classify the data.</p>
  </div>
</template>

<script>
export default {
  name: 'DocumentsEmptyClass',

}
</script>

<style scoped>

</style>