<template>
  <div class="DocumentDetails">


    <section class="part-section" v-if="mode === 'general-info-form'">
      <figure class="part-title" >
        <h4>Document General Info</h4>
      </figure>
      <document-general-info-form ref="documentGeneralInfoForm" @change-mode="changeMode" :selected-file-id="selectedFileId"></document-general-info-form>

      <figure class="part-footer">
        <b-button variant="primary" @click="approveGeneralInfoForm">Approve</b-button>
        <b-button variant="secondary"@click="cancelForm">Cancel</b-button>
      </figure>
    </section>


    <section class="part-section" v-if="mode === 'detailed-info-form'">
      <figure class="part-title" >
        <h4>Document Details Info</h4>
      </figure>
      <document-detailed-info-form ref="documentDetailedInfoForm" @change-mode="changeMode" :selected-file-id="selectedFileId" :sku-id="selectedSkuId"></document-detailed-info-form>

      <figure class="part-footer">
        <b-button variant="primary" @click="approveDetailedInfoForm">Approve</b-button>
        <b-button variant="secondary"@click="cancelForm">Cancel</b-button>
      </figure>
    </section>

    <section class="part-section" v-if="mode === 'all'">
      <figure class="part-title" >
        <h4>Document General Info</h4>
        <p :class="documentsStatus">{{documentsStatus}}</p>
      </figure>

      <document-general-info ref="documentGeneralInfo" @dataEdited="documentsStatus = 'Not Approved'" @document-approved="documentsStatus = 'Approved'" :selected-file-id="selectedFileId" @change-mode="changeMode"></document-general-info>
    </section>

    <section class="part-section" v-if="mode === 'all'">
      <figure class="part-title">
        <h4>Document Detailed Info</h4>
        <p :class="documentsStatus">{{documentsStatus}}</p>
      </figure>

      <document-detailed-info ref="documentDetailedInfo" @rowsSelected="rowsSelected" @edit-classification="editClassification"  @dataEdited="documentsStatus = 'Not Approved'" :selected-file-id="selectedFileId" :rows-approved="documentsStatus == 'Approved'" @change-mode="changeMode"></document-detailed-info>

      <figure class="part-footer">
        <b-button :variant="'primary'" @click="approveDetails" :disabled="documentsStatus !== 'Not Approved'">Approve</b-button>
        <b-button :variant="selectedRows.length>0 ? 'primary' : 'secondary'" :disabled="selectedRows.length === 0" class="next-btn" @click="showClassification">Start Classifying</b-button>
      </figure>
    </section>

  </div>
</template>

<script>
import DocumentGeneralInfo from "@/components/docs/DocumentGeneralInfo.vue"
import DocumentDetailedInfo from "@/components/docs/DocumentDetailedInfo.vue"
import DocumentGeneralInfoForm from "@/components/docs/DocumentGeneralInfoForm.vue"
import DocumentDetailedInfoForm from "@/components/docs/DocumentDetailedInfoForm.vue"
import {eventBus} from "@/main"
import Swal from "sweetalert2"
import docsDal from "@/dal/docs-dal"
import {useLoadStore} from "@/stores/load"
import {useClassificationStore} from "@/stores/classification"

export default {
  name: 'DocumentDetails',
  props: {
    selectedFileId: String
  },
  components: {
    DocumentDetailedInfo,
    DocumentGeneralInfo,
    DocumentGeneralInfoForm,
    DocumentDetailedInfoForm
  },
  data(){
    return {
      mode: 'all',
      selectedRows: [],
      selectedRowsIds: [],
      documentsStatus: 'Not Approved',
      storeLoad: useLoadStore(),
      classificationStore: useClassificationStore(),
      selectedSkuId: ''
    }
  },
  methods: {

    rowsSelected(rows){
      this.selectedRows = rows
      this.classificationStore.resetClassificationData()
      this.classificationStore.setSelectedRowsIds(Array.from(this.selectedRows).map(row => {return row.cells[0].getAttribute('row-id')}))
    },

    refreshDocumentDetails(){
      this.$refs.documentDetailedInfo.loadDocumentDetailedInfo()
    },

    approveDetails(){
      let that = this
      let generalInfoData = this.$refs.documentGeneralInfo.getDataRows()
      let detailedInfoData = this.$refs.documentDetailedInfo.getDataRows()

      let generalTotal = generalInfoData.length > 0  ? +generalInfoData[0][7] : -1
      if (generalTotal < 0 ){
        Swal.fire('Missing general info', 'Please add document general info !', "error")
      }else{

        let detailsTotal = detailedInfoData.length > 0 ? detailedInfoData.reduce((total, object) => {return total + (+object[9])},0) : -1
        if(detailsTotal < 0){
          Swal.fire('Missing details info', 'Please add document details info !', "error")
        }else{
          if(generalTotal != detailsTotal){
            Swal.fire('Unmatched Values', `The total value of general info (${generalTotal}) should be equal to the total sum of detailed info rows (${detailsTotal}) . Please check again !`, 'error')
          }else{
            Swal.fire({title: 'Approve document data', html: 'Please approve all data collected correctly', showCancelButton: true, icon: "question"})
                .then(result => {
                  if (result.isConfirmed) {
                    that.storeLoad.setVisible(true)
                    docsDal.approveDocumentInfo(this.selectedFileId)
                        .then(data => {
                          that.storeLoad.setVisible(false)
                          if(data.result === 'SUCCESS'){
                            Swal.fire('Data Approved !', 'Now you can classify document details . Select the details row to start' , 'success')
                                .then(()=> {
                                  this.documentsStatus='Approved'
                                  eventBus.$emit('detailsApproved')
                                  this.$emit('allDataApproved')
                                })
                          }else{
                            Swal.fire('Data Approve Fail !', "Can't approve the data . Please contact your system administrator" , 'error')
                          }
                        })


                  }
                })
          }
        }
      }
    },

    blinkAtom(id, text){
      this.$refs.documentGeneralInfo.blinkAtom(id, text)
    },

    highLightAtom(id){
      this.$refs.documentGeneralInfo.highLightAtom(id)
    },

    unLightAtom(id){
      this.$refs.documentGeneralInfo.unLightAtom(id)
    },

    unLightAllAtoms(){
      this.$refs.documentGeneralInfo.unLightAllAtoms()
    },

    editClassification(args){
      this.classificationStore.setSelectedRowsIds([args.toString()])
      this.$emit('showClassification', [args])
    },

    showClassification(args){
      console.log(this.selectedRowsIds)
      this.$emit('showClassification', this.selectedRowsIds)
    },

    approveGeneralInfoForm(){
      let that = this
      Swal.fire({
        title: 'Approve the data',
        html: 'Are you sure you want to save the data ?',
        icon: "question",
        showCancelButton: true
      }).then(result=>{
        if(result.isConfirmed){
          let form = that.$refs.documentGeneralInfoForm.getDocumentGeneralInfo()
          let validationResult = that.validateGeneralForm(form)
          if( validationResult !== ''){
            Swal.fire('Document data validation error', validationResult, 'error')
          }else{
            that.storeLoad.setVisible(true)
            docsDal.addDocumentGeneralInfo(this.selectedFileId , form.documentId, form.supplierId, form.documentTypeId, form.documentDate, form.documentStatusId, form.total, form.vat, form.currencyId, form.documentExpectedPaymentDate, 0)
                .then(data => {
                  that.storeLoad.setVisible(false)
                  this.mode = 'all'
                })
          }
        }
      })
    },

    approveDetailedInfoForm(){
      let that = this
      Swal.fire({
        title: 'Approve the data',
        html: 'Are you sure you want to save the data ?',
        icon: "question",
        showCancelButton: true
      }).then(result=>{
        if(result.isConfirmed){
          let form = that.$refs.documentDetailedInfoForm.getDocumentDetailsInfo()

          let validationResult = that.validateDetailsForm(form)
          if( validationResult !== ''){
            Swal.fire('Document data validation error', validationResult, 'error')
          }else{
            that.storeLoad.setVisible(true)
            docsDal.addDocumentDetailedInfo(this.selectedFileId , form.skuId, form.skuName, form.quantity, form.unitId, form.price, form.discount, form.currencyId, form.vat, form.total, form.po, 0)
              .then(data => {
                  that.storeLoad.setVisible(false)
                  this.mode = 'all'
                })
          }
        }
      })
    },

    cancelForm(){
      this.mode = 'all'
    },

    changeMode(args){
      this.mode = args.mode
      if(args.element){
        this.selectedSkuId = args.element
      }
    },

    validateGeneralForm(form){
      if(!form.supplierId){
        return 'Please select supplier name'
      }

      if(!form.documentTypeId){
        return 'Please select document type'
      }

      if(form.documentId == ''){
        return 'Please set document ID'
      }

      if(!form.documentDate){
        return 'Please set document date'
      }

      if(!form.documentStatusId){
        return 'Please select document status'
      }

      if(form.total == 0 || form.total == ''){
        return 'Please set document total value'
      }

      if(!form.currencyId){
        return 'Please select currency'
      }

      if(!form.documentExpectedPaymentDate){
        return 'Please set document expected payment date'
      }

      return ''
    },

    validateDetailsForm(form){
      if(!form.skuId){
        return 'Please set SKU Id'
      }

      if(!form.skuName){
        return 'Please set SKU Name'
      }

      if(form.quantity == ''|| form.quantity == 0){
        return 'Please Set the Quantity Value'
      }

      if(!form.unitId || form.unitId == 0){
        return 'Please Select Unit'
      }

      if(form.price == '' || form.price == 0){
        return 'Please Set the Price Value'
      }

      if(!form.currencyId){
        return 'Please select currency'
      }

      if(form.vat === ''){
        return 'Please Set the VAT Value'
      }

      if(form.total == '' || form.total == 0){
        return 'Please Set the Total Value'
      }

      return ''
    }
  }
}
</script>

<style scoped>
</style>