
<template>

  <figure class="breadcrumb-nav-area" style="padding-left: 0px">
    <ul class="breadcrumb"  style="font-size: 25px; font-weight: 600">
      <li v-for="(item,index) in breadCrumbsData" :key="item.text" >
        <a v-if="index != Object.keys(breadCrumbsData).length - 1" :menu_value="item.value" @click="elementClicked(index, item.value)">{{item.text}}</a>
        <p v-if="index === Object.keys(breadCrumbsData).length - 1" :menu_value="item.value">{{item.text}}</p>
      </li>
    </ul>
  </figure>

</template>

<script>

export default {
  name: 'Breadcrumbs',
  props:{
    breadCrumbsData: Array
  },
  methods: {
    elementClicked(index, value){
      if(index === 0){
        this.$emit('breadCrumbMenuElementClicked', value)
      }else{
        this.$emit('breadCrumbSubMenuElementClicked', value)
      }
    }
  }

}
</script>


<style scoped>
.viewz-app-body .breadcrumb-nav-area {
   border-top: none;
   border-bottom: none
}

</style>