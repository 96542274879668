
<template>
  <div class="DocumentGeneralInfo">
    <div v-if="false">
      <h4>Unmatched Fields :</h4>
      <table id="unMatchedDt" class="table table-hover non-editable data-table" style="width: 300px; margin-bottom: 40px"></table>

      <h4>Matched Fields :</h4>
    </div>
    <div class="table-area">
      <table id="generalInfoDt" class="table table-hover non-editable data-table" ></table>
    </div>

    <figure class="part-footer">
      <div>
        <input type="checkbox" id="is-one-time-doc"  v-model="isOneTime" style="width: 40px">
        <label for="is-one-time-doc">This is One-Time Document</label><br>
      </div>
    </figure>

  </div>
</template>

<script>
import $ from "jquery"
import moment from 'moment'
import {useEntityStore} from "@/stores/entity"
import docsDal from "@/dal/docs-dal"
import {useLoadStore} from "@/stores/load"


export default {
  name: "DocumentGeneralInfo",
  props: {
    selectedFileId: String
  },
  data(){
    return{
      dataColumns : [
        { title: 'Supp ID', type: 'select' , alias: 'supplier-name'},
        { title: 'Supplier Name'},
        { title: 'Doc Type' , type: 'select', alias: 'document-type'},
        { title: 'Doc Id' , type: 'text'},
        { title: 'Date' , type: 'date'},
        { title: 'Status' , type: 'select', alias: 'doc-validation'},
        { title: 'VAT' , type: 'number'},
        { title: 'Total' , type: 'number'},
        { title: 'Currency' , type: 'select', alias: 'currency'},
        { title: 'Expected Payment' , type: 'date'},
        { title: ''}
      ],
      isOneTime: false,
      table: null,
      unmatchedColumns:[],// [5,6,9]
      entityStore : useEntityStore(),
      docConfValues: [],
      storeLoad: useLoadStore(),
      dataRows: []
    }
  },

  mounted() {
    this.storeLoad.setVisible(true)
    docsDal.getDocumentConfValues(this.entityStore.entityId)
        .then(data=>{
          this.docConfValues = data.data

          docsDal.getDocumentGeneralInfo(this.selectedFileId)
              .then(data => {
                this.storeLoad.setVisible(false)
                //this.createUnMatchedDt()

                this.createGeneralInfoDt(data.data.length === 1 ? data.data[0] : null)
              })
        })
  },

  methods:{
    createUnMatchedDt(){
      let that = this
      let dataColumns = [
        {title: 'Source'},
        {title: 'Vat'},
        {title: 'Date'}
      ]

      let unmatchedDataRows = [['Source', '8.5', '25/03/2016']]

      $("#unMatchedDt").DataTable({
        data: unmatchedDataRows,
        columns: dataColumns,
        lengthChange: false,
        searching: false,
        "bInfo": false,
        "sDom": 'ft',
        columnDefs: [
          {
            targets: [0,1,2],
            render: function (data,type,row) {
              return `<div draggable="true">${data}</div>`
            }
          },
        ],
        drawCallback: function( settings ) {
          $("#unMatchedDt thead").remove();
        },
        createdRow: function ( row, data, dataIndex, cells ) {
          let rowCells = row.cells
          Array.from(rowCells).forEach(cell => {
            // $(cell).attr('draggable', 'true')
            $(cell).css('border', '1px solid black')
          })
        }
      })
    },

    createGeneralInfoDt(data){
      let that = this

      if(data && data.document_status == 1){
        this.$emit('document-approved')
      }

      this.dataRows = []
      if(data){
        let supplierEntityName = this.docConfValues.filter(x => {return x.type === 'supplier-name' && x.id == data.supplier_entity_id})[0].name
        let documentType = this.docConfValues.filter(x => {return x.type === 'document-type' && x.id == data.document_type_id})[0].name
        let documentCurrency = this.docConfValues.filter(x => {return x.type === 'currency' && x.id == data.currency})[0].name
        let documentValidation = this.docConfValues.filter(x => {return x.type === 'doc-validation' && x.id == data.document_validation_id})[0].name
        that.dataRows =  [[supplierEntityName.split('|')[1], supplierEntityName.split('|')[0], documentType, data.document_id, data.document_date, documentValidation, data.vat+'%', data.total, documentCurrency, data.expected_payment  ]]
      }

      let gridButtons = [
        "copy",
        "csv",
        "print",
        "excel"
      ]

      if(!data){
        gridButtons.push({
          text: 'Add Info',
          action: function (e, dt, node, config) {
            that.addDocumentInfo()
          }
        })
      }

      this.table = $("#generalInfoDt").DataTable({
        data: that.dataRows,
        columns: that.dataColumns,
        lengthChange: false,
        searching: false,
        ordering: false,
        "bInfo": false,
        bPaginate: false,
        "sDom": 'ft',
        columnDefs: [
          {
            targets: -1,
            width: '30px',
            render: function (data,type,row) {
              return `<button class="icon-btn-general grid-btn" value="edit" style="margin-right: 5px"><i class='fa fa-edit' style='font-size: 16px; color: #334249;'></i></button>`
            }
          },
          {
            targets: that.unmatchedColumns,
            render:function (data,type,row) {
              return ``
            }
          }
        ],
        drawCallback: function (settings) {
          let table = $("#generalInfoDt")
          let cells = table[0].rows[1].cells
          if(data){
            cells[0].setAttribute('selected-value',data.supplier_entity_id)
            cells[1].setAttribute('selected-value',data.document_type_id)
            cells[4].setAttribute('selected-value',data.document_validation_id)
            cells[7].setAttribute('selected-value',data.currency)
          }

          that.initButtons(!data)
        },
        createdRow: function ( row, data, dataIndex, cells ) {
          let rowCells = row.cells
          Array.from(rowCells).forEach((cell,index) => {
            if(that.unmatchedColumns.includes(index)){
              $(cell).addClass('dropzone')
              $(cell).css('background', '#deb4c3')
            }
          })
        },
        dom: "Blfrtip",
        buttons: gridButtons,
      })
    },

    initButtons(mode) {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn-general");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction( element.value )
        })
      })
    },

    proceedAction(value){
      if(value === 'edit'){
        this.addDocumentInfo()
      }
    },

    addDocumentInfo(){
      this.$emit('change-mode',{mode: 'general-info-form'})
    },

    isValidDate(dateString, format) {
      return moment(dateString, format, true).isValid();
    },

    highLightAtom(id){
      this.setCell(id, 'border', "3px solid red")
    },

    blinkAtom(id, text){
      this.blinkCell(id, 'animation', 'blinker 1s linear infinite', text)
    },

    unLightAtom(id){
      this.setCell(id,'border' ,  "none")
      this.setCell(id,'border-bottom' ,  "1px solid black")
    },

    unLightAllAtoms(){
      this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
        let rowNode = this.node();
        $(rowNode).find("td").each(function (index) {
          let cell = $(this)
          cell.css('border' ,'none' )
          cell.css('border-bottom' ,'1px solid black' )
        });
      });
    },

    setCell(celId , element, color){
      this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
        let rowNode = this.node();
        $(rowNode).find("td").each(function (index) {
          if(index === celId){
            let cell = $(this)
            cell.css(element ,color )
          }
        });
      });
    },

    blinkCell(celId , element, color, text){
      let that = this
      this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
        let rowNode = this.node();
        $(rowNode).find("td").each(function (index) {
          if(celId.includes(index)){
            let cell = $(this)
            cell.css(element ,color )
            cell.html('Click here to set !')
            cell[0].addEventListener('click',() => {
              cell.css(element,'none')

              cell.html(text)
              that.unBlinkAll()
            })
          }
        });
      });
    },

    unBlinkAll(){
      let that = this
      this.table.rows().every(function (rowIdx, tableLoop, rowLoop) {
        let rowNode = this.node();
        $(rowNode).find("td").each(function (index) {
          // if(this.unmatchedColumns.includes(index)){
          //   cell.html('')
          // }
          let cell = $(this)
          cell.css('background','none')

        })
      })
    },

    getDataRows(){
      return this.dataRows
    }
  }
}
</script>


<style scoped>
.data-table{
  font-size: 12px;
}

table.dataTable.no-footer {
  border-bottom: none;
}

.in-row-select{
  height: 35px;
}

</style>