<template>
  <div class="docz-component">
    <div class="table-area">
      <div class="datatable" ></div>
    </div>

    <customer-to-entities
        v-if="showEntities"
        :customer-name="selectedCustomerName"
        :account-id="authStore.getUserData.accountId"
        :customer-id="selectedCustomerId"
        @closeConnectEntitiesModal="showEntities = false"
        @entitiesConnected="entitiesConnected"
    ></customer-to-entities>
    <new-entity
        v-if="showNewEntity"
        @newEntityAdded="newEntityAdded"
        @closeNewEntityModal="showNewEntity = false"
        :company-vat-number="selectedVatNumber"
        :user-group-id="null"
        :parent-entity-id="entityId"
        :entity-type="'customer'"
    ></new-entity>

  </div>
</template>

<script>
import $ from "jquery"

import CustomerToEntities from "@/components/customers/CustomerToEntities.vue"
import NewEntity from "@/components/entities/NewEntity.vue"
import EditCistomer from "@/components/customers/EditCistomer.vue"

import EntityDal from "@/dal/entity_dal"
import CustomerDal from "@/dal/customer_dal"
import { useAuthStore } from "@/stores/auth"
import { useLoadStore } from "@/stores/load"
import { useEntityStore} from "@/stores/entity"
import Swal from "sweetalert2"

import {entityManagerClass} from "@/components/entities/entityManager"
import Vue from "vue";

export default {
  name: "Customers",
  components: {
    CustomerToEntities,
    NewEntity
  },
  props: {
    entityId: Number,
    userType: String
  },
  data(){
    return {
      showEntities: false,
      showNewEntity: false,
      selectedVatNumber: '',
      authStore: useAuthStore(),
      storeLoad: useLoadStore(),
      entityStore: useEntityStore(),
      allExistingCustomers: [],
      selectedCustomerName: '',
      selectedCustomerId: null,
      currentEntityId: this.entityId,
      entityManager: new entityManagerClass()
    }
  },
  watch: {
    entityId: function (newVal, oldVal) {
      this.storeLoad.setVisible(true)
      this.getCustomers(0)    }
  },

  mounted() {
    this.storeLoad.setVisible(true)
    this.getCustomers(0)
  },

  methods:{
    getCustomers(mode){
      if((this.userType ? this.userType : this.authStore.getUserData.userType) === 'account-manager'){
        EntityDal.getAccountCustomers(this.authStore.getUserData.accountId)
            .then( data => {
              this.createCustomersGrid(data, mode)
            })
      }else{
        const currentEntityId = this.entityStore.getCurrentEntity
        EntityDal.getEntityCustomers(this.authStore.getUserData.userId, currentEntityId).then( data => {
          this.createCustomersGrid(data, mode)
        })
      }
    },

    createCustomersGrid(data, mode){
      this.allExistingCustomers = data.entities.data
      let dataColumns = []
      let dataRows = []
      data.entities.columnList.forEach((title) => {
        dataColumns.push({
          title: title
              .split("_")
              .join(" ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
              ),
        });
      });
      dataColumns.push({ title: "" }); // add column for buttons
      data.entities.data.forEach((row) => {
        let dataRow = [];
        data.entities.columnList.forEach((value) => {
          dataRow.push(row[value]);
        });
        dataRows.push(dataRow);
      });
      let that = this
      $(".datatable").html("")
      $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable"></table>')

      $("#datatable").DataTable({
        data: dataRows,
        columns: dataColumns,
        pageLength: 10,
        columnDefs: [
          {
            targets: -1,
            width: "100px",
            render: function (data, type, row) {
              return `<button class="icon-btn grid-btn" value="edit"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                            ${that.entityId ? '' : "<button class='icon-btn grid-btn' value='link'><i class='fa fa-link' style='font-size: 16px; color: #334249'></i></button>" }
                          <button class="icon-btn grid-btn" value="delete"><i class='fa fa-trash' style='font-size: 16px; color: #334249'></i></button>  `;
            },
          },
          {
            targets: [6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21],
            visible: false,
            searchable: false,
          }
        ],
        drawCallback: function (settings) {
          that.initButtons()
        },
        dom: "Blfrtip",
        buttons: [
          "copy",
          "csv",
          "print",
          "excel",
          {
            text: "New Customer",
            action: function (e, dt, node, config) {
              that.setCustomerType()
            },
          },
        ],
        bDestroy: true,
      })
      this.storeLoad.setVisible(false)
    },

    setCustomerType(){
      let that = this
      let inputOptions = new Promise(function (resolve){
        resolve({
          'api': "By API",
          'manually': 'Manually Input'
        })
      })

      Swal.fire({
        title: "Add New Customer",
        input: "radio",
        inputOptions: inputOptions,
        showCancelButton: true,
        inputValue: 'api',
        inputValidator: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              resolve();
            } else {
              reject('You need to select something!');
            }
          }).catch(function(rej) {
            console.log(rej);
          });
        }
      }).then(function(result) {
        if(result.isConfirmed){
          if(result.value === 'api'){
            that.addCustomerByApi()
          }else{
            that.addCustomerManually()
          }
        }
      })
    },

    addCustomerManually(){
      let that = this
      Swal.fire({
        title: 'Add New Customer',
        html: this.entityManager.getNewEntityModalHtml(),
        showCancelButton: true,
        preConfirm: function() {return that.entityManager.preConfirmNewEntity()}
      }).then(function (result) {
        if(result.isConfirmed){
          let entityDetails = [result.value[0],result.value[1],'',result.value[7],'active','',null,null,null,null,null,null,result.value[2],result.value[3],result.value[4],result.value[5],null,result.value[6],null,null,null,null,that.authStore.getUserData.userId,null]

          that.storeLoad.setVisible(true)
          EntityDal.addEntity(entityDetails)
              .then(data => {
                CustomerDal.connectCustomersToEntity(data.entities.data.id, that.authStore.getUserData.accountId, that.entityId )
                    .then(data => {
                      that.storeLoad.setVisible(false)
                      if(data.result === 'SUCCESS'){
                        Swal.fire('Customer added successfully')
                            .then(() => {
                              that.storeLoad.setVisible(true)
                              that.getCustomers(0)
                            })
                      }else{
                        Swal.fire('Failed to add new customer . Please contact your system administrator')
                      }
                    })
              })
        }

      })
    },

    addCustomerByApi() {
      Swal.fire({
        title: 'Enter company registration number',
        input: 'text',
        inputLabel: 'Company registration number :',
        inputValue: '',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      }).then((result) => {

        if (result.isConfirmed) {
          if (result.value) {
            const existingCustomer = this.allExistingCustomers.find( customer => customer.customer_vat === result.value || customer.entity_id === result.value)
            if(existingCustomer){
              Swal.fire({title : 'Add Customer', html :  'This customer already exists in your entity !', icon:  'warning', showCancelButton: false})
            }else{
              this.storeLoad.setVisible(true)
              EntityDal.getAllEntities()
                  .then(data => {
                    this.storeLoad.setVisible(false)
                    let existingEntity = data.entities.data.find( entity => entity.entity_id === result.value)
                    if(existingEntity){
                      Swal.fire({title : 'New customer', html :  'The customer already exists in the platform . Do you want to connect it ?', icon:  'question', showCancelButton: true})
                          .then(result => {
                            if(result.isConfirmed){
                              this.storeLoad.setVisible(true)
                              if(this.authStore.getUserData.userType === 'book-keeper'){
                                CustomerDal.connectCustomersToEntity(existingEntity.id.toString(), this.authStore.getUserData.accountId, this.entityId)
                                    .then(data=> {
                                      this.customerAdded(data)
                                    })
                              }else{
                                CustomerDal.connectCustomerToAccount(existingEntity.id, this.authStore.getUserData.accountId)
                                    .then(data=> {
                                      this.customerAdded(data)
                                    })
                              }
                            }
                          })
                    }else{
                      this.storeLoad.setVisible(true)
                      this.showNewEntity = true
                      this.selectedVatNumber = result.value
                    }
                  })
            }
          }
        }
      })
    },

    customerAdded(data){
      this.storeLoad.setVisible(false)

      if(data.result === 'SUCCESS'){
        Swal.fire('New customer', 'The customer connected successfully', 'success')
            .then(result => {
              this.getCustomers(1)
            })
      }else {
        Swal.fire('New customer', "Can't connect the customer . Please contact system administrator .", 'error')
      }
    },

    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText
          );
        });
      });
    },

    proceedAction(actionType,customerId){
      let selectedCustomer = this.allExistingCustomers.find( customer => customer.id == customerId)
      this.selectedCustomerName = selectedCustomer.customer_name
      this.selectedCustomerId = customerId
      switch (actionType){
        case 'edit':
          this.editCustomer(selectedCustomer)
          break;
        case 'link':
          this.showEntities = true
          break;
        case 'delete':
          break;
        default:
          break;
      }
    },

    editCustomer(selectedCustomer){
      let that = this
      Swal.fire({
        title: 'Edit Customer',
        width: '500px',
        html: '<div></div>',
        showCancelButton: true,
        confirmButtonText: 'Update',
        willOpen() {
          let EditCustomer = Vue.extend(
              Vue.component('EditCustomer', EditCistomer)
          )
          let editCustomerInstance = new EditCustomer({propsData: {selectedCustomer: selectedCustomer}})
          editCustomerInstance.$mount()
          that.$refs.editCustomer = editCustomerInstance;
          Swal.getHtmlContainer().appendChild(editCustomerInstance.$el)
        },
        preConfirm: () => {
          return that.$refs.editCustomer.getCustomer()
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.storeLoad.setVisible(true)
          EntityDal.updateEntityName(selectedCustomer.id, result.value.customer_name, result.value.customer_name_en)
              .then(data => {
                if(data.entities.data.length === 1){
                  Swal.fire('Update Customer', 'Customer Updated Successfully', 'success').then(
                      data => {
                        this.getCustomers(1)
                      }
                  )
                }else{
                  Swal.fire('Update Customer', 'Failed to Updated Customer', 'error')
                }
              })
        }
      })
    },

    entitiesConnected(){
      // this.getCustomers(1)
      this.showEntities = false
    },
    newEntityAdded(args){
      this.showNewEntity = false

      if(args.entityId){

        Swal.fire('Customer added successfully !').then(result => {this.getCustomers(1)})
        // CustomerDal.connectCustomerToAccount(args.entityId, this.authStore.getUserData.accountId)
        //     .then(data => {
        //       if(data.result === 'SUCCESS'){
        //         Swal.fire('Customer added successfully !').then(result => {this.getCustomers(1)})
        //       }
        //     })

        // const addedCustomerId = args.entityId
        // const currentEntityId = this.entityStore.getCurrentEntity
        //
        // CustomerDal.connectCustomerToEntity(addedCustomerId, currentEntityId)
        //     .then(data => {
        //       if(data.result === 'SUCCESS'){
        //         Swal.fire('Customer added successfully !').then(result => {this.getCustomers(1)})
        //       }
        //     })

      }
    }
  }
}

</script>

<style scoped>



</style>