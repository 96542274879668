<template>
<div>

  <div style="display: flex">
    <label class="screen-title">{{dashboardMenuName}} : {{dashboardScreenName}}</label>
    <button @click="showReport" v-if="dashboardReportId && dashboardWorkspaceId"><i class='fa fa-bar-chart' style='font-size: 16px; color: #334249; margin-left: 20px'></i></button>
  </div>

  <div>
    <div style="padding-bottom: 5px">
      <label for="reportId" class="report-label">Report ID :</label>
      <input id="reportId" type="text" v-model="dashboardReportId" class="report-input">
    </div>
    <div>
      <label for="workspaceId" class="report-label">Workspace ID :</label>
      <input id="workspaceId" type="text" v-model="dashboardWorkspaceId" class="report-input">
    </div>
  </div>
  <hr>
</div>
</template>

<script>
import Swal from "sweetalert2"
import pbiReport from "@/screens/PbiReport.vue"
import Vue from "vue"
import {useLoadStore} from '@/stores/load'

export default {
  name: 'ScreenToDashboard',
  props: {
    screen: Object
  },
  data() {
    return {
      dashboardMenuName : this.screen.menu_name,
      dashboardScreenName: this.screen.sub_menu_text,
      dashboardReportId: this.screen.report_id,
      dashboardWorkspaceId: this.screen.workspace_id,
      screenId: this.screen.screen_id,
      storeLoad: useLoadStore(),
    }
  },
  methods: {
    getRecord(){
      return {screenId: this.screenId, reportId: this.dashboardReportId , workspaceId: this.dashboardWorkspaceId}
    },
    showReport(){
      let that = this
      Swal.close()
      if(this.dashboardWorkspaceId && this.dashboardReportId){
        Swal.fire({
              title: `${this.dashboardMenuName} : ${this.dashboardScreenName}`,
              html: '<div id="report-container" class="report_container" style="width: 100%; height: 700px">Wait for loading</div>',
              width:1200,
              showCancelButton: false,
              didOpen(evt){
                let newPbiReport = Vue.extend(
                  Vue.component('pbiReport',pbiReport)
                )
                let newPbiReportInstance = new newPbiReport({propsData: {dashboardDetails: {workspaceId : that.dashboardWorkspaceId ,reportId: that.dashboardReportId }}})
                newPbiReportInstance.$mount()
              }
            }
        ).then(()=>{
          this.$emit('reopen-dashboards-list')
        })
      }
    }
  }
}
</script>

<style scoped>
.report-input{
  width: 70%;
}

.report-label{
  text-align: left;
  width:30%;
}

.screen-title{
  text-align: left;
  width: 100%;
  color: black;
  font-weight: bold;
  padding-bottom: 5px;
}
</style>