<template>
  <div class="form-div">
<!--    <b-form @submit="onSubmit" @reset="onReset" v-if="show">-->
    <b-form style="font-size: 13px" autocomplete="off">
      <b-form-group id="input-group-user-name" label="User Name :" label-for="input-user-name" >
        <b-form-input type="text" id="input-user-name" v-model="form.user_name" placeholder="Enter username" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-first-name" label="First Name :" label-for="input-first-name">
        <b-form-input type="text" id="input-first-name" v-model="form.first_name" placeholder="Enter First-Name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-last-name" label="Last Name :" label-for="input-last-name">
        <b-form-input type="text" id="input-last-name" v-model="form.last_name" placeholder="Enter Last-Name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-user-description" label="Description:" label-for="input-user-description">
        <b-form-input type="text" id="input-user-description" v-model="form.user_description" placeholder="Enter user's first-name and last-name" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-user-email" label="Email address :" label-for="input-user-email">
        <b-form-input type="email" id="input-user-email" v-model="form.email" placeholder="Enter email" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-user-phone" label="Phone Number :" label-for="input-user-phone">
        <b-form-input type="tel" id="input-user-phone" v-model="form.phone_number" placeholder="Enter phone number" required></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-user-types" label="User-Types:" label-for="input-user-type">
        <b-form-select id="input-user-type" v-model="form.user_level_id" :options="userTypes" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-user-groups" label="User-Groups :" label-for="input-user-group">
        <b-form-select id="input-user-group" v-model="form.user_group_id" :options="userGroups" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-4" style="display: flex">
        <input type="checkbox" style="width: 20px" v-model="form.otp_enabled">
        <label>OTP Enabled</label>

        <input type="checkbox" style="width: 20px; margin-left: 20px" v-model="form.user_status" >
        <label>Active</label>
      </b-form-group>

      <b-form-group id="input-group-user-password" label="User-Password:" label-for="input-user-password" v-if="actionType==='add'">
        <b-form-input type="password" id="input-user-password"  v-model="form.user_password"  required autocomplete="false"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-user-repeat-password" label="Repeat Password:" label-for="input-user-repeat-password"  v-if="actionType==='add'">
        <b-form-input type="password" id="input-user-repeat-password"  v-model="form.user_repeat_password"  required autocomplete="false"></b-form-input>
      </b-form-group>

    </b-form>
  </div>

</template>

<script>
import {useUserStore} from "@/stores/user";
export default {
  name: "UserForm",
  props: {
    selectedUser: Object,
    userGroupsProps: Array,
    actionType: String
  },
  data() {
    return {
      userStore : useUserStore(),
      form: {
        phone_number: this.selectedUser ? this.selectedUser.phone_number : '',
        email: this.selectedUser ? this.selectedUser.email : '',
        user_name: this.selectedUser ? this.selectedUser.user_name : '',
        first_name: this.selectedUser ? this.selectedUser.first_name : '',
        last_name: this.selectedUser ? this.selectedUser.last_name : '',
        user_description: this.selectedUser ? this.selectedUser.user_description : '',
        user_type: this.selectedUser ? this.selectedUser.user_type : '',
        user_group_name: this.selectedUser ? this.selectedUser.user_group_name : '',
        user_status: this.selectedUser ? this.selectedUser.user_status == 1 : false,
        otp_enabled: this.selectedUser ? this.selectedUser.otp_enabled : false,
        id: this.selectedUser ? this.selectedUser.id : 0,
        user_group_id: this.selectedUser ? this.selectedUser.user_group_id : 0,
        user_level_id: this.selectedUser ? this.selectedUser.user_level_id : 0,
        should_replace_password: false,
        user_password: '',
        user_repeat_password: ''
      },
      userTypes: [{ text: 'Select User-Type', value: 0 }, {value : 1 , text : 'book-keeper'}, { value: 2, text: 'account-manager'}, {value: 3, text: 'administrator'}],
      userGroups : [].concat([{ text: 'Select User-Group', value: 0 }],this.userGroupsProps.map(group => {return {value: group.id, text: group.user_group_name}})),
      // show: true
      //.map(groupType => { return {value: groupType.id , text: groupType.type_name }})
    }
  },
  methods: {
    // onSubmit(event) {
    //   event.preventDefault()
    //   //alert(JSON.stringify(this.form))
    //
    // },
    // onReset(event) {
    //
    //   event.preventDefault()
    //   // Reset our form values
    //   this.form.email = ''
    //   this.form.name = ''
    //   this.form.food = null
    //   this.form.checked = []
    //   // Trick to reset/clear native browser form validation state
    //   this.show = false
    //   this.$nextTick(() => {
    //     this.show = true
    //   })
    // }
  },
  mounted() {
    this.userStore.setUserObject(this.selectedUser)
  },
  computed:{
    formProperty(){
      return this.form
    }
  },
  watch:{
    formProperty: {
      handler(newVal, oldVal) {
        this.userStore.setUserName(newVal.userName)
        this.userStore.setUserObject(newVal)
      },
      deep: true  // Enable deep watching
    }
  }
}
</script>

<style scoped>
.form-div{
  text-align: left;
}

.form-group{
  padding-top: 10px !important;
}

.form-control {
  font-size: 13px !important;
}

.custom-select{
  border: 0.5px solid #dee0e2;
  border-radius: 5px;
  padding: 5px;
}
</style>
