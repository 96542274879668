<template>
  <div>
    {{SelectedProfile.profile_name}}
    <div class="container">
      <div class="div-1">
        <button @click="goBack()">
          <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
        </button>
      </div>
      <div class="div-2">
        <LiquorTree
            :data="items"
            :options="options"
            ref="tree"
            @node:selected="nodeSelected"
        ></LiquorTree>
      </div>
      <div class="div-3">
        <ProfileParams :params="selectedParams" :selected-node="selectedNode"  @subMenuClicked="subMenuClicked"></ProfileParams>
      </div>
      <div class="div-4">
        <b-button class="form-button" type="submit" variant="primary" @click="saveConfiguration">Update Data</b-button>
        <b-button class="form-button" variant="secondary" @click="goBack">Back to profiles</b-button>
      </div>

    </div>
  </div>
</template>

<script>

import LiquorTree from 'liquor-tree'
import ProfileParams from "@/components/profiles/ProfileParams.vue"
import ProfileDal from "@/dal/profile_dal"
import {useLoadStore} from "@/stores/load"
import Swal from "sweetalert2";
import {faLaptop} from "@fortawesome/free-solid-svg-icons";

export default {
  name: 'ProfileConfiguration',
  components: {
    LiquorTree,
    ProfileParams
  },
  props:{
    SelectedProfile: Object
  },
  data() {
    return {
      storeLoad: useLoadStore(),
      selectedParams: null,
      selectedNode: null,
      items: this.SelectedProfile.profile_config.items,
      options: {
        checkbox: false,
        parentSelect: true
      }
    }
  },
  methods:{
    subMenuClicked(args){
      this.selectedNode.states.expanded = true
      this.selectedNode.states.selected = false
      this.selectedNode.children[args].states.selected = true
      this.selectedParams = this.selectedNode.children[args].data.params
      this.selectedNode = this.selectedNode.children[args]
    },

    saveConfiguration(){
      let paramItems = document.getElementsByClassName('param-item')
      let params = []

      Array.from(paramItems).forEach(item => {

        let input = item.getElementsByTagName('input')
        if(input.length === 1){
          let label = item.getElementsByTagName('label')
          let param = {type: 'checkbox', name: input[0].id, text: label[0].innerHTML, is_checked: input[0].checked}
          params.push(param)
        }

        let select = item.getElementsByTagName('select')
        if(select.length === 1){
          let label = item.getElementsByTagName('label')
          let options = []
          Array.from(select[0].options).forEach(option=> {options.push({ text: option.text, value : option.value })})
          let param = {type: 'select', name: select[0].id, text: label[0].innerHTML, value: select[0].value, options}
          params.push(param)
        }
      })


      let parentNode = this.SelectedProfile.profile_config.items[this.selectedNode.parent.id -1 ]
      let editedChild = parentNode.children.find(child => child.id === this.selectedNode.id)
      editedChild.data.params = params

      this.storeLoad.setVisible(true)
      ProfileDal.updateProfileConfig(this.SelectedProfile.id , {items : this.SelectedProfile.profile_config.items})
          .then(data => {
            this.storeLoad.setVisible(false)
            if(data.profile.result === 'SUCCESS'){
              Swal.fire('Profile Update', 'Profile Updated Successfully', 'success')
            }else{
              Swal.fire('Profile Update', `Profile Update Failed . ${data.profile.message}`, 'error')
            }
          })
    },

    nodeSelected(node){
      if( this.selectedNode){
        this.selectedNode.states.selected = false
      }

      this.selectedNode = node

      if(node.data.params){
        this.selectedParams = node.data.params
      }else{

        this.selectedParams = []
      }
    },

    goBack(){
      Swal.fire({
        title: 'Back to profile',
        html: 'Please confirm to back to the profiles list',
        icon: 'question',
        showConfirmButton: true,
        showCancelButton: true
      })
          .then(result => {
            if(result.isConfirmed){
              this.storeLoad.setVisible(true)
              this.$emit('backToProfiles')
            }
          })
    },
  }
}

</script>

<style scoped>
.container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 0.1fr 1.9fr;
  gap: 0px 5px;
  grid-template-areas:
    "div-1 div-1"
    "div-2 div-3"
    "div-4 div-4";
}
.div-1 { grid-area: div-1; margin-bottom: 10px}
.div-2 { grid-area: div-2; border: 1px solid grey; border-radius: 5px;     max-height: 800px; overflow: auto;}
.div-3 { grid-area: div-3; border: 1px solid grey; border-radius: 5px; padding: 20px}
.div-4 { grid-area: div-4; text-align: left;  margin-top: 20px;}
.form-button{
  width: 200px;
  margin-right: 20px;
}
</style>