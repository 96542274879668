import {ApiDal} from './api_dal'
class SuppliersDal extends ApiDal{


    async connectSupplierToEntity(supplierId , entityId  ){
        return this.callApi('post', '/api/suppliers/connectSupplierToEntity', 'entities', {supplierId , entityId})
    }

    async connectSuppliersToEntity(supplierId , entityId, connectedEntities){
        return this.callApi('post', '/api/suppliers/connectSuppliersToEntity', null, {supplierId , entityId, connectedEntities})
    }
}

export default new SuppliersDal()