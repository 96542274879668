import {ApiDal} from './api_dal'

class ProfileDal extends ApiDal{
    async getAllProfiles(){
        return this.callApi('post', '/api/profiles/getAllProfiles', 'profiles', null)
    }

    async getProfileConfById(profileId){
        return this.callApi('post', '/api/profiles/getProfileConfById', 'profile', {profileId})
    }

    async updateProfileConf(profileId, enabledEntries , disabledEntries){
        return this.callApi('post', '/api/profiles/updateProfileConf', 'profile', {profileId, enabledEntries , disabledEntries})
    }

    async addProfile(profileName, profileDescription){
        return this.callApi('post', '/api/profiles/addProfile', 'profile', { profileName, profileDescription })
    }

    async deleteProfile(profileId){
        return this.callApi('post', '/api/profiles/deleteProfile', 'profile', {profileId})
    }

    async updateProfileConfig(profileId, profileConfig){
        return this.callApi('post', '/api/profiles/updateProfileConfig', 'profile', { profileId, profileConfig })
    }

    async updateProfile(profileId , profileName , profileDescription){
        return this.callApi('post', '/api/profiles/updateProfile', 'profile', { profileId, profileName , profileDescription })
    }

}

export default new ProfileDal()
