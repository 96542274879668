<template>
  <div class="chat-container">
    <div class="messages">
      <div
          v-for="(msg, index) in messages"
          :key="index"
          :class="['message overflow-x-auto whitespace-pre-wrap', msg.isUser ? 'user-message' : 'bot-message', msg.isWaiting ? 'waiting-message' : '']"
      >
        <div v-html="msg.text" />
      </div>
    </div>
    <div class="input-container">
      <input
          type="text"
          v-model="userInput"
          @keydown.enter="sendMessage"
          placeholder="Type a message"
      />
      <button @click="sendMessage">Send</button>
      <input type="file" @change="uploadFile" id="inputFile"/>

    </div>
  </div>
</template>

<script>
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css'; // Choose a theme or style for syntax highlighting
import $ from "jquery"
import env from "@/env";

export default {
  data() {
    return {
      userInput: '',
      messages: [],
      connectConfig: {
        url: `${env.getPortalUrl()}/api/openai/callOpenAi`,
        method: "POST",
        stream: true
      }

    };
  },

  updated() { // This will be triggered every time the component updates
    this.$nextTick(() => {
      $('pre code').each(function(i, block) {
        hljs.highlightBlock(block);
      });
    });
  },


  methods: {
    async sendMessage() {
      if (!this.userInput.trim()) return;

      this.addMessage(this.userInput, true, false);  // Add the user message
      const placeholderIndex = this.messages.length;  // Placeholder message while waiting for the API response
      this.addMessage('...', false, true);

      try {
        let message = this.userInput
        this.userInput = '';
        const response = await this.callApi({ text: message });

        let content = response.data.message

        const hasCodeBlock = content.includes("```");
        if (hasCodeBlock) {        // If the content has code block, wrap it in a <pre><code> element
          content = content.replace(/```([\s\S]+?)```/g, '<p><pre><code>$1</code></pre></p>');
        }

        content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // replace ** ** wrapped text to bold .
        this.updateMessage(placeholderIndex, content);

      } catch (error) {
        console.error(error)
        this.updateMessage(placeholderIndex, 'Error in fetching response');
      }
    },

    async uploadFile(event) {
      const file = event.target.files[0];
      if (!file) return;

      // Add the file upload message
      this.addMessage(`File uploaded: ${file.name}`, true, false);

      // Placeholder message while waiting for the API response
      const placeholderIndex = this.messages.length;
      this.addMessage('...', false, true);

      try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await this.callApi(formData);
        const formattedText = this.formatResponse(response.data.message);
        this.updateMessage(placeholderIndex, formattedText);
        this.updateMessage(placeholderIndex, content);
      } catch (error) {
        this.updateMessage(placeholderIndex, 'Error in fetching response');
      }
    },

    addMessage(text, isUser, isWaiting) {
      this.messages.push({ text, isUser, isWaiting });
    },

    updateMessage(index, newText) {
      this.$set(this.messages, index, { text: newText, isUser: false });
    },

    // formatResponse(text) {
    //   const tempDiv = document.createElement('div');  // Convert plain text into HTML with syntax highlighting
    //   tempDiv.innerHTML = text;
    //
    //   tempDiv.querySelectorAll('pre code').forEach((block) => { // Apply syntax highlighting to code blocks
    //     hljs.highlightBlock(block);
    //   });
    //
    //   return tempDiv.innerHTML;
    // },

    async callApi(data) {
      const messages = [{ text: data }]

      const response = await fetch(this.connectConfig.url, {
        method: this.connectConfig.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ messages }),
      });

      const resp = await response.json();

      return new Promise((resolve) => {
        resolve({data: {message: resp.text}});
      });
    },

  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  flex-direction: column;
  height: 800px;
  width: 100%;
  max-width: 600px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  max-width: 70%;
}

.user-message {
  background-color: #806cf6;
  align-self: flex-end;
  color: white;
  font-size:20px;
}

.bot-message {
  background-color: #e2e4e9;
  align-self: flex-start;
  font-size:20px;
}

.waiting-message{
  color: red
}

pre {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
}

code {
  font-family: 'Courier New', Courier, monospace;
}

.input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background-color: #b09fd5;
}

input[type='text'] {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

input[type='file'] {
  margin-right: 10px;
}

.overflow-x-auto{
  overflow-x: auto;
}

.whitespace-pre-wrap{
  white-space: pre-wrap;
}

.pre-block {
  font-size: 20px;
  border: 2px solid grey;
  width: 450px;
  border-left: 12px solid green;
  border-radius: 5px;
  padding: 14px;
}


</style>
