
<template>
  <div class="pageContent" :class="currentPage.sub_menu_name">

<!--    1 . THEPAGE : add this class to the DB -->
<!--    2 . the selected page can be out of section .-->

    <section>
      <h2 v-if="false">{{selectedPage.text}}</h2>
<!--      <p>{{selectedPage.data.name}}</p>-->
      <component
          :is="currentPage.sub_menu_name"
          :user-id="currentUser"
          :page-name="pageName"
          :sub-page-name="selectedPage ? selectedPage.text : ''"
          :user-group-id="userGroupId"
          :screen-name="selectedPage ? selectedPage.sub_menu_name : ''"
          :entity-id="this.entityStore.getCurrentEntity"
          :screen-key="this.screenKey"
          @addBreadCrumb="addBreadCrumb"
          ref="currentComponent"
          @newGlAccountAdded="newGlAccountAdded"
      ></component>
    </section>
    <img src="@/assets/images/mainImage.png" alt="image" v-if="selectedPage===''">
  </div>
</template>

<script>
import EmptyPage from "@/components/page/EmptyPage.vue"
import SettingsSystem from "@/screens/Settings/SettingsSystem.vue"
import AnalyticsOverview from "@/screens/Analytics/AnalyticsOverview.vue"
import SettingsUsers from "@/screens/Settings/SettingsUsers.vue"
import SettingsUserGroups from "@/screens/Settings/SettingsUserGroups.vue"
import SettingsEntities from "@/screens/Settings/SettingsEntities.vue"
import SystemProfiles from "@/screens/Settings/SettingsProfiles.vue"
import SettingsSupport from "@/screens/Settings/SettingsSupport.vue"
import SettingsGLUserLevels from "@/screens/Settings/SettingsGLUserLevels.vue"
import Accounts from "@/screens/Settings/SettingsAccounts.vue"
import HomeOverview from "@/screens/Home/HomeOverview.vue"
import HomeActualVsBudget from "@/screens/Home/HomeActualVsBudget.vue"
import HomeIncomeAndProfit from "@/screens/Home/HomeIncomeAndProfit.vue"
import HomeMainCosts from "@/screens/Home/HomeMainCosts.vue"
import ClassificationWizard from "@/components/docs/ClassificationWizard.vue"
import BanksOverview from "@/screens/Banks/BanksOverview.vue"
import BanksCashFlows from "@/screens/Banks/BanksCashFlows.vue"
import BanksDebtPosition from "@/screens/Banks/BanksDebtPosition.vue"
import BanksDataOnBoarding from "@/screens/Banks/BanksDataOnBoarding.vue"
import BanksDetails from "@/screens/Banks/BanksDetails.vue"
import IncomeManagement from "@/screens/Income/IncomeManagement.vue"
import IncomeOverview from "@/screens/Income/IncomeOverview.vue"
import HrOverview from "@/screens/HR/HrOverview.vue"
import HrDetails from "@/screens/HR/HrDetails.vue"
import FixedAssetsOverview from "@/screens/FixedAssets/FixedAssetsOverview.vue"
import AccountingReports from "@/screens/Accounting/AccountingReports.vue"
import AccountingAnalytics from "@/screens/Accounting/AccountingAnalytics.vue"
import AccountingGeneralLedger from "@/screens/Accounting/AccountingGeneralLedger.vue"
import AccountingProvisions from "@/screens/Accounting/AccountingProvisions.vue"
import AccountingManagement from "@/screens/Accounting/AccountingManagement.vue"
import AccountingDataOnBoarding from "@/screens/Accounting/AccountingDataOnBoarding.vue"
import ProcurementManagement from "@/screens/Procurement/ProcurementManagement.vue"
import ProcurementDataOnboarding from "@/screens/Procurement/ProcurementDataOnboarding.vue"
import ProcurementOverview from "@/screens/Procurement/ProcurementOverview.vue"
import ProjectionsActualVsBudget from "@/screens/Projections/ProjectionsActualVsBudget.vue"
import EquityOverview from "@/screens/Equity/EquityOverview.vue"

import {useAuthStore} from "@/stores/auth"
import {useEntityStore} from "@/stores/entity";

export default {
  name: 'PageContent',
  components: {

    'empty_page': EmptyPage,
    'procurement_data_on_boarding' : ProcurementDataOnboarding,
    'analytics_overview': AnalyticsOverview,
    'settings_users': SettingsUsers,
    'settings_user_groups': SettingsUserGroups,
    'settings_profiles' : SystemProfiles,
    'settings_system': SettingsSystem,
    'settings_entities': SettingsEntities,
    'settings_accounts' : Accounts,
    'settings_support': SettingsSupport,
    'settings_gl_user_levels': SettingsGLUserLevels,
    'home_overview': HomeOverview,
    //'home_actual_vs_budget' : HomeActualVsBudget,
    'home_sales_profit': HomeIncomeAndProfit,
    'home_main_costs': HomeMainCosts,
    'banks_overview': BanksOverview,
    'banks_cash_flows': BanksCashFlows,
    'banks_debt_position': BanksDebtPosition,
    'banks_data_on_boarding': BanksDataOnBoarding,
    'banks_details': BanksDetails,
    'income_management': IncomeManagement,
    'income_overview': IncomeOverview,
    'hr_overview': HrOverview,
    'hr_details': HrDetails,
    'fixed_assets_overview': FixedAssetsOverview,
    'accounting_reports': AccountingReports,
    'accounting_analytics': AccountingAnalytics,
    'accounting_general_ledger': AccountingGeneralLedger,
    'accounting_provisions': AccountingProvisions,
    'accounting_management': AccountingManagement,
    'procurement_manag': ProcurementManagement,
    'procurement_overview': ProcurementOverview,
    'accounting_data_on_boarding': AccountingDataOnBoarding,
    'equity_overview': EquityOverview,
    'projections_actual_vs_budget': ProjectionsActualVsBudget,
    // 'home_ops': ClassificationWizard
  },
  props: {
    selectedPage: Object,
    pageName: String
  },
  data(){
    return {
      authStore : useAuthStore(),
      currentPage: {sub_menu_name: 'empty_page'},
      currentUser: '0',
      userGroupId: null,
      entityStore: useEntityStore(),
      screenKey: ''
    }
  },
  methods: {
    addBreadCrumb(args){
      this.$emit("addBreadCrumb", args)
    },

    toggleMenu(){
      this.screenKey = this.uuidv4()
    },

    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
          (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
      );
    },

    resetPage(args){
      let currentComponent = this.$refs.currentComponent
      try{
        currentComponent.showAll()
      }catch (e) {
        // console.log(e)
      }
    },

    newGlAccountAdded(){
      this.$emit('newGlAccountAdded')
    }
  },
  watch:{
    selectedPage: function (newVal, oldVal){
      if(newVal){
        this.currentPage = this.$options.components[newVal.sub_menu_name] ? this.selectedPage :  {sub_menu_name: 'empty_page'}
        this.userGroupId = this.authStore.getUserData.userGroupId
      }else{
        this.currentPage = {sub_menu_name: 'empty_page'}
      }
    }
  }
}

</script>


<style scoped>
.home_overview > section{
  height: 100%;
}

.home_actual_vs_budget > section{
  height: 100%;
}

.home_main_costs > section{
  height: 100%;
}

.home_sales_profit > section{
  height: 100%;
}

.banks_overview > section{
  height: 100%;
}

.banks_cash_flows > section{
  height: 100%;
}

.banks_debt_position > section{
  height: 100%;
}

.banks_details > section{
  height: 100%;
}

.hr_overview > section{
  height: 100%;
}

.hr_details > section{
  height: 100%;
}

.fixed_assets_overview > section{
  height: 100%;
}

.accounting_reports > section{
  height: 100%;
}

.accounting_analytics > section{
  height: 100%;
}

.accounting_general_ledger > section{
  height: 100%;
}

.accounting_provisions > section{
  height: 100%;
}

.procurement_manag > section{
  height: 100%;
}

.procurement_overview > section{
  height: 100%;
}

.income_management > section{
  height: 100%;
}

.income_overview > section{
  height: 100%;
}

.equity_overview > section{
  height: 100%;
}

.analytics_overview > section{
  height: 100%;
}

.projections_actual_vs_budget > section{
  height: 100%;
}

</style>