<template>
  <div class="TextInput-area field-area">
    <figure class="input-container field-input field-item" v-if="title != 'Consumption driver'">
      <label for="additional-info-text-input" class="text-label">Additional Details</label>
      <input type="text" id="additional-info-text-input" v-model="additionalDetailsValue" >
    </figure>
    <figure class="input-container field-input field-item">
      <label for="text-input" class="text-label">{{title}}</label>
      <input type="text" id="text-input" v-model="textValue" ref="text-input">
    </figure>

    <b-button variant="primary" ref="next-button" class="next-btn" @click="next" v-if="!existingValue">{{isLastTab ? 'Done': 'Next'}}</b-button>
  </div>
</template>

<script>
export default {
  name: 'TextInputComponent',
  props: {
    title : String,
    nextComponentId: Number,
    isLastTab: Boolean,
    existingValue: Object
  },
  data(){
    return {
      additionalDetailsValue: this.existingValue ? this.existingValue.additionalDetailsValue: '',
      textValue: this.existingValue ? this.existingValue.textValue : ''
    }
  },
  methods: {
    next(){
      this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"], isLastTab: this.isLastTab})
    },

    getConfiguration(){
      return {data: {additionalDetailsValue : this.additionalDetailsValue ,textValue : this.textValue}}
    }
  }
}
</script>

<style scoped>

#additional-info-text-input{
  /*width:50%;*/
}

#text-input{
  /*width:50%;*/
}

.text-container{
  /*text-align: left;*/
  /*padding: 10px;*/
}

.text-label{
  /*margin-right: 10px;*/
  /*min-width: 220px;*/
}

.input-container{
  /*padding: 20px;*/
}
</style>