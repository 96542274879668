<template>

</template>

<script>
import Swal from "sweetalert2";
import authDal from "@/dal/auth-dal";

export default {
  props:{
    selectedUser: Object
  },
  name: "SystemUsers",
  mounted() {
    Swal.fire({
      title: 'Change password',
      html: `<input type="password" id="password" class="swal2-input" placeholder="New Password" autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" >
                 <input type="password" id="repeat-password" class="swal2-input" placeholder="Repeat New Password" autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" >`,
      showCancelButton: true,
      confirmButtonText: 'Update',
      preConfirm: () => {
        const password = Swal.getPopup().querySelector('#password').value
        const passwordRepeat = Swal.getPopup().querySelector('#repeat-password').value
        if (!passwordRepeat || !password) {
          Swal.showValidationMessage(`Please enter password and repeat`)
        }
        if (passwordRepeat !== password) {
          Swal.showValidationMessage(`Unmatched values of password`)
        }
        return { password , passwordRepeat }
      }
    }).then((result) => {
      if(result.isConfirmed){
        authDal.updatePassword(this.selectedUser.id, result.value.password)
            .then(data => {
              if (data.result === 'SUCCESS') {
                Swal.fire({title: `Password for user ${this.selectedUser.user_name} updated successfully`, icon: 'success'})
                    .then(() => {
                      this.$emit('closeModal')
                    })
              } else {
                Swal.fire({title: `Failed to update password for user ${this.selectedUser.user_name}`, icon: 'error'})
                    .then(() => {
                      this.$emit('closeModal')
                    })
              }
            })
      }else{
        this.$emit('closeModal')
      }

    })
  }
}
</script>

<style scoped>

</style>