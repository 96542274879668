
<!--<template>-->

<!--    <figure class="support-area" :class="{ SupportIsOpen: isSupportOpen }">-->
<!--      <button class="support-btn" @click="toggleSupport">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">-->
<!--          <path d="M3.86,31.45,24.68,100,37.9,81.62,24.67,31.45ZM73.3,0,32.1,59.61H53.55L96.14,0Z"/>-->
<!--        </svg>-->
<!--      </button>-->

<!--      <div class="support-chat-area">-->
<!--        <button class="close-support-btn" @click="toggleSupport">&times;</button>-->
<!--        <div class="support-chat">-->
<!--          <div class="the-chat">-->
<!--            <deep-chat-->
<!--                demo="true"-->
<!--                :connect="connectConfig"-->
<!--                ref="deepChat"-->
<!--                style="background-color: #f7f7f7; border-radius: 8px; width: 100%; height:100%; display: block"-->
<!--                messageStyles='{-->
<!--                                "default": {-->
<!--                                  "user": {"bubble": {"backgroundColor": "#816df8",  "fontSize": "20px" }},-->
<!--                                  "ai": {-->
<!--                                    "bubble": {-->
<!--                                      "fontSize": "20px"-->
<!--                                    }-->
<!--                                  }-->
<!--                                }-->
<!--                              }'-->
<!--                inputAreaStyle='{"backgroundColor": "#b09fd6", "fontSize": "20px" }'-->
<!--                @message="handleMessage"-->
<!--            ></deep-chat>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </figure>-->

<!--</template>-->

<!--<script >-->
<!--import {DeepChat} from "deep-chat"-->
<!--import env from "@/env"-->

<!--export default {-->
<!--  name: "AiSupport",-->
<!--  data() {-->
<!--    return {-->
<!--      isSupportOpen: false,-->
<!--      openAiKey: env.getOpenAiKey(),-->
<!--      directConnection: `{-->
<!--                            "openAI": {-->
<!--                              "key": "${env.openAiKey}",-->
<!--                              "chat": {"max_tokens": 2000, "system_prompt": "Assist me with anything you can"}-->
<!--                            }-->
<!--                          }`,-->
<!--      connectConfig: {-->
<!--        url: "http://localhost:8899/api/openai/callOpenAi",-->
<!--        method: "POST",-->
<!--        stream: true-->
<!--      }-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    toggleSupport: function () {-->
<!--      this.isSupportOpen = this.isSupportOpen === false;-->
<!--    },-->


<!--    async handleMessage(event) {-->
<!--      console.log(this.$refs.deepChat.getMessages())-->

<!--      if(event.detail.message.role === 'user'){-->

<!--        const userMessage = event.detail.message-->
<!--        const messages = [{ text: userMessage }]-->

<!--        try {-->
<!--          const response = await fetch(this.connectConfig.url, {-->
<!--            method: this.connectConfig.method,-->
<!--            headers: {-->
<!--              "Content-Type": "application/json",-->
<!--            },-->
<!--            body: JSON.stringify({ messages }),-->
<!--          });-->

<!--          const data = await response.json();-->

<!--          console.log(`data.text : ${data.text}`)-->

<!--          this.$refs.deepChat.addMessage({ text: data.text, role: "assistant" });-->
<!--        } catch (error) {-->
<!--          console.error("Error while calling the backend service:", error);-->
<!--          this.$refs.deepChat.addMessage({-->
<!--            text: "Sorry, something went wrong.",-->
<!--            role: "assistant",-->
<!--          });-->
<!--        }-->
<!--      }else{-->
<!--        this.$refs.deepChat.history = [this.$refs.deepChat.getMessages()[0]]-->
<!--      }-->

<!--    },-->
<!--  }-->

<!--}-->

<!--</script>-->


<!--<style scoped>-->
<!--.support-area {-->
<!--  position: fixed;-->
<!--  bottom: 20px;-->
<!--  right: 20px;-->

<!--  .support-btn {-->
<!--    background: #b39edb;-->
<!--    height: 60px;-->
<!--    width: 60px;-->
<!--    border-radius: 50px;-->
<!--    box-shadow: 0 0 10px #b39edb;-->

<!--    display: flex;-->
<!--    align-items: center;-->
<!--    justify-content: center;-->
<!--    padding: 12px;-->

<!--    &:hover {-->
<!--      transform: scale(1.2);-->
<!--    }-->

<!--    svg {-->
<!--      fill: white;-->
<!--    }-->
<!--  }-->

<!--  .close-support-btn {-->
<!--    position: absolute;-->
<!--    top: 0;-->
<!--    right: 0;-->
<!--    width: 45px;-->
<!--    height: 45px;-->
<!--    padding: 0;-->
<!--    font-size: 30px;-->
<!--  }-->

<!--  .support-chat-area {-->
<!--    position: absolute;-->
<!--    bottom: calc(100% + 10px);-->
<!--    right: 0;-->
<!--    width: 500px;-->

<!--    height: 0;-->
<!--    padding: 0 10px;-->
<!--    overflow: hidden;-->
<!--    opacity: 0;-->

<!--    .support-chat {-->
<!--      position: relative;-->
<!--      height: 640px;-->
<!--      padding: 10px;-->
<!--      background: white;-->
<!--      box-shadow: 0 0 10px;-->
<!--      border-radius: 10px;-->

<!--      .the-chat {-->
<!--        width: 100%;-->
<!--        height: 100%;-->
<!--        overflow: auto;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->

<!--.SupportIsOpen {-->

<!--  .support-chat-area {-->
<!--    height: 700px;-->
<!--    padding: 50px 10px 10px;-->
<!--    opacity: 1;-->
<!--  }-->
<!--}-->

<!--.user-message-text {-->
<!--  background-color: rgb(129 109 248);-->
<!--}-->

<!--</style>-->



<template>

  <figure class="support-area" :class="{ SupportIsOpen: isSupportOpen }">
    <button class="support-btn" @click="toggleSupport">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M3.86,31.45,24.68,100,37.9,81.62,24.67,31.45ZM73.3,0,32.1,59.61H53.55L96.14,0Z"/>
      </svg>
    </button>

    <div class="support-chat-area">
      <button class="close-support-btn" @click="toggleSupport">&times;</button>
      <div class="support-chat">
        <div class="the-chat">
          <deep-chat
              style="background-color: #f7f7f7; border-radius: 8px; width: 100%; height:100%; display: block"
              messageStyles='{
                                "default": {
                                  "user": {"bubble": {"backgroundColor": "#816df8",  "fontSize": "20px" }},
                                  "ai": {
                                    "bubble": {
                                      "fontSize": "20px"
                                    }
                                  }
                                }
                              }'
              inputAreaStyle='{"backgroundColor": "#b09fd6", "fontSize": "20px" }'
              :directConnection="directConnection"
          ></deep-chat>
        </div>
      </div>
    </div>
  </figure>

</template>

<script >
import {DeepChat} from "deep-chat"
import env from "@/env"

export default {
  name: "AiSupport",
  data() {
    return {
      isSupportOpen: false,
      openAiKey: env.getOpenAiKey(),
      directConnection: `{
                            "openAI": {
                              "key": "${env.openAiKey}",
                              "chat": {"max_tokens": 2000, "system_prompt": "Assist me with anything you can"}
                            }
                          }`
    }
  },
  methods: {
    toggleSupport: function () {
      this.isSupportOpen = this.isSupportOpen === false;
    }
  }

}

</script>


<style scoped>
.support-area {
  position: fixed;
  bottom: 20px;
  right: 20px;

  .support-btn {
    background: #b39edb;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    box-shadow: 0 0 10px #b39edb;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;

    &:hover {
      transform: scale(1.2);
    }

    svg {
      fill: white;
    }
  }

  .close-support-btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    padding: 0;
    font-size: 30px;
  }

  .support-chat-area {
    position: absolute;
    bottom: calc(100% + 10px);
    right: 0;
    width: 500px;

    height: 0;
    padding: 0 10px;
    overflow: hidden;
    opacity: 0;

    .support-chat {
      position: relative;
      height: 640px;
      padding: 10px;
      background: white;
      box-shadow: 0 0 10px;
      border-radius: 10px;

      .the-chat {
        width: 100%;
        height: 100%;
        overflow: auto;
      }
    }
  }
}

.SupportIsOpen {

  .support-chat-area {
    height: 700px;
    padding: 50px 10px 10px;
    opacity: 1;
  }
}

.user-message-text {
  background-color: rgb(129 109 248);
}

</style>