
<template>
  <figure class="mainNavigation" :class="{mainNavigationOpen: isNavOpen}">

    <figure class="logo-area" @click="toggleMenu">
      <svg class="v-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
        <path d="M72.83,1,32.46,59.42h21L95.22,1Zm-68,30.82L25.19,99l13-18-13-49.16Z"/>
      </svg>
      <svg class="viewz-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 100">
        <path d="M90.19,31.92l-14,65.51H94.28l14-65.51Zm13.2-8.12a11.14,11.14,0,0,0,8.18-3.28A11,11,0,0,0,115,12.4a10.62,10.62,0,0,0-3.43-8A10.94,10.94,0,0,0,103.39,1a11.07,11.07,0,0,0-8,3.41,10.62,10.62,0,0,0-3.43,8,11,11,0,0,0,3.43,8.12A11.26,11.26,0,0,0,103.39,23.8ZM339.5,31.92H280.78l-3.43,16.25h27L262.83,97.43h61l3.43-16.25h-29.3Zm-85.47,0L233.57,62.71,223.28,30.48,199,62.71l-7.26-30.79H172.88l17.15,67,27.18-34.33,12.41,34.33,45.91-66.95ZM143.2,30.48q-16,0-25.74,10.35t-9.63,25.81q0,14.42,9.63,23.32t24.81,8.91q12.14,0,19.53-2.88l3.56-16.9a48.36,48.36,0,0,1-19.53,3.8,25.27,25.27,0,0,1-13.46-3.41A12.88,12.88,0,0,1,126.31,69h46.05q3-18-5.68-28.17T143.2,30.48Zm-16.1,26.6a16.62,16.62,0,0,1,5.67-9.18,15.63,15.63,0,0,1,9.9-3.4q5.67,0,9,3.4t3.17,9.18ZM.5,31.82,20.9,99l13-18-13-49.16ZM68.55,1,28.17,59.42h21L90.93,1Z"/>
      </svg>
    </figure>

    <ul class="theNavigation">
      <li>
        <a :dataTooltip="'Search'">
          <figure class="icon">
            <img src="@/assets/icons/search.svg" alt="icon for Search">
          </figure>
          <span>Search</span>
        </a>
      </li>

      <li v-for="(item,index) in menuItems" :key="index" :class="{currentActivePage: index === clickedMenuIndex}" >
        <a @click="clickedMenuIndex = index" :dataTooltip="item.menu_name " >
          <figure class="icon" >
            <img :src="getImgUrl(item.menu_name.replace(/\s/g,'') +'.svg')" :alt="'icon for '+item.menu_name" :title="isNavOpen ? false : item.menu_item">
          </figure>
          <span>{{item.menu_name}}</span>
        </a>
      </li>

    </ul>

  </figure>

</template>


<script>
import AuthDal from "@/dal/auth-dal"
import UserDal from "@/dal/user-dal"
import { useAuthStore } from "@/stores/auth"
import {useLoadStore} from "@/stores/load"

import Swal from "sweetalert2";

export default {
  name: "LeftSideNavBar",
  mounted() {
    this.storeLoad.setVisible(true)
    this.currentUser = this.storeAuth.getUserData.name
    UserDal.getUserMenus(this.storeAuth.getUserData.userId)
        .then(data =>{
          this.storeLoad.setVisible(false)
          this.menuItems = data.data
          this.$emit('menuItemClicked', this.menuItems[0])
        })
  },
  data(){
    return {
      isNavOpen: false,
      storeAuth: useAuthStore(),
      storeLoad: useLoadStore(),
      menuItems: [
      ],
      currentUser:'',
      clickedMenuIndex: 0
    }
  },

  watch: {
    "clickedMenuIndex": function( newVal, oldVal) {
      this.$emit('menuItemClicked', this.menuItems[newVal])
    }
  },

  methods: {
    toggleMenu: function () {
      this.isNavOpen = this.isNavOpen === false;
      this.$emit('toggleMenu', this.isNavOpen)
    },

    setSelectedMenu(menuName){
      let selectedMenu = this.menuItems.find(x => x.menu_name === menuName)
      //this.clickedMenuIndex = selectedMenu.menu_id
      this.$emit('menuItemClicked', this.menuItems[selectedMenu.menu_id - 1])
    },

    getImgUrl: function (path) {
      return require('@/assets/icons/' + path);
    },

    async logOut() {
      Swal.fire({
        title : 'Logout',
        html: 'Logout from ViewZ',
        icon: 'question',
        showCancelButton: true
      })
          .then(async result => {
            if (result.isConfirmed) {
              await AuthDal.logout(this.storeAuth.getUserName).then((response) => {
                this.storeAuth.setAuthorisation(!response.result === "SUCCESS");
              });
            }
          })
    },

  }
}

</script>


<style scoped>

</style>