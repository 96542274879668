<template>
  <div class="signup-div">

    <b-form @submit.prevent="getCompanyDetails" v-if="!showRegistrationForm">
      <b-form-group id="input-group-vat-number-initial" label="Your Company VAT Number :" label-for="input-vat-number-initial">
        <b-form-input id="input-vat-number-initial" v-model="form.vatNumber" placeholder="Enter VAT Number" required ></b-form-input>
      </b-form-group>
      <b-button type="submit" variant="primary">Search</b-button>
      <b-button variant="secondary" class="cancel-button" @click="$emit('open-login')">Cancel</b-button>
    </b-form>

    <h2 class="form-title" v-if="showRegistrationForm">Registration Form :</h2>
    <b-form @submit.prevent="onSubmit"  v-if="showRegistrationForm">

      <div class="parent">
        <div class="div1">
          <b-form-group id="input-group-vat-number" label="Your Company VAT Number :" label-for="input-vat-number">
            <b-form-input id="input-vat-number" v-model="form.vatNumber" placeholder="Enter VAT Number" required ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-account-name" label="Account / Company Name :" label-for="input-account-name">
            <b-form-input id="input-account-name" v-model="form.accountName" placeholder="Enter Company Name" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-first-name" label="Your Name:" label-for="input-first-name">
            <b-form-input id="input-first-name" v-model="form.firstName" placeholder="Enter First Name" required ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-last-name" label="Your Last Name:" label-for="input-last-name">
            <b-form-input id="input-last-name" v-model="form.lastName" placeholder="Enter Last Name" required ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-email" label="Email address:" label-for="input-email" description="We'll never share your personal details with anyone else." >
            <b-form-input id="input-email" v-model="form.email" type="email" placeholder="Enter email" required></b-form-input>
          </b-form-group>
        </div>
        <div class="div2">
          <b-form-group id="input-group-phone-number" label="Phone Number:" label-for="input-phone-number"  >
            <b-form-input id="input-phone-number" v-model="form.phoneNumber" placeholder="Enter Phone Number" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-company-type" label="Company Type:" label-for="input-company-type">
            <b-form-select id="input-company-type" v-model="form.accountType" :options="accountTypes" required ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-city-name" label="City Name :" label-for="input-city-name">
            <b-form-input id="input-city-name" v-model="form.cityName" placeholder="Enter City Name" required ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-street-name" label="Street Name :" label-for="input-street-name">
            <b-form-input id="input-street-name" v-model="form.streetName" placeholder="Enter Street Name" required ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-house-number" label="House Number :" label-for="input-house-number">
            <b-form-input id="input-house-number" v-model="form.houseNumber" placeholder="Enter House Number" required ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-approval" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group v-model="form.checked" id="checkboxes-approval" :aria-describedby="ariaDescribedby" >
              <b-form-checkbox  >I have read and agree to the Terms and Conditions</b-form-checkbox>
              <!--          <b-form-checkbox value="that">Check that out</b-form-checkbox>-->
            </b-form-checkbox-group>
          </b-form-group></div>
      </div>

      <b-button type="submit" variant="primary">Create Account</b-button>
<!--      <b-button type="reset" variant="danger">Reset</b-button>-->
      <b-button variant="light" @click="$emit('open-login')">Cancel</b-button>
    </b-form>

  </div>
</template>

<script>
import {useLoadStore} from "@/stores/load"

import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AuthDal from "@/dal/auth-dal"
import EntityDal from "@/dal/entity_dal"
import UserOtpQrCode from "@/components/users/UserOtpQrCode.vue"
import Swal from "sweetalert2";
import QRCode from "qrcode"

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

export default {
  name: "SignUp",
  data() {
    return {
      storeLoad: useLoadStore(),
      form: {
        vatNumber: '',
        accountName: '',
        email: '',
        phoneNumber: '',
        firstName: '',
        lastName:'',
        accountType: null,
        cityName: '',
        streetName: '',
        houseNumber: '',
        checked: []
      },
      accountTypes: [{ text: 'Select One', value: null }, 'Private Company', 'Public Company'],
      showRegistrationForm: false
    }
  },
  methods: {
    getCompanyDetails(){
      this.storeLoad.setVisible(true)

      AuthDal.checkNewAccount(this.form.vatNumber)
          .then( data => {
            if(data.data[0].is_exist){
              this.storeLoad.setVisible(false)
              Swal.fire('Add Account', 'This account already exists in the platform !', 'error')
            }else{
              EntityDal.getEntityDetails(this.form.vatNumber)
                  .then(data => {
                    this.storeLoad.setVisible(false)
                    if(data.entities.data.result.records.length !== 1){
                      Swal.fire({title: "Account not fined", icon: "error"})
                    }else{
                      const dataFields = data.entities.data.result.fields
                      const dataValues = data.entities.data.result.records[0]
                      this.form.accountName = dataValues[dataFields[2].id]
                      this.form.cityName = dataValues[dataFields[13].id]
                      this.form.streetName = dataValues[dataFields[14].id]
                      this.form.houseNumber = dataValues[dataFields[15].id]
                      this.showRegistrationForm = true
                    }
                  })
            }
          })
    },
    onSubmit(event) {
      Swal.fire({
        title: `Set user's password`,
        html: `<input type="password" id="password" class="swal2-input" placeholder="New Password" autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" >
                 <input type="password" id="repeat-password" class="swal2-input" placeholder="Repeat New Password" autocomplete="false" readonly onfocus="this.removeAttribute('readonly');" >`,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          const password = Swal.getPopup().querySelector('#password').value
          const passwordRepeat = Swal.getPopup().querySelector('#repeat-password').value
          if (!passwordRepeat || !password) {
            Swal.showValidationMessage(`Please enter password and repeat`)
          }
          if (passwordRepeat !== password) {
            Swal.showValidationMessage(`Unmatched values of password`)
          }
          return { password , passwordRepeat }
        }
      }).then((result) => {
        if(result.isConfirmed){
          this.storeLoad.setVisible(true)
          AuthDal.signUpNewAccount(this.form.accountName, this.form.email, this.form.phoneNumber,this.form.vatNumber, this.form.accountType, this.form.cityName, this.form.streetName, this.form.houseNumber, this.form.firstName, this.form.lastName, result.value.password)
              .then(data => {
                let createdUserOtpKey = data.data.find(x => x.column_name === 'otp-key').id
                let otpAuthUrl = `otpauth://totp/SecretKey?secret=${createdUserOtpKey}`

                QRCode.toDataURL(otpAuthUrl, (err, dataURL) => {
                  if (err) {
                    Swal.fire ('New Account', `Can't generate authentication QR code`, 'error')
                  } else {
                    this.storeLoad.setVisible(false)
                    if(data.result === 'SUCCESS'){
                      Swal.fire({
                        title: `Scan this QR-code with your google authentication app :`,
                        html: `<div id="modal-content"></div>`,
                        willOpen: () => {
                          let ComponentClass = Vue.extend(
                              Vue.component("UserOtpQrCode", UserOtpQrCode)
                          );
                          let instance = new ComponentClass({
                            propsData: { imageUrl: dataURL },
                          });

                          instance.$mount();
                          document.getElementById("modal-content").appendChild(instance.$el);
                        }
                      })
                          .then(() => {
                            Swal.fire(
                                'New Account',
                                'Account created successfully!',
                                'success'
                            )
                            this.$emit('open-login')
                          })

                    }else{
                      Swal.fire(
                          'New Account',
                          'Account creation failed . Please check the data and try again',
                          'error'
                      )
                    }
                  }
                });

              })
        }else{
          this.$emit('closeModal')
        }
      })

    },
  }
}

</script>

<style scoped>
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }

  .div1 { grid-area: 1 / 1 / 2 / 2; }
  .div2 { grid-area: 1 / 2 / 2 / 3; }

  .signup-div{
    margin-top: 100px;
    width:50%;
    margin-left: 25%;
    border: 3px solid #f1f1f1;
    text-align: left;
    padding: 20px;
  }

  .form-title{
    padding-bottom: 25px;
    text-decoration: underline;
  }

  .form-group{
    padding-bottom: 20px;
  }

  .search-input{
    background: white url('../assets/images/icon-search.svg') no-repeat 15px;
    background-size: 18px 18px;
    background-position-x: 97%;
  }

  .cancel-button{
    margin-left: 10px;
  }


</style>