import {ApiDal} from './api_dal'

class DocsDal extends ApiDal{
    async getUserDocs(){
        return this.callApi('get', '/api/docs/getUserDocs', 'docs', null)
    }

    async getEntityDocuments(){
        return this.callApi('post', '/api/docs/getEntityDocuments', 'docs', {})
    }

    async updateEntityDocument(documentStatus, documentId){
        return this.callApi('post', '/api/docs/updateEntityDocument', 'docs', {documentStatus, documentId})
    }

    async getEntityDocument(documentId){
        return this.callApi('post', '/api/docs/getEntityDocument', null, {documentId})
    }

    async uploadEntityDocument(entityId , fileName, fileData){
        return this.callApi('post', '/api/docs/uploadEntityDocument', null, {entityId , fileName, fileData})
    }

    async getDocumentConfValues(entityId){
        return this.callApi('post', '/api/docs/getDocumentConfValues', null, {entityId })
    }

    async addDocumentGeneralInfo(entityDocumentId , documentId, supplierEntityId, documentTypeId, documentDate, documentValidationId, total, vat, currency, expectedPayment, documentStatus){
        return this.callApi('post', '/api/docs/addDocumentGeneralInfo', null, {entityDocumentId , documentId, supplierEntityId, documentTypeId, documentDate, documentValidationId, total, vat, currency, expectedPayment, documentStatus})
    }

    async addDocumentDetailedInfo(entityDocumentId , skuId, skuName, quantity, unit, price, discount, currency, vat, total, po, rowStatus){
        return this.callApi('post', '/api/docs/addDocumentDetailedInfo', null, {entityDocumentId , skuId, skuName, quantity, unit, price, discount, currency, vat, total, po, rowStatus})
    }

    async editDocumentDetailedInfo(id , skuId, skuName, quantity, unit, price, discount, currency, vat, total, po){
        return this.callApi('post', '/api/docs/editDocumentDetailedInfo', null, {id , skuId, skuName, quantity, unit, price, discount, currency, vat, total, po})
    }

    async deleteDocumentDetailedInfo(entityDocumentId , skuId){
        return this.callApi('post', '/api/docs/deleteDocumentDetailedInfo', null, {entityDocumentId , skuId})
    }

    async approveDocumentInfo(entityDocumentId){
        return this.callApi('post', '/api/docs/approveDocumentInfo', null, { entityDocumentId })
    }

    async getDocumentGeneralInfo(entityDocumentId){
        return this.callApi('post', '/api/docs/getDocumentGeneralInfo', null, { entityDocumentId })
    }

    async getDocumentDetailsInfo(entityDocumentId){
        return this.callApi('post', '/api/docs/getDocumentDetailsInfo', null, { entityDocumentId })
    }

    async getDocumentDetailsRowInfo(entityDocumentId, skuId){
        return this.callApi('post', '/api/docs/getDocumentDetailsRowInfo', null, { entityDocumentId, skuId })
    }

    async addDocumentClassification(documentDetailIds, classificationData){
        return this.callApi('post', '/api/docs/addDocumentClassification', null, { documentDetailIds, classificationData })
    }

    async getDocumentClassification(classificationId){
        return this.callApi('post', '/api/docs/getDocumentClassification', null, { classificationId })
    }

    async getDocumentAtoms(){
        return this.callApi('post', '/api/docs/getDocumentAtoms', 'docs', {})
    }

    async getClassificationTable(dataSource){
        return this.callApi('post', '/api/docs/getClassificationTable', null, {dataSource})
    }
}

export default new DocsDal()