import {ApiDal} from './api_dal'
class UserDal extends ApiDal{
    async getUsersListByAccount(accountId){
        return this.callApi('post', '/api/users/getUsersListByAccount', 'users', { accountId })
    }
    async getUsersList(){
        return this.callApi('get', '/api/users/getUserList', 'users', {})
    }
    async updateUserConfiguration(user){
        return this.callApi('post', '/api/users/updateUserConfiguration', 'user', { user })
    }
    async addNewUser(user){
        return this.callApi('post', '/api/users/createNewUser', 'user', { user })
    }

    async getUserMenus(userId){
        return this.callApi('post', '/api/users/getUserMenus', null, { userId })
    }

    async deactivateUser(userId){
        return this.callApi('post', '/api/users/deactivateUser', null, { userId })
    }

    async getUserQrCode(userId){
        return this.callApi('post', '/api/users/getUserQrCode', null, { userId })
    }

}

export default new UserDal()