import {ApiDal} from './api_dal'

class EmployeesDal extends ApiDal{
    async getEmployeesList(entityId) {
        return this.callApi('post', '/api/employees/GetEmployeesList', '', {entityId})
    }

    async AddEmployee(employee){
        return this.callApi('post', '/api/employees/AddEmployee', '', {employee})
    }

    async UpdateEmployee(employee){
        return this.callApi('post', '/api/employees/UpdateEmployee', '', {employee})
    }

}

export default new EmployeesDal()