<template>
  <div class="nav-bar">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark">
<!--      <a class="navbar-brand" href="#">-->
<!--        <img src="../assets/logo2.png" alt="Logo" style="width:40px;margin-left: 10px">-->
<!--      </a>-->
      <h6 class="account_name">{{accountName}}</h6>
      <div class="container-fluid">
        <div class="navbar-collapse collapse" >
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" @click="goToHome">Home</a>
            </li>
            <li class="nav-item" v-for="item in menuItems" :value="item.name" v-if="isAvailable(item.name)">
              <a class="nav-link" :menu-name="item.data.name" @click="menuItemClicked">{{item.text}}</a>
            </li>
<!--            <li class="nav-item">-->
<!--              <a class="nav-link"  data-bs-toggle="collapse" @click="$emit('show-part', 'on-line charts')">Charts</a>-->
<!--            </li>-->
          </ul>

          <ul class="navbar-nav ms-auto">
            <li class="nav-item dropdown ml-auto">
              <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{selectedUser.user_name}}</a>
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" @click="accountConfig">Account</a>
                <a class="dropdown-item" @click="changePassword">Change Password</a>
                <change-password v-if="showPasswordModal" :selected-user="selectedUser" @closeModal="showPasswordModal = false"></change-password>
                <a class="dropdown-item" @click="logOut">Logout</a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link">About</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>

import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import $ from "jquery";

import AuthDal from "@/dal/auth-dal";
import { useAuthStore } from "@/stores/auth";
import ChangePassword from "@/components/users/ChangePassword.vue"

export default {
  name: "NavBar",
  props:{
    menuItems : Array
  },
  components:{
    ChangePassword
  },
  data() {
    return {
      selectedUser: {},
      showPasswordModal: false,
      accountName: '',
      storeAuth: useAuthStore(),
    };
  },
  mounted() {
    this.accountName = this.storeAuth.getUserData.accountName
    this.selectedUser.id = this.storeAuth.getUserData.userId
    this.selectedUser.user_name = this.storeAuth.getUserData.userName

    $('.dropdown').click(function(){
      $('.dropdown-menu').toggleClass('show');
    });
  },
  methods: {
    isAvailable(itemName){
      let item = this.menuItems.find(item => item.name === itemName)

      let isAvailable = item.data.params.find(param => param.name === 'is_available')

      if(isAvailable){
        return ( isAvailable.is_checked || isAvailable.value === 'true')
      }else{
        return false
      }
    },
    menuItemClicked(evt){

      this.$emit("showPart",evt.target.attributes['menu-name'].value);
    },
    async logOut() {
      await AuthDal.logout(this.storeAuth.getUserName).then((response) => {
        this.storeAuth.setAuthorisation(!response.result === "SUCCESS");
      });
    },
    goToHome() {
      this.$emit("goBack");
    },
    accountConfig(){
      this.$emit("showPart",'account-config');
    },
    changePassword(){
      this.showPasswordModal = true
    }
  },
};
</script>

<style scoped>
.nav-link, .dropdown-item{
  cursor: pointer;
}
.account_name{
  color: white;
  width: 100%;
  position: absolute;
}
</style>
