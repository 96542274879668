import {ApiDal} from './api_dal'
class UserGroupDal extends ApiDal{
    async getAccountUserGroups(accountId){
        return this.callApi('post', '/api/usergroups/getAccountUserGroups', 'userGroups', {accountId})
    }

    async getUserGroupsList(){
        return this.callApi('get', '/api/usergroups/getUserGroupsList', 'userGroups', null)
    }

    async getUsersConnectedToUserGroup(userGroupId){
        return this.callApi('post', '/api/usergroups/getUserByUserGroup', 'users', { userGroupId })
    }

    async addAccountUserGroup(accountId, userGroupName, profileId){
        return this.callApi('post', '/api/usergroups/addAccountUserGroup', 'users', { accountId, userGroupName, profileId })
    }

    async updateAccountUserGroup(userGroupId , accountId, userGroupName, profileId){
        return this.callApi('post', '/api/usergroups/updateAccountUserGroup', 'users', { userGroupId, accountId, userGroupName, profileId })
    }

    async deleteUserGroup(userGroupId){
        return this.callApi('post', '/api/usergroups/deleteUserGroup', null, { userGroupId })
    }

    async getUserGroupTypes(){
        return this.callApi('post', '/api/usergroups/getUserGroupTypes', null, { })
    }

    async getUserGroupDashboards(userGroupId){
        return this.callApi('post', '/api/usergroups/getUserGroupDashboards', null, { userGroupId })
    }

    async connectUserGroupDashboards(userGroupId, connectionConfig){
        return this.callApi('post', '/api/usergroups/connectUserGroupDashboards', null, { userGroupId, connectionConfig })
    }
}

export default new UserGroupDal()