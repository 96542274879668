<template>
  <div class="docz-component" >
    <g-l-accounts-v2 :entity-id="entityId" :key="selectedEntityId" @newGlAccountAdded="newGlAccountAdded" ref="glAccounts"></g-l-accounts-v2>
  </div>
</template>

<script>
import GlDal from "@/dal/gl_dal"
import { useLoadStore } from "@/stores/load"
import { useGlAccountStore } from "@/stores/gl"
import GLAccounts from "@/components/gl/GLAccounts.vue"
import GLAccountsV2 from "@/components/gl/GLAccountsV2.vue"
import GLForm from "@/components/gl/GLForm.vue"
export default {
  name: 'AccountingManagement',
  props: {
    entityId: Number,
    screenKey: String
  },
  components: {
    GLForm,
    GLAccounts,
    GLAccountsV2
  },
  data(){
    return {
      glAccountsConfigData: [],
      glAccounts: [],
      selectedGLAccount: null,
      storeLoad: useLoadStore(),
      storeGlAccount: useGlAccountStore(),
      glAccountsStore: useGlAccountStore(),
      selectedEntityId: 0
    }
  },
  watch: {
    entityId: function (newVal, oldVal) {
      this.getGlAccounts()
    },
    screenKey: function (newVal, oldVal) {
      this.$refs.glAccounts.toggleMenu()
    }
  },

  mounted() {
   // this.getGlAccounts()
  },

  methods: {
    getGlAccounts(){
      let that = this
      that.storeLoad.setVisible(true)
      GlDal.getGlAccountsConfigData(this.entityId)
          .then(data => {
            that.glAccountsConfigData = data.data
            GlDal.getGlAccounts(that.entityId)
                .then(glData => {
                  this.glAccountsStore.setAllGlAccounts(glData.data)
                  that.glAccounts = glData.data
                  that.selectedEntityId = that.entityId
                })
          })
    },

    glAccountSelected(args){
      this.selectedGLAccount = args
    },

    toggleMenu(){
      console.log('account management toggle menu')
    },

    newGlAccountAdded(){
      this.$emit('newGlAccountAdded')
    },

    refreshGrid(){
      let that = this
      this.storeLoad.setVisible(true)
      GlDal.getGlAccounts(that.entityId)
          .then(glData => {
            that.glAccounts = glData.data
            that.$refs.glGrid.createGlAccountsGrid(that.glAccounts)
            that.$emit('newGlAccountAdded')
          })
    }
  }
}
</script>

<style scoped>
.parent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3;
  background: white;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
}
</style>