<template>
  <div class="form-div">
    <b-form ref="form" style="font-size: 13px" autocomplete="off" >

      <b-form-group id="input-group-entity-id" class="form-group" :label="'Supplier ID :' + form.entityId" label-for="input-entity-id" >
<!--        <p style="">{{form.entityId}}</p>-->
      </b-form-group>

      <b-form-group id="input-group-suppliers" label="Supplier Name:" label-for="input-suppliers" >
        <input type="text" v-model="form.supplierName" list="suppliers" >
        <datalist id="suppliers">
          <option v-for="supplier in suppliers" :value="supplier.text.split('|')[0]" >{{supplier.text.split('|')[1]}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-document-type" class="form-group" label="Document Type :" label-for="input-document-type">
        <b-form-select id="input-document-type" class="form-select" v-model="form.documentTypeId" :options="documentTypes" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-document-id" class="form-group" label="Document ID :" label-for="input-document-id" >
        <b-form-input type="text" class="form-text" id="input-document-id" v-model="form.documentId" placeholder="Enter Document ID" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-document-date" class="form-group" label="Document Date :" label-for="input-document-date" >
        <date-picker id="input-document-date" v-model="form.documentDate" valueType="format" style="width: 100%" :lang="en" ></date-picker>
      </b-form-group>

      <b-form-group id="input-group-document-status" class="form-group" label="Document Status :" label-for="input-document-status">
        <b-form-select id="input-document-status" class="form-select" v-model="form.documentStatusId" :options="documentStatuses" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-document-vat" class="form-group" label="Document VAT :" label-for="input-document-vat" >
        <b-form-input type="text" class="form-text percent" id="input-document-vat" v-model="form.vat+'%'" maxlength="6" pattern="[0-9]*" placeholder="Enter Document VAT" required ></b-form-input>

<!--        <input class="percent" type="text" v-model="form.vat+'%'" maxlength="6" pattern="[0-9]*"/>-->

      </b-form-group>

      <b-form-group id="input-group-document-total" class="form-group" label="Document Total :" label-for="input-document-total" >
        <b-form-input type="number" class="form-text" id="input-document-total" v-model="form.total" placeholder="Enter Document Total" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-document-currency" class="form-group" label="Document Currency :" label-for="input-document-currency">
        <b-form-select id="input-document-currency" class="form-select" v-model="form.currencyId" :options="currencies" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-document-expected-payment-date" class="form-group" label="Document Expected Payment Date :" label-for="input-document-expected-payment-date" >
        <date-picker id="input-document-expected-payment-date" v-model="form.documentExpectedPaymentDate" valueType="format"  style="width: 100%" :lang="en" ></date-picker>
      </b-form-group>

    </b-form>
  </div>
</template>

<script>
import docsDal from "@/dal/docs-dal"
import {useEntityStore} from "@/stores/entity"
import {useLoadStore} from "@/stores/load"

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/en'
import en from "vue2-datepicker/locale/es/en"

export default {
  name: 'DocumentGeneralInfoForm',
  data(){
    return {
      documentTypes : [],
      suppliers: [],
      documentStatuses: [],
      currencies: [],
      form: {
        supplierId: null,
        supplierName: '',
        entityId: '',
        documentTypeId: null,
        documentId: '',
        documentDate: null,
        documentStatusId: null,
        vat: 0,
        vatPercentage: '',
        total: 0,
        currencyId: null,
        documentExpectedPaymentDate: null,
      },
      entityStore : useEntityStore(),
      storeLoad: useLoadStore(),
      docConfValues: []
    }
  },
  components: {
    DatePicker
  },
  props: {
    selectedFileId: String
  },
  computed: {
    en() {
      return en
    }
  },
  mounted() {
    let that = this
    that.manageVatField()
    this.storeLoad.setVisible(true)
    docsDal.getDocumentConfValues(this.entityStore.entityId)
        .then(data=> {
          this.docConfValues = data.data
          this.docConfValues.forEach(x=> {
            let option = {value: x.id, text : x.name}
            switch(x.type){
              case 'supplier-name':
                this.suppliers.push(option)
                    break
              case 'currency':
                this.currencies.push(option)
                    break
              case 'document-type':
                this.documentTypes.push(option)
                    break
              case 'doc-validation':
                this.documentStatuses.push(option)
              default:
                break
            }
          })

          docsDal.getDocumentGeneralInfo(this.selectedFileId)
              .then(data => {
                if(data.data.length === 1){
                  let row = data.data[0]
                  that.form.supplierId = row.supplier_entity_id
                  that.form.supplierName = that.docConfValues.filter(x => x.type === 'supplier-name' && x.id == row.supplier_entity_id)[0].name.split('|')[0]
                  that.form.entityId = that.docConfValues.filter(x => x.type === 'supplier-name' && x.id == row.supplier_entity_id)[0].name.split('|')[1]
                  that.form.documentTypeId = row.document_type_id
                  that.form.documentId = row.document_id
                  that.form.documentDate = row.document_date
                  that.form.documentStatusId = row.document_validation_id
                  that.form.vat = that.form.vatPercentage = row.vat
                  that.form.total = row.total
                  that.form.currencyId = row.currency
                  that.form.documentExpectedPaymentDate = row.expected_payment
                }
              })

          this.storeLoad.setVisible(false)
        })
  },

  watch: {
    "form.supplierName":  function (newVal, oldVal) {
      this.form.entityId = this.docConfValues.filter(x=>x.name.includes(newVal))[0].name.split('|')[1]
    }
  },

  methods:{
    getDocumentGeneralInfo(){
      this.form.supplierId = this.docConfValues.filter(x=>x.name.includes(this.form.supplierName))[0].id
      this.form.vat = this.form.vatPercentage
      return this.form
    },

    manageVatField(){
      let that = this
      document.querySelector('.percent').addEventListener('input', function(e){
        //Separate the percent sign from the number:
        let int = e.target.value.slice(0, e.target.value.length - 1);

        /* If there is no number (just the percent sign), rewrite
           it so it persists and move the cursor just before it.*/
        if (int.includes('%')) {
          e.target.value = '%';
        }
        /* If the whole has been written and we are starting the
           fraction rewrite to include the decimal point and percent
           sign. The fraction is a sigle digit. Cursor is moved to
           just ahead of this digit.*/
        else if(int.length >= 3 && int.length <= 4 && !int.includes('.')){
          e.target.value = int.slice(0,2) + '.' + int.slice(2,3) + '%';
          e.target.setSelectionRange(4, 4);
        }
        /* If the we have a double digit fraction we split up, format it
           and print that. Cursor is already in the right place.*/
        else if(int.length >= 5 & int.length <= 6){
          let whole = int.slice(0, 2);
          let fraction = int.slice(3, 5);
          e.target.value = whole + '.' + fraction + '%';
        }
        /* If we're still just writing the whole (first 2 digits), just
           print that with the percent sign. Also if the element has just
           been clicked on we want the cursor before the percent sign.*/
        else {
          e.target.value = int + '%';
          e.target.setSelectionRange(e.target.value.length-1, e.target.value.length-1);
        }

        if(e.target.value.includes('.')){
          if(e.target.value.split('.')[1].length === 2){
            that.form.vatPercentage = that.getInt(e.target.value)/10
          }else{
            that.form.vatPercentage = that.getInt(e.target.value)/100
          }
        }else{
          that.form.vatPercentage = that.getInt(e.target.value)
        }
      })
    },

    getInt(val){
      //So as not to breakup a potential fraction
      let v = parseFloat(val);
      //If we only have the whole:
      if(v % 1 === 0){
        return v;
        //If the numberis a fraction
      }else{
        let n = v.toString().split('.').join('');
        return parseInt(n);
      }
    }
  },

}
</script>

<style scoped>
.form-div{
  text-align: left;
  padding: 20px;
}

.form-select, .form-text{
  border: 1px solid #cccccc;
  height: 36px;
  border-radius: 5px;
}

.form-group{
  margin-top: 10px;
}

</style>