<template>
  <div class="form-div">
    <b-form ref="form" style="font-size: 13px" autocomplete="off">
      <b-form-group id="input-group-sku-id" class="form-group" label="SKU ID :" label-for="input-sku-id" >
        <b-form-input type="text" class="form-text" id="input-sku-id" v-model="form.skuId" placeholder="Enter SKU ID" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-sku-name" class="form-group" label="SKU Name :" label-for="input-sku-name" >
        <b-form-input type="text" class="form-text" id="input-sku-name" v-model="form.skuName" placeholder="Enter SKU Name" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-quantity" class="form-group" label="Quantity :" label-for="input-document-quantity" >
        <b-form-input type="number" class="form-text" id="input-document-quantity" v-model="form.quantity" placeholder="Enter Quantity" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-document-unit" class="form-group" label="Unit :" label-for="input-document-unit">
        <b-form-select id="input-document-unit" class="form-select" v-model="form.unitId" :options="units" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-price" class="form-group" label="Price :" label-for="input-document-price" >
        <b-form-input type="number" class="form-text" id="input-document-price" v-model="form.price" placeholder="Enter Price" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-discount" class="form-group" label="Discount :" label-for="input-document-discount" >
        <b-form-input type="number" class="form-text" id="input-document-discount" v-model="form.discount" placeholder="Enter Discount" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-document-currency" class="form-group" label="Document Currency :" label-for="input-document-currency">
        <b-form-select id="input-document-currency" class="form-select" v-model="form.currencyId" :options="currencies" required></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-document-vat" class="form-group" label="VAT :" label-for="input-document-vat" >
        <span class="vat-span">
          <b-form-input type="number" class="form-text vat-input" id="input-document-vat" v-model="form.vat" placeholder="Enter VAT" required ></b-form-input>
          %
        </span>
      </b-form-group>

      <b-form-group id="input-group-document-total" class="form-group" label="Total :" label-for="input-document-total" >
        <b-form-input type="number" class="form-text" id="input-document-total" v-model="form.total" placeholder="Enter Total" required ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-sku-po" class="form-group" label="PO :" label-for="input-sku-po" >
        <b-form-input type="text" class="form-text" id="input-sku-po" v-model="form.po" placeholder="Enter PO" required ></b-form-input>
      </b-form-group>

    </b-form>
  </div>
</template>


<script>
import docsDal from "@/dal/docs-dal"
import {useEntityStore} from "@/stores/entity"
import {useLoadStore} from "@/stores/load"

export default {
  name: 'DocumentDetailedInfoForm',
  data(){
    return {
      units: [],
      currencies: [],
      form: {
        skuId: '',
        skuName: '',
        quantity: 0,
        unitId: null,
        price: 0,
        discount: 0,
        currencyId: null,
        vat: 0,
        total: 0,
        po: ''
      },
      storeLoad: useLoadStore(),
      entityStore : useEntityStore(),
      docConfValues: []
    }
  },
  props: {
    selectedFileId: String,
    skuId: String
  },
  mounted() {
    let that = this
    this.storeLoad.setVisible(true)
    docsDal.getDocumentConfValues(this.entityStore.entityId)
        .then(data=> {
          this.docConfValues = data.data
          this.docConfValues.forEach(x=> {
            let option = {value: x.id, text : x.name}
            switch(x.type){
              case 'currency':
                this.currencies.push(option)
                break
              case 'unit':
                this.units.push(option)
                break
              default:
                break
            }
          })

          docsDal.getDocumentDetailsRowInfo(this.selectedFileId, this.skuId)
              .then(data => {
                if(data.data.length === 1){
                  let row = data.data[0]
                  that.form.skuId = row.sku_id
                  that.form.skuName = row.sku_name
                  that.form.quantity = row.quantity
                  that.form.unitId = row.unit_id
                  that.form.price = row.price
                  that.form.discount = row.discount
                  that.form.currencyId = row.currency_id
                  that.form.vat = row.vat
                  that.form.total = row.total
                  that.form.po = row.po
                }
              })

          this.storeLoad.setVisible(false)
        })
  },
  methods:{
    getDocumentDetailsInfo(){
      return this.form
    }
  },
}
</script>

<style scoped>
.form-div{
  text-align: left;
  padding: 20px;
  max-height: 600px;
  overflow: auto;
}

.form-select, .form-text{
  border: 1px solid #cccccc;
  height: 36px;
  border-radius: 5px;
}

.form-group{
  margin-top: 10px;
}

.vat-span{
  display: flex;
  line-height: 45px;
  font-size: 1rem !important;
  font-weight: 400;
}


.vat-input{
  width: 100px;
  margin-right: 5px;
}
</style>