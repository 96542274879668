<template>
  <div id="app">
    <login v-if="!isAuthorised && !isSignUp" @open-signup="openSignUp"></login>
    <sign-up v-if="isSignUp" @open-login="openLogIn" ></sign-up>

    <main class="app-layout" v-if="isAuthorised">
      <figure>
        <left-side-nav-bar ref="leftSideNavBar" @menuItemClicked="menuItemClicked" @toggleMenu="toggleMenu"></left-side-nav-bar>
        <main-page ref="mainPage" :sub-menu-items="subMenuItems" @breadCrumbMenuElementClicked="breadCrumbMenuElementClicked"></main-page>
      </figure>
    </main>

    <loading v-if="isLoading"></loading>
    <ai-support v-if="isAuthorised"></ai-support>

  </div>
</template>

<script>
import LeftSideNavBar from "@/components/LeftSideNavBar.vue"
import MainPage from "@/components/page/MainPage.vue"
import HomeScreen from "@/screens/Settings/SettingsSystem.vue"
import Login from "@/screens/Login.vue"
import Loading from "@/components/Loading.vue"
import SignUp from "@/screens/SignUp.vue"
import {useLoadStore} from "@/stores/load"
import AiSupport from "@/components/AiSupport.vue"

import {eventBus} from "@/main"

import {useAuthStore} from "@/stores/auth"
import $ from "jquery";
export default {
    name: 'App',
    components: {
      MainPage,
      LeftSideNavBar,
      HomeScreen,
      Login,
      SignUp,
      Loading,
      AiSupport
    },
    data(){
        return{
          subMenuItems: null,
          menuClass: 'menu-closed',
          authStore: useAuthStore(),
          loadStore: useLoadStore(),
          isAuthorised: false,
          isLoading: false,
          isSignUp: false,
          isSupportOpen: false,
        }
    },
    mounted() {
      (function() {
        let dragged, listener;

        dragged = null;
        listener = document.addEventListener;
        listener("dragstart", (event) => {
          return dragged = event.target;
        });

        listener("dragend", (event) => {
          return console.log("dragend!");
        });

        listener("dragover", function(event) {
          return event.preventDefault();
        });

        listener("drop", (event) => {
          event.preventDefault();
          if (event.target.classList.contains("dropzone")) {
            if(event.target.children.length === 0 || event.target.classList.contains('source')){
              $(dragged).css('border','none')
              $(event.target).css('border','none')
              //dragged.parentNode.removeChild(dragged);
              return event.target.appendChild(dragged);
            }else{
              return null
            }
          }
        });
      }).call(this)

      this.authStore.$subscribe((mutation,state) => {
            this.isAuthorised = state.authorised
        })

        this.loadStore.$subscribe((mutation,state) => {
            this.isLoading = state.loading
        })
    },
    methods: {
      breadCrumbMenuElementClicked(args){
        this.$refs.leftSideNavBar.setSelectedMenu(args)
      },

      menuItemClicked(args){
        if(args){
          this.subMenuItems = args
        }
      },

      showPart(part){
        if(this.currentPart !== part){
          this.currentPart = part
          this.menuItemClicked(null)
          let sidebar = document.querySelector(".sidebar");
          sidebar.classList.toggle("open");
        }
      },
      hidePart() {
        this.currentPart = ''
        this.currentUserGroup = 0
        this.currentUser = "0"
      },
      openUserGroup(userGroupId){
        this.currentUserGroup = userGroupId
        this.currentPart = 'user-groups'
      },
      openUser(userId){
        this.currentUser = userId
        this.currentPart = 'users'
      },

      openSignUp(){
        this.isSignUp = true
      },

      openLogIn(){
        this.isSignUp = false
      },

      toggleMenu(args){
        this.$refs.mainPage.toggleMenu(args)
      },


    }

}
</script>

<style scoped>
.ai-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #ada5df;
  border-radius: 50px;
  padding: 10px;
  box-shadow: 0 0 12px gray;
}

.ai-button:hover{
  transform: scale(1.2);
}

.ai-button svg {
  width: 25px;
  height: 25px;
}
</style>