<template>
  <div  class="docz-component">
    <div class="table-area"  v-if="selectedEntity == null">

<!--      <button @click="goBack()">-->
<!--        <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back-->
<!--      </button>-->
<!--      <h2>{{accountName}} : Entities</h2>-->
      <div class="datatable"></div>
    </div>
    <entity-form v-if="selectedEntity != null" :selected-entity="selectedEntity" @update-entity="updateEntity" @cancel-update="cancelUpdate"></entity-form>
<!--    <book-keepers-main  v-if="selectedEntity != null" :selected-entity="selectedEntity" @backToEntities="getAllEntities"></book-keepers-main>-->
<!--    <customers v-if="selectedEntity != null" :entity-id="selectedEntity.id" @goToBookKeeperMain="getAllEntities"></customers>-->
  </div>

</template>

<script>
import $ from "jquery";
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import {useUserStore} from "@/stores/user"
import {useAuthStore} from "@/stores/auth"
import {useLoadStore} from "@/stores/load"

import EntityDal from "@/dal/entity_dal"
import Customers from "@/screens/Income/IncomeManagement.vue"
import BookKeepersMain from "@/components/bookkeepers/BookKeepersMain.vue"
import EntityForm from "@/components/entities/EntityForm.vue"
import {entityManagerClass} from "@/components/entities/entityManager"

import Swal from "sweetalert2";

export default {
  name: "Entities",
  components: {
    Customers,
    BookKeepersMain,
    EntityForm
  },
  data() {
    return {
      storeLoad: useLoadStore(),
      userStore : useUserStore(),
      authStore: useAuthStore(),
      selectedEntity: null,
      fetchedEntity: [],
      accountName: '',
      allEntities: null,
      entitiesTable: null,
      entityManager: new entityManagerClass()
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getAllEntities()
  },

  methods: {
    async getAllEntities() {
      this.selectedEntity = null
      this.accountName = this.authStore.getUserData.accountName

      switch (this.authStore.getUserData.userType){
        case 'user':
          break;
        case 'account-manager':
          EntityDal.getUserGroupEntities(this.authStore.getUserData.accountId, this.authStore.getUserData.userGroupId)
              .then(data => {
                this.proceedEntitiesData(data)
              })
          break;
        case 'administrator':
          EntityDal.getAllEntities()
              .then(data =>{
                this.proceedEntitiesData(data)
              })
          break;
      }
    },
    proceedEntitiesData(data){
      this.allEntities = data.entities.data

      let dataColumns = []
      let dataRows = []
      data.entities.columnList.forEach((title) => {
        dataColumns.push({
          title: title
              .split("_")
              .join(" ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
              ),
        });
      });
      dataColumns.push({ title: "" }); // add column for buttons

      data.entities.data.forEach((row) => {
        let dataRow = [];
        data.entities.columnList.forEach((value) => {
          dataRow.push(row[value]);
        });
        dataRows.push(dataRow);
      });

      let that = this
      $(".datatable").html("")
      $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable"></table>')
      this.entitiesTable = $("#datatable").DataTable({
        data: dataRows,
        columns: dataColumns,
        pageLength: 5,
        // scrollX: true,
        columnDefs: [
          {
            targets: -1,
            width: "80px",
            render: function (data, type, row) {
              return `<button class="icon-btn grid-btn" value="edit-name"><i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                      <button class="icon-btn grid-btn" value="integration"><i class='fa fa-external-link' style='font-size: 16px; color: #334249'></i></button>
                      <button class="icon-btn grid-btn" value="manage"><i class='fas fa-book' style='font-size: 16px; color: #334249'></i></button> `;
            },
          },
          {
            targets: 2,
            render(data,type,row){
              return `<a class="grid-link" value="edit-entity" entity-id="${row[0]}" style="text-decoration: underline">${data}</a>`
            }
          },
          {
            targets: [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22],
            visible: false,
            searchable: false,
          }
        ],
        drawCallback: function (settings) {
          that.initButtons()
        },
        dom: "Blfrtip",
        buttons: [
          "copy",
          "csv",
          "print",
          "excel",
          {
            text: "New Entity",
            action: function (e, dt, node, config) {
              that.setEntityType("add", 0);
            },
          },
          {
            text: "Refresh",
            action: function (e, dt, node, config) {
              that.getAllEntities()
            },
          },
        ],
        bDestroy: true,
      });
      this.storeLoad.setVisible(false)
    },

    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn")
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText
          );
        });
      });

      let allLinks = document.getElementsByClassName("grid-link")
      Array.from(allLinks).forEach( link => {

        link.addEventListener('click', function (event){

          that.proceedAction(
              link.getAttribute('value'),
              event.target.closest("tr").cells[0].innerText
          )
        })
      })
    },
    proceedAction(actionType,entityId){

      switch (actionType){
        case 'edit-name':
          this.editEntityName(entityId)
          break;
        case 'edit-entity':
          this.editEntity(entityId)
          break;
        case 'integration':
          this.integrateEntity(entityId);
          break;
        case 'manage':
          this.selectedEntity = this.allEntities.find(entity => entity.id == entityId)
          break;
        default:
          break;
      }
    },

    editEntityName(entityId){
      let selectedEntity = this.allEntities.find(entity => entity.id == entityId)

      Swal.fire({
        title: 'Update Entity',
        width: '500px',
        html: `<input type="text" id="entity-name" style="width:80%" class="swal2-input" placeholder="Entity Name" value="${selectedEntity.entity_name}" >
                 <input type="text" id="entity-name-en" style="width:80%"  class="swal2-input" placeholder="Entity Name - EN"  value="${selectedEntity.entity_name_en ? selectedEntity.entity_name_en: ''}">`,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          const entity_name = Swal.getPopup().querySelector('#entity-name').value
          const entity_name_en = Swal.getPopup().querySelector('#entity-name-en').value

          return { entity_name , entity_name_en }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          EntityDal.updateEntityName(entityId, result.value.entity_name, result.value.entity_name_en)
              .then(data => {
                if(data.entities.data.length === 1){
                  Swal.fire('Update Entity', 'Entity Updated Successfully', 'success').then(
                      data => {
                        this.getAllEntities()
                      }
                  )
                }else{
                  Swal.fire('Update Entity', 'Failed to Updated Entity', 'error')
                }

              })
        }
      })
    },

    editEntity(entityId){
      this.selectedEntity = this.allEntities.find(entity => entity.id == entityId)
    },

    integrateEntity(entityId){
      this.storeLoad.setVisible(true)
      EntityDal.getEntityIntegrationConf(entityId)
          .then(data => {
            let existingConfig = data.data.length ? data.data[0] : null

            let selectedEntity = this.allEntities.find(entity => entity.id == entityId)
            Swal.fire({
              title: `${selectedEntity.entity_name} : Entity Integration`,
              width: '500px',
              html: `<label for="integration-type"  style="width: 80%; text-align: left; margin-top: 20px">Integration Type :</label>
               <select id="integration-type" style="width:80%"  class="swal2-input"><option value="0" disabled selected>--Integration Type--</option><option value="1">PRIORITY</option></select>
               <label for="integration-url"  style="width: 80%; text-align: left; margin-top: 20px">Integration URL :</label>
               <input type="text" id="integration-url" style="width:80%" class="swal2-input" placeholder="Integration URL" >
               <label for="integration-username"  style="width: 80%; text-align: left; margin-top: 20px">Integration User-Name :</label>
               <input type="text" id="integration-username" style="width:80%" class="swal2-input" placeholder="Integration user-name">
               <label for="integration-password"  style="width: 80%; text-align: left; margin-top: 20px">Integration User-Password :</label>
               <div style="display: flex; width: 100%;">
                <input id="integration-password-enabled" type="checkbox" style="width:5%">
                <input type="text" id="integration-password" style="margin-left: 22px; width:355px" class="swal2-input" placeholder="Integration password" disabled>
                </div>
                <label for="integration-reference-id"  style="width: 80%; text-align: left; margin-top: 20px">Integration Reference ID :</label>
               <input type="text" id="integration-reference-id" style="width:80%" class="swal2-input" placeholder="Integration Reference ID">
                <label id="validation-error" style="color: red"></label>`,
              showCancelButton: true,
              confirmButtonText: 'Update',
              preConfirm: () => {
                let integrationType = Swal.getPopup().querySelector('#integration-type').value
                let integrationUrl = Swal.getPopup().querySelector('#integration-url').value
                let integrationUserName = Swal.getPopup().querySelector('#integration-username').value
                let integrationPassword= Swal.getPopup().querySelector('#integration-password').value
                let integrationReferenceId= Swal.getPopup().querySelector('#integration-reference-id').value

                integrationPassword =  integrationPassword == '' ? '-1' : integrationPassword

                let passwordEnabled =Swal.getPopup().querySelector('#integration-password-enabled').checked

                if(integrationUserName == ''){
                  Swal.getPopup().querySelector('#validation-error').innerHTML = "Empty user-name"
                  return false
                }else if(integrationType == '-1'){
                  Swal.getPopup().querySelector('#validation-error').innerHTML = "Empty Integration Type"
                  return false
                }else if(integrationUrl == ''){
                  Swal.getPopup().querySelector('#validation-error').innerHTML = "Empty URL"
                  return false
                }else if(integrationReferenceId == ''){
                  Swal.getPopup().querySelector('#validation-error').innerHTML = "Empty Reference ID"
                  return false
                }else if(passwordEnabled && integrationPassword == ''){
                  Swal.getPopup().querySelector('#validation-error').innerHTML = "Empty Password"
                  return false
                }else {
                  return { integrationType, integrationUrl, integrationUserName, integrationPassword, integrationReferenceId }
                }
              }
            }).then((result) => {
              if (result.isConfirmed) {
                this.storeLoad.setVisible(true)
                EntityDal.addEntityIntegrationConf(entityId,  result.value).then(data => {
                  if(data.entities.data.length === 1){
                    Swal.fire('Update Entity Integration', 'Entity Integration Updated Successfully', 'success').then(
                        data => {
                          this.getAllEntities()
                        }
                    )
                  }else{
                    Swal.fire('Update Entity Integration', 'Failed to Updated Entity Integration', 'error')
                        .then(() => {
                          this.storeLoad.setVisible(false)
                        })
                  }
                })
              }
            })

            Swal.getPopup().querySelector('#integration-password-enabled')
                .addEventListener('change', function() {
                  let passInput = Swal.getPopup().querySelector('#integration-password')
                  passInput.disabled = !this.checked
                  passInput.style.opacity = this.checked ? '1' : '0.5'
                })

            if(existingConfig){
              Swal.getPopup().querySelector('#integration-url').value = existingConfig["external_url"]
              Swal.getPopup().querySelector('#integration-username').value = existingConfig["integration_username"]
              Swal.getPopup().querySelector('#integration-reference-id').value = existingConfig["external_reference_id"]
              Swal.getPopup().querySelector('#integration-type').value = existingConfig["integration_type_id"]
            }

            this.storeLoad.setVisible(false)
          })
    },

    setEntityType(action, id){
      let that = this
      let inputOptions = new Promise(function (resolve){
        resolve({
          'api': "By API",
          'manually': 'Manually Input'
        })
      })

      Swal.fire({
        title: "Add New Entity",
        input: "radio",
        inputOptions: inputOptions,
        showCancelButton: true,
        inputValue: 'api',
        inputValidator: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              resolve();
            } else {
              reject('You need to select something!');
            }
          }).catch(function(rej) {
            console.log(rej);
          });
        }
      }).then(function(result) {
        if(result.isConfirmed){
          if(result.value === 'api'){
            that.addEntityByApi()
          }else{
            that.addEntityManually()
          }
        }
      })
    },

    addEntityByApi() {
      Swal.fire({
        title: 'Enter company registration number',
        input: 'text',
        inputLabel: 'Company registration number :',
        inputValue: '',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write something!'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value) {
            this.storeLoad.setVisible(true)
            EntityDal.checkEntity(result.value)
                .then(data => {
                  if(data.entity.data.rows[0].not_exist){
                    EntityDal.getEntityDetails(result.value)
                        .then(data => {
                          if(data.entities.data.result.records.length === 0 ){
                            Swal.fire(`No company found`)
                          }else{
                            let fields = data.entities.data.result.fields
                            let values = data.entities.data.result.records[0]
                            let labels = ''
                            fields.forEach((field , i)=>{
                              labels += `<label class="company_field_label">${fields[i].id} : </label><label  class="company_value_label">${values[fields[i].id]}</label>`
                              this.fetchedEntity.push(values[fields[i].id])
                            })
                            this.fetchedEntity.push(this.authStore.getUserData.accountId)
                            this.fetchedEntity.push(this.authStore.getUserData.userId)
                            this.fetchedEntity.push(this.authStore.getUserData.userGroupId)
                            this.storeLoad.setVisible(false)
                            Swal.fire({
                              title: 'Company found',
                              width: 650,
                              showCancelButton: true,
                              showConfirmButton: true,
                              confirmButtonText: 'Save',
                              cancelButtonText: "Cancel",
                              html : labels
                            }).then( (result) => {
                              if(result.isConfirmed){
                                this.storeLoad.setVisible(true)
                                this.fetchedEntity.shift()
                                EntityDal.addEntity(this.fetchedEntity)
                                    .then(data => {
                                      this.fetchedEntity = []
                                      this.getAllEntities()
                                      this.storeLoad.setVisible(false)
                                    })
                              }
                            })
                          }
                        })
                  }
                  else{
                    this.storeLoad.setVisible(false)
                    Swal.fire('New Entity', 'This entity is already related to another account !', 'error')
                  }
                })

          }
        }
      })
    },

    addEntityManually(){
      let that = this
      Swal.fire({
        title: 'Add New Entity',
        html: this.entityManager.getNewEntityModalHtml(),
        showCancelButton: true,
        preConfirm: function() {return that.entityManager.preConfirmNewEntity()}
      }).then(function (result) {
        if (result.isConfirmed) {
          let entityDetails = [result.value[0],result.value[1],'',result.value[7],'active','',null,null,null,null,null,null,result.value[2],result.value[3],result.value[4],result.value[5],null,result.value[6],null,null,null,null,null,null,null,null,null,null,null,null,that.authStore.getUserData.accountId,that.authStore.getUserData.userId,that.authStore.getUserData.userGroupId]
          //
          // {
          //   "entityId":"512163825",
          //     "entityMame":"MERBANCO FINANCES",
          //     "entityNameEn":"",
          //     "entityType":"private company",
          //     "status":"active",
          //     "description":"",
          //     "target":null,
          //     "createdDate":null,
          //     "isGovernment":null,
          //     "isLimited":null,
          //     "isViolated":null,
          //     "lastYearlyReport":null,
          //     "cityName":"חיפה",
          //     "streetName":"הרצל",
          //     "houseNumber":"11",
          //     "zipCode":"12345",
          //     "poBox":null,
          //     "countryName":null,
          //     "via":null,
          //     "subStatus":null
          // }




          that.storeLoad.setVisible(true)
          EntityDal.addEntity(entityDetails)
              .then(data => {
                that.fetchedEntity = []
                that.getAllEntities()
                that.storeLoad.setVisible(false)
              })
        }
      })
    },

    updateEntity(entity){
      this.storeLoad.setVisible(true)
      EntityDal.updateEntity(entity)
          .then(data => {
            if(data.result === 'SUCCESS'){
              Swal.fire('Update Entity', 'Entity Updated Successfully', 'success').then(
                  data => {
                    this.getAllEntities()
                  }
              )
            }else{
              this.storeLoad.setVisible(false)
              Swal.fire('Update Entity', 'Failed to Updated Entity', 'error')
            }

          })
    },

    cancelUpdate(){
      this.selectedEntity = null
      this.storeLoad.setVisible(true)
      this.getAllEntities()
    },

    goBack(){
      this.$emit("goBack");
    }
  }
}

</script>


<style scoped>
.swal2-input, .swal2-file, .swal2-textarea, .swal2-select, .swal2-radio, .swal2-checkbox {
  margin: 0em 2em 3px;
}
</style>