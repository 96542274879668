<template>
  <div class="SelectWithSplitter select-area field-area select-container">

    <div ref="inputContainer">

      <figure class="select-field field-item selectPart">
        <label for="browser" class="select-label">{{title}}</label>
        <input list="elements" name="elements"  :class="'data-list-input ' + 'dataListInput-'+currentTabId" ref="select-input" @change="optionSelected" v-model="selectedOption" >

        <datalist id="elements">
          <option v-for="item in dataOptions" :key="item.id" :value="item.value" :data-value="item.id">{{item.id}}</option>
        </datalist>
      </figure>


      <div class="range-area field-area range-container rangePart"  @change="rangeChanged">
        <label>Split Rate</label>
        <figure class="theRange field-item">
          <label class="range-label box-field">{{selectedRate}} %</label>
          <b-form-input type="range" min="0" max="100" value="0" :class="'range-input '+'rangeInput-'+currentTabId" ref="range-input" v-model="selectedRate"></b-form-input>
        </figure>
      </div>

    </div>

    <figure class="field-btn field-item" v-if="!existingValue">
      <label for="addNewPart">{{addButtonLabel}}</label>
      <b-button id="addNewPart" @click="cloneInput(null)">+</b-button>
    </figure>

    <b-button variant="primary" ref="next-button" class="next-btn" @click="next" v-if="allCompleted && !existingValue">Next</b-button>
  </div>
</template>

<script >
import Swal from "sweetalert2";

export default {
  name: 'SelectWithSplitterComponent',
  props: {
    title : String,
    nextComponentId: Number,
    additionalDetails: Array,
    currentTabId: String,
    existingValue: Array
  },
  data(){
    return {
      dataOptions: [{id: 0 , value : 'AAA'}, {id: 1, value: 'BBB'}],
      addButtonLabel : this.additionalDetails[0].label,
      allCompleted: false,
      selectedOption: this.existingValue ? this.existingValue[0].selectedOption : null,
      selectedRate: this.existingValue ? this.existingValue[0].selectedRate : null
    }
  },

  mounted() {
    let that = this
    if(this.existingValue){
      this.existingValue.forEach((value, i)=> {
        if(i>=1){
          that.cloneInput(i)
        }
      })
    }
  },

  methods: {
    cloneInput(index){
      let selectPart = this.$refs.inputContainer.getElementsByClassName('selectPart')[0]
      let rangePart = this.$refs.inputContainer.getElementsByClassName('rangePart')[0]

      let inputContainer = this.$refs.inputContainer

      let newSelectPart = selectPart.cloneNode(true)
      newSelectPart.addEventListener('change', this.optionSelected)


      let newRangePart = rangePart.cloneNode(true)
      newRangePart.addEventListener('change', this.rangeChanged)

      let lastChild = inputContainer.lastElementChild
      inputContainer.insertBefore(newSelectPart,lastChild.nextSibling)
      newSelectPart.getElementsByClassName('data-list-input')[0].value = index ?  this.existingValue[index].selectedOption : ''

      lastChild = inputContainer.lastElementChild
      inputContainer.insertBefore(newRangePart,lastChild.nextSibling)

      newRangePart.getElementsByClassName('range-input')[0].value = index ? this.existingValue[index].selectedRate : '0'
      newRangePart.getElementsByClassName('range-label')[0].innerHTML = index ? this.existingValue[index].selectedRate + ' %' : '0 %'

      this.optionSelected()
    },

    optionSelected(evt){
      let completed = true
      let selectInputs = Array.from(this.$refs.inputContainer.getElementsByClassName('data-list-input'))
      selectInputs.forEach(input => {
        if(input.value === ''){
          completed = false
        }
      })

      this.allCompleted = completed
    },

    rangeChanged(evt){
      evt.target.previousSibling.innerHTML = evt.target.value + " %"
    },

    next(){
      let total = 0
      let selectedRanges = Array.from(this.$refs.inputContainer.getElementsByClassName('range-input'))
      selectedRanges.forEach(input =>{
        total += parseInt( input.value)
      })

      if(total === 100){
        this.$emit('component-value-changed' , {nextComponentId: this.nextComponentId , value: null, nextButton: this.$refs["next-button"]})
      }else{
        Swal.fire('Validation Error', 'The total percentage of all parts should be 100%', 'error')
      }
    },

    getConfiguration(){
      let configuration = []
      let dataListInputs = Array.from(document.getElementsByClassName('dataListInput-'+this.currentTabId))
      let rangeInputs = Array.from(document.getElementsByClassName('rangeInput-'+this.currentTabId))
      dataListInputs.forEach((input,i) => {
        configuration.push({selectedOption: input.value , selectedRate: rangeInputs[i].value})
      })

      return {data: configuration}
    }
  }
}
</script>

<style scoped>

</style>