import {ApiDal} from './api_dal'

class BanksDal extends ApiDal{
    async getBankUploadedFiles(entityId){
        return this.callApi('post', '/api/banks/getBankUploadedFiles', null, {entityId})
    }

    async getEntityBankAccounts(entityId){
        return this.callApi('post', '/api/banks/getEntityBankAccounts', null, {entityId})
    }

    async addBankUploadedFile(entityId, bankEntityId, fileName, fileType, fileData, moduleName){
        return this.callApi('post', '/api/banks/addBankUploadedFile', null, {entityId, bankEntityId, fileName, fileType, fileData, moduleName})
    }
}

export default new BanksDal()