<template>
  <div>
    <button @click="goBack()">
      <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
    </button>

    <h2>{{this.selectedEmployee ? `${form.firstName} ${form.lastName} : Update Data` : 'New Employee Onboarding'}}</h2>
    <b-form @submit.prevent="createEmployee" >
      <div class="parentElement">
        <div class="box1">
          <label class="input-label" for="new-employee-identity-id">Identity ID :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="hash"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-identity-id" placeholder="Identity ID" required v-model="form.identityId"></b-form-input>
          </b-input-group>
        </div>
        <div class="box2">
          <label class="input-label" for="new-employee-first-name">First Name :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="person-badge"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-first-name" placeholder="First Name" required v-model="form.firstName"></b-form-input>
          </b-input-group>
        </div>
        <div class="box3">
          <label class="input-label" for="new-employee-last-name">Last Name :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="person-badge-fill"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-last-name" placeholder="Last Name" required v-model="form.lastName"></b-form-input>
          </b-input-group>
        </div>
        <div class="box4">
          <label class="input-label" for="new-employee-email">Email :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="envelope"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-email" placeholder="Email" required v-model="form.email"></b-form-input>
          </b-input-group>
        </div>
        <div class="box5">
          <label class="input-label" for="new-employee-phone-number">Phone Number :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="phone"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-phone-number" placeholder="Phone Number" required v-model="form.phoneNumber"></b-form-input>
          </b-input-group>
        </div>
        <div class="box6"></div>
        <div class="box7">
          <label class="input-label" for="new-employee-position-name">Position Name :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="bag"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-position-name" placeholder="Position Name" required v-model="form.positionName"></b-form-input>
          </b-input-group>
        </div>
        <div class="box8">
          <label class="input-label" for="new-employee-city-name">City Name :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="building"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-city-name" placeholder="City Name" required v-model="form.cityName"></b-form-input>
          </b-input-group>
        </div>
        <div class="box9">
          <label class="input-label" for="new-employee-postal-zip">Postal /ZIP :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="signpost"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-postal-zip" placeholder="Postal / ZIP" v-model="form.postalZip"></b-form-input>
          </b-input-group>
        </div>
        <div class="box10">
          <label class="input-label" for="new-employee-address">Address :</label>
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="house-door"></b-icon>
            </b-input-group-prepend>
            <b-form-input type="text" id="new-employee-address" placeholder="Address" required v-model="form.address"></b-form-input>
          </b-input-group>
        </div>
      </div>

        <b-button type="submit" variant="primary">{{selectedEmployee ? 'Update' : 'Create'}}</b-button>
        <b-button variant="light" @click="goBack">Cancel</b-button>
    </b-form>
  </div>
</template>

<script>
import EmployeesDal from "@/dal/employees_dal"
import {useLoadStore} from "@/stores/load"
import Swal from "sweetalert2";

export default {
  props:{
    entityId: Number,
    selectedEmployee: Object
  },
  name: "NewEmployee",
  data(){
    return{
      storeLoad: useLoadStore(),
      form: {
        id: this.selectedEmployee ? this.selectedEmployee.id : null,
        identityId : this.selectedEmployee ? this.selectedEmployee.identity_id : '',
        entityId: this.selectedEmployee ? this.selectedEmployee.entity_id : null,
        firstName: this.selectedEmployee ? this.selectedEmployee.first_name : '',
        lastName: this.selectedEmployee ? this.selectedEmployee.last_name : '',
        email: this.selectedEmployee ? this.selectedEmployee.email : '',
        phoneNumber: this.selectedEmployee ? this.selectedEmployee.phone_number : '',
        positionName: this.selectedEmployee ? this.selectedEmployee.position_name : '',
        cityName: this.selectedEmployee ? this.selectedEmployee.city_name : '',
        postalZip: this.selectedEmployee ? this.selectedEmployee.postal_zip : '',
        address: this.selectedEmployee ? this.selectedEmployee.address : ''
      }
    }
  },
  mounted() {
    this.form.entityId = this.entityId
  },
  methods:{
    createEmployee(){
      if(this.selectedEmployee){
        Swal.fire('Update employee data', 'Please approve to update the data', 'warning')
            .then(result => {
              if(result.isConfirmed){
                this.storeLoad.setVisible(true)
                EmployeesDal.UpdateEmployee(this.form)
                    .then(data => {
                      this.$emit('showEmployees')
                    })
              }
            })
      }else{
        Swal.fire('Create New Employee', 'Please approve to create the record', 'warning')
            .then(result => {
              if (result.isConfirmed) {
                this.storeLoad.setVisible(true)
                EmployeesDal.AddEmployee(this.form)
                    .then(data => {
                      this.$emit('showEmployees')
                    })
              }
            })
      }
    },
    goBack(){
      this.$emit('showEmployees')
    }
  }
}

</script>

<style scoped>
.parentElement {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-row-gap: 3px;
  grid-column-gap: 10px;
}

.box1 { grid-area: 1 / 1 / 2 / 4; }
.box2 { grid-area: 1 / 4 / 2 / 7; }
.box3 { grid-area: 2 / 1 / 3 / 4; }
.box4 { grid-area: 2 / 4 / 3 / 7; }
.box5 { grid-area: 3 / 1 / 4 / 7; }
.box6 { grid-area: 4 / 1 / 5 / 7; }
.box7 { grid-area: 5 / 1 / 6 / 3; }
.box8 { grid-area: 5 / 3 / 6 / 5; }
.box9 { grid-area: 5 / 5 / 6 / 7; }
.box10 { grid-area: 6 / 1 / 7 / 7; }

div.input-group-text{
  border-radius: unset;
  background-color:#e2e5e8;
  padding-bottom: 10px;
  border-color: grey;
  margin-right: 1px;
}
.input-label{
  display: flex;
  margin-top: 20px;
}

b-form-input{
  border-radius: 0px;
}

</style>