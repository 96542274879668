<template>
  <div class="docz-component">
    <div class="table-area">
      <table id="datatable" class="table table-hover non-editable"
      ></table>
    </div>
  </div>
</template>

<script>
import BanksDal from "@/dal/banks_dal"
import { useLoadStore } from "@/stores/load"
import $ from "jquery"
import Swal from "sweetalert2";
import { useEntityStore} from "@/stores/entity"

export default {
  name: 'BankDataOnBoarding',
  data(){
    return {
      storeLoad: useLoadStore(),
      entityStore: useEntityStore(),
      allFilesData: []
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getBankUploadedFiles()
  },
  methods: {
    getBankUploadedFiles(){
      this.storeLoad.setVisible(true)
      BanksDal.getBankUploadedFiles(this.entityStore.getCurrentEntity)
          .then(data => {
            this.storeLoad.setVisible(false)
            this.allFilesData = data.data
            let dataColumns = [];
            let dataRows = [];
            data.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            });
            this.allFilesData.forEach((row) => {
              let dataRow = [];
              data.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });

            let that = this;
            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 5,
              //stateSave: true,
              "aaSorting": [],
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "New File",
                  action: function (e, dt, node, config) {
                    that.proceedAction("add", 0);
                  },
                },
              ],
              bDestroy: true,
            });
          })
    },

    proceedAction(actionType, id){
      if(actionType === 'add'){
        let entityId = this.entityStore.getCurrentEntity
        this.storeLoad.setVisible(true)
        BanksDal.getEntityBankAccounts(entityId)
            .then(data => {
              this.storeLoad.setVisible(false)
              if(data.result === 'SUCCESS'){
                if(data.data.length > 0){
                  let bankEntityOptions = ''
                  data.data.forEach(row => {
                    bankEntityOptions += `<option value="${row.id}">${row.account_alias} - ${row.bank_account_id}</option>`
                  })
                  let uploadFileData
                  Swal.fire({
                    title: 'Upload new file',
                    html: `<label for="banksEntityId" style="width:100%; text-align: left">Choose bank account</label>
                 <select id="banksEntityId" style="border: 1px solid #cccccc; border-radius: 3px; height: 50px; margin-bottom: 10px;">
                    ${bankEntityOptions}
                 </select>
                 <input class="inputFile" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                 <label id="errorMessage" style="color: red"></label>`,
                    width:600,
                    showCancelButton: true,
                    didOpen(popup) {
                      let that = this
                      let fileInput = document.getElementsByClassName('inputFile')[0]
                      fileInput.addEventListener('change',function (event) {
                        $("#errorMessage").html('')
                        let fileToLoad = event.currentTarget.files[0]
                        let fileReader = new FileReader()
                        let base64
                        fileReader.onload = function(fileLoadedEvent) {
                          base64 = fileLoadedEvent.target.result;
                          base64 = base64.replace(`data:${fileToLoad.type};base64,`,'')
                          uploadFileData = {
                            entityId: entityId,
                            bankEntityId: $("#banksEntityId").val(),
                            fileName: fileToLoad.name ,
                            fileType: fileToLoad.type ,
                            fileData: base64
                          }
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(fileToLoad);

                      })
                    },
                    preConfirm(inputValue) {
                      if(document.getElementsByClassName('inputFile')[0].value === ''){
                        $("#errorMessage").html('Please select the file')
                        return false
                      }
                    }
                  }).then((result) => {
                    if (result.isConfirmed) {
                      this.storeLoad.setVisible(true)
                      BanksDal.addBankUploadedFile(entityId, uploadFileData.bankEntityId, uploadFileData.fileName, uploadFileData.fileType, uploadFileData.fileData, 'banksdata')
                          .then(data => {
                            this.storeLoad.setVisible(false)
                            if(data.result === 'SUCCESS'){
                              Swal.fire('Upload bank file', 'File uploaded successfully', 'success')
                                  .then(() => {
                                    this.getBankUploadedFiles()
                                  })
                            }else{
                              Swal.fire('Upload bank file', 'File upload failed . Please contact your system administrator', 'error')
                            }
                          })
                    }
                  })
                }
                else{
                  Swal.fire('Add new file', "This entity has no bank account . Please add at least one bank account to the entity and try again ", 'error')
                }
              }else{
                Swal.fire('Add new file', "Can't get bank accounts . Please contact your system administrator", 'error')
              }
            })
      }
    }
  }
}

</script>

<style scoped>

</style>