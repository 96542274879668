import { render, staticRenderFns } from "./NewEntity.vue?vue&type=template&id=7952ba54&scoped=true&"
import script from "./NewEntity.vue?vue&type=script&lang=js&"
export * from "./NewEntity.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7952ba54",
  null
  
)

export default component.exports