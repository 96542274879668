<template>
  <div class="DocumentDetailedInfo">
    <div class="table-area">
      <table id="detailedInfoDt" class="table table-hover data-table" ></table>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import moment from "moment/moment"
import {eventBus} from "@/main"

import {useEntityStore} from "@/stores/entity"
import docsDal from "@/dal/docs-dal"
import {useLoadStore} from "@/stores/load"
import Swal from "sweetalert2";

export default {
  name: 'DocumentDetailedInfo',
  props: {
    selectedFileId: String,
    rowsApproved: Boolean
  },
  data(){
    return {
      dataColumns : [
        { title: 'SKU ID', type: 'text' },
        { title: 'SKU Name', type: 'text'},
        { title: 'Status'},
        { title: 'Quantity', type: 'text' },
        { title: 'Unit', type: 'select', alias: 'unit' },
        { title: 'Price', type: 'text' },
        { title: 'Discount', type: 'text' },
        { title: 'Currency', type: 'select', alias: 'currency' },
        { title: 'Vat', type: 'text' },
        { title: 'Total', type: 'text' },
        { title: 'PO', type: 'text' },
        { title: 'ID' },
        { title: 'Classification'},
        { title: ''}
      ],
      docConfValues: [],
      storeLoad: useLoadStore(),
      entityStore : useEntityStore(),
      detailedInfoTable: null,
      dataRows: []
    }
  },
  mounted() {
    this.loadDocumentDetailedInfo()
  },

  methods: {
    loadDocumentDetailedInfo(){
      this.storeLoad.setVisible(true)
      docsDal.getDocumentConfValues(this.entityStore.entityId)
          .then(data=> {
            this.docConfValues = data.data

            docsDal.getDocumentDetailsInfo(this.selectedFileId)
                .then(data => {
                  this.createDetailedInfoDt(data.data.length >0 ? data.data : null)
                })
          })
    },

    createDetailedInfoDt(data){
      let that = this
      this.dataRows = []

      if(data){
        data.forEach(row => {
          let documentCurrency = this.docConfValues.filter(x => {return x.type === 'currency' && x.id == row.currency_id})[0].name
          let documentUnit = this.docConfValues.filter(x => {return x.type === 'unit' && x.id == row.unit_id})[0].name
          this.dataRows.push([row.sku_id, row.sku_name, row.classification ? 2 : row.row_status, row.quantity, documentUnit, row.price, row.discount, documentCurrency, row.vat+'%', row.total, row.po , row.id, row.classification ])
        })
      }

      this.detailedInfoTable = $("#detailedInfoDt").DataTable({
        data: this.dataRows,
        columns: this.dataColumns,
        lengthChange: false,
        searching: false,
        ordering: false,
        "bInfo": false,
        bPaginate: false,
        "sDom": 'ft',
        bDestroy: true,
        columnDefs: [
          {
            targets: -1,
            width: '70px',
            searchable: false,
            orderable: false,
            render: function (data,type,row) {

              return `<button class="icon-btn-details grid-btn" value="edit" style="padding: ${row[12] ? '0px' : '0px 24px 0px 0px'}"> <i class='fa fa-edit' style='font-size: 16px; color: #334249'></i></button>
                      ${row[12] ? '<button class="icon-btn-details grid-btn" value="classification" style="padding: 0px" row-id="'+row[11]+'"><i class="fa fa-eye" style="font-size: 16px; color: #334249"></i></button>' : '' }
                      <button class="icon-btn-details grid-btn" value="delete" style="padding: 0px"><i class='fa fa-close' style='font-size: 16px; color: #334249'></i></button>`
            }
          },
          {
            targets: 2,
            render: function (data, type, row) {
              if(row[2] == 0){
                return `<p><i class='fa fa-clock' style='font-size: 20px; color: #EE956FFF'></i><p>`
              }else if(row[2] == 1){
                return `<p><i class='fa fa-circle' style='font-size: 20px; color: #826fee'></i><p>`
              }else{
                return `<p><i class='fa fa-circle' style='font-size: 20px; color: #68b368'></i><p>`
              }

            }
          },
          {
            target: [11,12],
            visible: false,
            searchable: false
          },
        ],
        "fnRowCallback": function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
          if (aData[12]) {
            // $('td', nRow).css('background-color', '#68b368');
          }
        },

        drawCallback: function (settings) {

          that.storeLoad.setVisible(false)
          if(data) {
            let table = $("#detailedInfoDt")
            Array.from(table[0].rows).forEach((row, i) => {
              if (i > 0 && data[i-1]) {
                let cells = table[0].rows[i].cells
                cells[0].setAttribute('row-id', data[i - 1].id)
                // cells[0].setAttribute('original-value', data[i - 1].sku_id)
                // cells[3].setAttribute('selected-value', data[i - 1].unit_id)
                // cells[6].setAttribute('selected-value', data[i - 1].currency_id)
              }
            })
          }

          that.initButtons(!data)
        },
        select: true,
        dom: "Blfrtip",
        buttons: [
          "copy",
          "csv",
          "print",
          "excel",
          {
            text: "Add Row",
            action: function (e, dt, node, config) {
              that.addDocumentDetails()
            },
          },
        ],
      })

      this.detailedInfoTable.on('click', 'tbody tr', function (e) {
        if(that.rowsApproved){
          e.currentTarget.classList.toggle('selected');
          that.$emit('rowsSelected',$('#detailedInfoDt tbody tr.selected'))
        }
      })

      eventBus.$on('detailsApproved', ()=>{
        this.rowsApproved = true
      })
    },

    addDocumentDetails(){
      this.$emit('change-mode',{mode: 'detailed-info-form', element: '-1'})
    },

    initButtons(mode) {
      let that = this
      let allButtons = document.getElementsByClassName("icon-btn-details");

      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          let action = element.value

          switch(action){
            case 'delete':
              that.deleteDocumentDetail(event)
              break
            case 'classification':
              that.showRowClassification(event)
              break
            case 'edit':
              let cells = Array.from(event.target.closest("tr").cells)
              that.editDocumentDetail(cells[0].innerHTML)
              break
            default:
              break
          }
        });
      });

      if(allButtons.length === 1 && mode){
        allButtons[0].click()
      }
    },

    showRowClassification(event){
      let rowId = event.target.parentNode.getAttribute('row-id')
      this.$emit("edit-classification", rowId.toString())
    },

    editDocumentDetail(skuId){
      this.$emit('change-mode',{mode: 'detailed-info-form', element: skuId})
    },

    deleteDocumentDetail(event){
      let that = this
      Swal.fire({
        title : 'Delete document detailed info row',
        html: 'Are you sure you want do delete the row ?' ,
        icon: 'question',
        showCancelButton: true
      }).then(result => {
        if(result.isConfirmed){
          let skuId = event.target.closest("tr").cells[0].innerText

          that.storeLoad.setVisible(true)
          docsDal.deleteDocumentDetailedInfo(that.selectedFileId , skuId)
              .then(data => {
                that.storeLoad.setVisible(false)
                if(data.result === 'SUCCESS'){
                  Swal.fire('Delete document detailed info row' , 'Deleted successfully', 'success' )
                      .then( () => {
                        that.storeLoad.setVisible(true)
                        docsDal.getDocumentDetailsInfo(that.selectedFileId)
                            .then(data => {
                              that.detailedInfoTable.destroy();
                              that.createDetailedInfoDt(data.data.length > 0 ? data.data : null)
                            })
                      })
                }else{
                  Swal.fire('Delete document detailed info row' , "Can't delete the row . Please contact your system administrator", 'error')
                }
              })
        }
      })
    },

    getDataRows(){
      return this.dataRows
    }


  }
}
</script>

<style scoped>

</style>