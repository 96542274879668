import {ApiDal} from './api_dal'
class CustomerDal extends ApiDal{
    async getCustomerEntities(customerId , accountId){
        return this.callApi('post', '/api/customers/getCustomerEntities', 'entities', {customerId , accountId})
    }

    async connectCustomerToAccount(customerId , accountId ){
        return this.callApi('post', '/api/customers/connectCustomerToAccount', 'entities', {customerId , accountId})
    }

    async connectCustomerToEntity(customerId , entityId  ){
        return this.callApi('post', '/api/customers/connectCustomerToEntity', 'entities', {customerId , entityId})
    }

    async connectCustomersToEntity(customerId , accountId , connectedEntities){
        return this.callApi('post', '/api/customers/connectCustomersToEntity', null, {customerId , accountId , connectedEntities})
    }
}

export default new CustomerDal()