<script>
export default {
  name: 'EmptyPage'
}

</script>

<template>
  <div class="docz-component">
    <img src="@/assets/images/mainImage.png" alt="main image" style="height: auto; width: unset">
  </div>
</template>

<style scoped>

</style>