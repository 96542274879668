<template>
<div class="form-div">

  <h2 class="param-title" v-if="selectedNode">{{selectedNode ? selectedNode.data.text : ''}}</h2>
  <b-form-checkbox v-for="cb in params" :key="cb.name" :id="cb.name" :checked="cb.is_checked || cb.value === 'true'" v-if="cb.type === 'checkbox'" class="param-item"  @change="cbChanged(cb.name)">{{cb.text}}</b-form-checkbox>

  <div v-for="sel in params"  v-if="sel.type === 'select'" class="param-item">
    <label :for="sel.name">{{sel.text}}</label>
    <b-form-select :key="sel.name" :id="sel.name" :options="sel.options" class="param-select" v-model="sel.value" @change="selectChanged(sel.name)"></b-form-select>
  </div>

  <div v-if="selectedNode && selectedNode.children.length > 0">
<!--    <b-form-checkbox :checked="true" class="param-item">Is Available</b-form-checkbox>-->
    <ul>
      <li v-for="(child,index) in selectedNode.children"> <b-link @click="subMenuClicked(index)">{{child.text}}</b-link></li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: "ProfileParams",
  data() {
    return{
    }
  },
  methods:{
    cbChanged(name ){
      let cb = document.getElementById(name)
      this.selectedNode.data.params.forEach(param => {
         param.is_checked = param.name === name ? cb.checked : param.is_checked
      })
    },
    selectChanged(name){
      let sel = document.getElementById(name)
      this.selectedNode.data.params.forEach(param => {
        param.value =  param.name === name ? sel.value : param.value
      })
    },
    subMenuClicked(index){
      this.$emit('subMenuClicked', index)
    }
  },
  mounted() {
  },
  props: {
    params: Array,
    selectedNode: Object
  }
}
</script>


<style scoped>
  .form-div{
    text-align: left;
  }


  .param-item{
    margin-bottom: 20px;
  }

  .param-select{
    width: 50%;
    margin-left: 10px;
  }

  .param-title{
    border-bottom: 1px solid grey;
    margin-bottom: 40px;
  }
</style>