import { render, staticRenderFns } from "./ProcurementOverview.vue?vue&type=template&id=855af1a4&scoped=true&"
import script from "./ProcurementOverview.vue?vue&type=script&lang=js&"
export * from "./ProcurementOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "855af1a4",
  null
  
)

export default component.exports