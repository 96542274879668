import {ApiDal} from './api_dal'

class GlDal extends ApiDal{
    async getGlAccounts(entityId){
        return this.callApi('post', '/api/gl/getGlAccounts', null, {entityId})
    }

    async getGlAccountsV2(entityId){
        return this.callApi('post', '/api/gl/getGlAccountsV2', null, {entityId})
    }

    async getGlAccountById( glAccountId){
        return this.callApi('post', '/api/gl/getGlAccountById', null, { glAccountId})
    }

    async getGlAccountsConfigData(entityId){
        return this.callApi('post', '/api/gl/getGlAccountsConfigData', null, {entityId})
    }

    async getGlAccountsConfigDataV2(entityId){
        return this.callApi('post', '/api/gl/getGlAccountsConfigDataV2', null, {entityId})
    }

    async getEconomyCategories(reportId){
        return this.callApi('post', '/api/gl/getEconomyCategories', null, {reportId})
    }

    async updateGlAccountDescription(glAccountId, glAccountDescription){
        return this.callApi('post', '/api/gl/updateGlAccountDescription', null, {glAccountId, glAccountDescription})
    }

    async addUserDefinedGlAccount(entityId, glUserLevel1CategoryId, glUserLevel2CategoryId, glUserLevel3CategoryId, glAccountName, glAccountDescription, userId, economicCategoryId, cashFlowBucketId, directCashFlowBucketId){
        return this.callApi('post', '/api/gl/addUserDefinedGlAccount', null, {entityId, glUserLevel1CategoryId, glUserLevel2CategoryId, glUserLevel3CategoryId, glAccountName, glAccountDescription, userId, economicCategoryId, cashFlowBucketId, directCashFlowBucketId})
    }

    async updateUserDefinedGlAccount(entityId, glUserLevel1CategoryId, glUserLevel2CategoryId, glUserLevel3CategoryId, glAccountName, glAccountDescription, userId, economicCategoryId, cashFlowBucketId, directCashFlowBucketId, id){
        return this.callApi('post', '/api/gl/updateUserDefinedGlAccount', null, {entityId, glUserLevel1CategoryId, glUserLevel2CategoryId, glUserLevel3CategoryId, glAccountName, glAccountDescription, userId, economicCategoryId, cashFlowBucketId, directCashFlowBucketId, id})
    }

    async updateUserDefinedGlAccountV2(id , userId, entityId, glAccountTypeId,statusId, glErpAccountTypeId, glAccountName,glEbitdaTypeId,glCashFlowTypeId, glUserSubGroup1TypeId, glUserSubGroup2TypeId){
        return this.callApi('post', '/api/gl/updateUserDefinedGlAccountV2', null, {id , userId, entityId, glAccountTypeId,statusId, glErpAccountTypeId, glAccountName,glEbitdaTypeId,glCashFlowTypeId, glUserSubGroup1TypeId, glUserSubGroup2TypeId})
    }

    async addUserDefinedGlAccountV2( userId, entityId, glAccountTypeId, statusId, glErpAccountTypeId, glAccountName, glEbitdaTypeId, glCashFlowTypeId, glUserSubGroup1TypeId, glUserSubGroup2TypeId){
        return this.callApi('post', '/api/gl/addUserDefinedGlAccountV2', null, { userId, entityId, glAccountTypeId,statusId, glErpAccountTypeId, glAccountName,glEbitdaTypeId,glCashFlowTypeId, glUserSubGroup1TypeId, glUserSubGroup2TypeId})
    }

    async updateGlAccountStatus(glAccountId, glAccountStatus){
        return this.callApi('post', '/api/gl/updateGlAccountStatus', null, {glAccountId, glAccountStatus})
    }

    async getGlCashflowBuckets(){
        return this.callApi('post', '/api/gl/getGlCashflowBuckets', null, {})
    }

    async getGlUserDefinedSubGroups(entityId){
        return this.callApi('post', '/api/gl/getGlUserDefinedSubGroups', null, {entityId})
    }

    async addUserDefinedGroup(entityId, subGroupName, defaultTypeId, groupId){
        return this.callApi('post', '/api/gl/addUserDefinedGroup', null, {entityId, subGroupName, defaultTypeId, groupId})
    }

    async updateUserDefinedGroup(entityId, subGroupName, subGroupId, groupId){
        return this.callApi('post', '/api/gl/updateUserDefinedGroup', null, {entityId, subGroupName, subGroupId, groupId})
    }

    async deleteUserDefinedGroup(subGroupId){
        return this.callApi('post', '/api/gl/deleteUserDefinedGroup', null, {subGroupId})
    }
}

export default new GlDal()