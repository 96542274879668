<template>
  <div>
    <h2 v-if="screenMode === 'all'">EMPLOYEES</h2>
    <div class="container"  >
      <button @click="goBack()" v-if="screenMode === 'all'">
        <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
      </button>
      <div class="datatable" v-if="screenMode === 'all'"></div>
      <new-employee :entity-id="entityId" :selected-employee="selectedEmployee" v-if="screenMode === 'new'" @showEmployees="showEmployees"></new-employee>
    </div>
<!--    <iframe title="Report Section" width="90%" height="800" src="https://app.powerbi.com/view?r=eyJrIjoiNzk2ZjEwNTYtM2ZlNC00YWI2LWFjNGEtZjdmN2Q3MzVhMmM0IiwidCI6ImM5NjlmN2I5LWRlZDEtNGQ2OS1hM2ZkLWM2NjZjMDZiNDkzZSIsImMiOjl9" frameborder="0" allowFullScreen="true"></iframe>-->

      </div>

</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import EmployeesDal from "@/dal/employees_dal"
import NewEmployee from "@/components/employees/NewEmployee.vue"
import {useLoadStore} from "@/stores/load"
import {useAuthStore} from "@/stores/auth"
import $ from "jquery";

library.add(faUser, faEnvelope, faPhone);

export default {
  name: 'Employees',
  props:{
    entityId: Number
  },
  components: {
    FontAwesomeIcon,
    NewEmployee
  },
  data() {
    return {
      storeLoad: useLoadStore(),
      authStore : useAuthStore(),
      allExistingEmployees: [],
      selectedEmployee: null,
      screenMode: 'all',
      employee: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
    };
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getEmployees(0)
  },
  methods: {
    getEmployees(mode){
      EmployeesDal.getEmployeesList(this.entityId)
          .then(data => {
            this.createEmployeesGrid(data)
          })
    },
    createEmployeesGrid(data){
      this.allExistingEmployees = data.data
      let dataColumns = []
      let dataRows = []
      data.columnList.forEach((title) => {
        dataColumns.push({
          title: title
              .split("_")
              .join(" ")
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                  letter.toUpperCase()
              ),
        });
      });
      dataColumns.push({ title: "" }); // add column for buttons
      data.data.forEach((row) => {
        let dataRow = [];
        data.columnList.forEach((value) => {
          dataRow.push(row[value]);
        });
        dataRows.push(dataRow);
      });
      let that = this
      $(".datatable").html("")
      $(".datatable").append('<table id="datatable" class="table table-hover table-striped non-editable"></table>')

      $("#datatable").DataTable({
        data: dataRows,
        columns: dataColumns,
        pageLength: 10,
        columnDefs: [
          {
            targets: -1,
            width: "100px",
            render: function (data, type, row) {
              return `<button class="icon-btn grid-btn" value="edit"><i class='fa fa-edit style='font-size: 16px; color: #334249'></i></button>
                          <button class="icon-btn grid-btn" value="delete"><i class='fa fa-trash style='font-size: 16px; color: #334249'></i></button>  `;
            },
          },
          {
            targets: [1],
            visible: false,
            searchable: false,
          }
        ],
        drawCallback: function (settings) {
          that.initButtons()
        },
        dom: "Blfrtip",
        buttons: [
          "copy",
          "csv",
          "print",
          "excel",
          {
            text: "New Employee",
            action: function (e, dt, node, config) {
              that.proceedAction("add", 0);
            },
          },
        ],
        bDestroy: true,
      })

      this.storeLoad.setVisible(false)
    },

    proceedAction(action, employeeId){
      switch(action){
        case 'add':
          this.selectedEmployee = null
          this.screenMode = 'new'
          break
        case 'edit':
          this.selectedEmployee = this.allExistingEmployees.find(employee => employee.id == employeeId)
          this.screenMode = 'new'
          break
        case 'delete':
          break
        default:
          break
      }
      if(action === 'add'){

      }

    },

    initButtons() {
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText
          );
        });
      });
    },

    submitForm() {
      // Handle form submission logic here
    },
    showEmployees(){
      this.screenMode = 'all'
      this.storeLoad.setVisible(true)
      this.getEmployees(0)
    },
    goBack(){
      this.$emit( "goToBookKeeperMain");
    }
  },
};
</script>

<style>
.onboarding-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-group font-awesome-icon {
  margin-right: 10px;
}

input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
</style>
