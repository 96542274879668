<template>
  <div class="DocumentsWizard">
    <figure class="DocumentsWizard-layout">
      <div class="document-info">

        <document-details v-if="fileUploaded" ref="documentDetails" @showClassification="nextStep" @allDataApproved="showEmptyClassification = true" :selected-file-id="selectedFileId"></document-details>
      </div>
      <div class="document-classification">
        <section class="part-section right-part-section">
          <figure class="part-title" v-if="fileUploaded">
            <h4>Lines Classification Wizard</h4>
            <p>Invoice</p>
          </figure>

          <div class="documentContainer right-part-section" id="documentContainer">
            <div class="background-div right-part-section">

              <iframe style="width:100%; height: 100%" ref="uploadedPdf" v-if="currentStep === 1 && !showEmptyClassification"></iframe>
              <classification-wizard v-if="currentStep === 2" @classification-completed="refreshDocumentDetails"></classification-wizard>
              <documents-empty-class v-if="showEmptyClassification"></documents-empty-class>
            </div>
            <div class="overlay-div" v-if="currentStep === 1 && !showEmptyClassification">
              <canvas id="myCanvas"></canvas>
              <div class="tooltip" id="tooltip">Tooltip Text</div>
            </div>
          </div>
        </section>
      </div>
    </figure>
  </div>
</template>

<script>
import {useClassificationStore} from "@/stores/classification"
import DocumentDetails from "@/components/docs/DocumentDetails.vue"
import ClassificationWizard from "@/components/docs/ClassificationWizard.vue"
import DocumentsEmptyClass from "@/components/docs/DocumentsEmptyClass.vue"

export default {
  name: "DocumentsWizard",
  components: {
    DocumentDetails,
    ClassificationWizard,
    // Breadcrumbs,
    DocumentsEmptyClass
  },
  props: {
    pageName: String,
    subPageName: String,
    uploadedDataBlob: Blob,
    selectedFileId: String
  },
  data(){
    return {
      fileUploaded: true,
      showEmptyClassification: false,
      currentStep: 1,
      maxSteps : 2,
      stepName: 'Document Details',
      pdfData: '',
      fileData: null,
      rect: {},
      recognizedAtoms: [
        {id: 0 , color: 'blue', rect: {x:730, y:165 , width:150, height:30}},
        {id: 3 , color: 'blue', rect: {x:560, y:205 , width:250, height:30}},
       // {id: 7 , color: 'blue', rect: {x:460, y:265 , width:250, height:30}}
        {id: 7 , color: 'blue', rect: {x:560, y:305 , width:250, height:30}}
      ],
      unRecognizedAtoms: [
        {id: [5,6,9] , color: 'green', rect: {x:560, y:255 , width:280, height:30},text: 'January 25, 2016'},
      ],
      breadCrumbsData:[this.pageName, this.subPageName],
      classificationStore: useClassificationStore()
    }
  },
  mounted() {

    this.classificationStore.resetClassificationData()
    if(this.uploadedDataBlob){
      const url = URL.createObjectURL( this.uploadedDataBlob );
      console.log(url)
      this.$refs.uploadedPdf.src = url

    }
  },
  methods: {

    refreshDocumentDetails(){
      this.$refs.documentDetails.refreshDocumentDetails()
      this.currentStep = 1
    },

    /*
    loadDocument(){
      const file = event.target.files[0]
      this.fileName = file.name
      const reader = new FileReader();
      let that = this
      reader.onload = () => {
        that.pdfData = reader.result;
      };
      reader.readAsDataURL(file);
      this.fileData = new FormData();
      this.fileData.append('uploadedFile', file)
      this.fileUploaded = true
    },

    documentLoaded(){
      let myCanvas = document.querySelector('#myCanvas');
      myCanvas.width =document.getElementsByClassName('background-div')[0].clientWidth
      myCanvas.height = document.getElementsByClassName('background-div')[0].clientHeight
      this.drawCanvas()
    },

    drawCanvas(){
      const canvas = document.getElementById('myCanvas');
      this.rect = {x:460, y:245 , width:250, height:30}

      let atoms = [...new Set([...this.recognizedAtoms, ...this.unRecognizedAtoms])]
          atoms
          .forEach(atom => {
            //this.drawRectangle(atom.color, 1 , atom.rect)
          })

      canvas.addEventListener('click', (event) => {
        let myCanvas = document.querySelector('#myCanvas');
        let context = myCanvas.getContext('2d');
        context.clearRect(0, 0, myCanvas.width, myCanvas.height);

        let mouseX = event.clientX  - canvas.getBoundingClientRect().left;
        let mouseY = event.clientY - canvas.getBoundingClientRect().top;

        let atomClicked = false

        this.recognizedAtoms
            .forEach(atom => {
          if ( mouseX >= atom.rect.x && mouseX <= atom.rect.x + atom.rect.width && mouseY >= atom.rect.y && mouseY <= atom.rect.y + atom.rect.height ){
            this.drawRectangle('red', 2,  atom.rect)
            this.$refs.documentDetails.unLightAllAtoms()
            this.$refs.documentDetails.highLightAtom(atom.id)
            atomClicked = true
          }else{
            this.drawRectangle(atom.color, 1, atom.rect)
            if(!atomClicked){
              this.$refs.documentDetails.unLightAllAtoms()
            }
          }
        })

        this.unRecognizedAtoms.forEach(atom => {
          if ( mouseX >= atom.rect.x && mouseX <= atom.rect.x + atom.rect.width && mouseY >= atom.rect.y && mouseY <= atom.rect.y + atom.rect.height ){
            this.drawRectangle('red', 2,  atom.rect)
            this.$refs.documentDetails.blinkAtom(atom.id, atom.text)
          }
          this.drawRectangle(atom.color, 1, atom.rect)
        })
      });
    },

    drawRectangle(color,  lineWidth, rect){
      let myCanvas = document.querySelector('#myCanvas');
      let context = myCanvas.getContext('2d');

      context.beginPath();
      context.rect(rect.x, rect.y, rect.width, rect.height);
      context.strokeStyle = color;
      context.lineWidth = lineWidth;
      context.stroke();
    },

    prevStep(){
      if(this.currentStep>1){
        this.currentStep--
      }
      this.setStepName()
    },

    setStepName(){
      switch(this.currentStep){
        case 1:
          this.stepName = 'Document Details'
              break;
        case 2:
          this.stepName = 'Document Details'
              break;
        case 3:
          this.stepName = 'Lines Classification'
              break;
      }
    },
*/

    goBack(){
      this.$emit('show-all')
    },


    nextStep(){
      if(this.currentStep < this.maxSteps){
        this.currentStep++
      }
      this.showEmptyClassification = false
    },

  }
}
</script>

<style scoped>
.right-part-section{
  height: 100%;
}

</style>