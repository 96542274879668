import { defineStore } from "pinia"

export const useClassificationStore= defineStore({
    id: 'classificationStore',
    state: () => ({
        classificationData: [],
        selectedRowsIds: [],
        lineType: 0
    }),

    getters: {
        getClassificationData: (state) => {return {lineType : state.lineType, classificationData: state.classificationData}},
        getSelectedRowIds: (state) => state.selectedRowsIds
    },
    actions: {
        addClassificationData(data){

            let existingComponent = this.classificationData.find(item=> item.tabIndex == data.tabIndex && item.componentId == data.componentId)
            if(existingComponent){
                existingComponent.componentConfig = data.componentConfig
                existingComponent.componentId = data.componentId
                existingComponent.componentType = data.componentType
                existingComponent.originalComponent = data.originalComponent
            }else{
                this.classificationData.push(data)
            }
        },

        setLineType(lineType){
          this.lineType = lineType
        },

        setSelectedRowsIds(rowIds){
          this.selectedRowsIds = rowIds
        },

        resetClassificationData(){
            this.classificationData = []
            //this.selectedRowsIds = []
        },

        clearClassificationData(tabId){
            this.classificationData = this.classificationData.filter(item => !(item.tabId > tabId))
        }
    }
})