<template>

<section class="ClassificationWizard-section cardSection">
  <div>
    <line-types @line-type-selected="lineTypeSelected" :existing-line-type="existingLineType" v-if="showLineTypes"></line-types>
    <wizard-tabs ref="wizardTab" v-if="selectedLineType!=null"  @wizard-tab-selected="wizardTabSelected" @component-value-changed="componentValueChanged" :existing-classification-data="existingClassificationData"></wizard-tabs>
  </div>
</section>
</template>

<script>

import wizardDal from "@/dal/wizard_dal";
import {useLoadStore} from "@/stores/load"

import LineTypes from "@/components/docs/WizardElements/LineTypes.vue"
import WizardTabs from "@/components/docs/WizardElements/WizardTabs.vue"

import {useWizardStore} from "@/stores/wizard"
import {useClassificationStore} from "@/stores/classification"
import docsDal from "@/dal/docs-dal";
import Swal from "sweetalert2";

export default {
  name: 'ClassificationWizard',
  components: {
    LineTypes,
    WizardTabs
  },
  data(){
    return {
      existingLineType: '',
      existingClassificationData: [],
      selectedLineType: null,
      selectedTab: null,
      wizardStore: useWizardStore(),
      storeLoad: useLoadStore(),
      classificationStore: useClassificationStore(),
      currentComponentValue: null,
      showLineTypes: false
    }
  },
  mounted() {
    let that = this
    this.storeLoad.setVisible(true)
    let selectedRowIds = this.classificationStore.getSelectedRowIds

    docsDal.getDocumentClassification(selectedRowIds[0])
        .then(data=> {
          this.wizardStore.initStore()
          this.storeLoad.setVisible(false)
          if(data.data.length == 1){
            this.existingClassificationData = data.data[0].classification.classificationData
            that.existingLineType = data.data[0].classification.lineType
          }
          this.showLineTypes= true
        })

  },
  methods: {
    lineTypeSelected(args){
      this.selectedLineType = args.id
      if(this.existingClassificationData.length === 0){
        this.currentComponentValue = args.name
        this.wizardStore.setLineType(this.selectedLineType)
        if(this.$refs.wizardTab){
          this.wizardStore.setNextComponentId(1)
          this.$refs.wizardTab.resetTabs()
          this.$refs.wizardTab.goToTab(2)
        }
      }
      // else{
      //   this.$refs.wizardTab.populateTabs()
      // }
      this.storeLoad.setVisible(false)
    },

    wizardTabSelected(args){
      this.selectedTab = args.tabId

      this.wizardStore.setTabId(args.tabId)
      if(args.hasChildren){
        this.componentSelected(this.wizardStore.getWizardState.componentId)
      }
    },

    componentSelected(nextComponentId, nextButton){
      wizardDal.GetWizardComponent(this.wizardStore.getWizardState.documentType , nextComponentId, this.currentComponentValue||'')
          .then(data => {
            if(nextButton){
              nextButton.disabled = false
            }
            if(data.data.length > 0 ){
              if( this.selectedTab !== parseInt(data.data[0].component_tab_id)){
                this.$refs.wizardTab.goToTab(data.data[0].component_tab_id)
              }else{
                this.$refs.wizardTab.addComponentToTab(data.data[0], nextComponentId)
              }
              this.selectedTab = parseInt(data.data[0].component_tab_id)
            }
          })
    },

    componentValueChanged(args){
      let that = this
      if(args.nextButton){
        args.nextButton.disabled = true
      }
      this.currentComponentValue = args.componentValue
      this.wizardStore.setNextComponentId(args.nextComponentId)
      this.componentSelected(args.nextComponentId, args.nextButton)
      if(args.isLastTab){

        Swal.fire({
          title: 'Classification Completed',
          html: 'Please approve to save the data',
          icon: 'question',
          showCancelButton: true
        }).then(result => {
          if(result.isConfirmed){
            that.storeLoad.setVisible(true)
            docsDal.addDocumentClassification(that.classificationStore.getSelectedRowIds.join('#'), that.classificationStore.getClassificationData)
                .then(data => {
                  that.storeLoad.setVisible(false)
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Classification', 'Classification data saved successfully !', 'success')
                        .then(()=> {
                          that.$emit('classification-completed')
                        })
                  }
                })
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>