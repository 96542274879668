import { defineStore } from "pinia"

export const useEntityStore = defineStore({
    id: 'entityStore',
    state: () => ({
        entityId: null
    }),
    getters:{
        getCurrentEntity: (state) => state.entityId
    },
    actions:{
        setEntityId(entityId){
            this.entityId = entityId
        }
    }
})