<template>
  <div>
    <div class="container" >
      <button @click="goBack()" v-if="showEntities">
        <font-awesome-icon :icon="['fas', 'arrow-up-from-bracket']" />Back
      </button>

      <b-card-group fluid v-if="showEntities">
        <b-card  v-for="entity in entities" :key="entity.id"
                 :header=entity.entity_type
                 header-tag="header"
                 :footer = entity.entity_name_en
                 footer-tag="footer"
                 :title=entity.entity_id
        >
          <b-card-text>{{entity.entity_name}}</b-card-text>
          <b-button  @click="openEntity(entity.id)" variant="primary">{{entity.entity_id}}</b-button>
        </b-card>
      </b-card-group>

      <book-keepers-main v-if="!showEntities" @backToEntities="showEntities = true" :selected-entity="selectedEntity"></book-keepers-main>

    </div>
  </div>
</template>

<script>
import BookKeeperDal from "@/dal/bookkeeper_dal"
import { useAuthStore } from "@/stores/auth"
import { useLoadStore } from "@/stores/load"
import BookKeepersMain from "@/components/bookkeepers/BookKeepersMain.vue"

export default {
  name: "BookKeepers",

  components: {
    BookKeepersMain
  },

  data(){
    return {
      autStore: useAuthStore(),
      storeLoad: useLoadStore(),
      showEntities: true,
      entities: [],
      selectedEntity: null
    }
  },

  mounted() {
    this.storeLoad.setVisible(true)
    BookKeeperDal.getBookKeeperEntities(this.autStore.getUserData.userId)
        .then(data => {
          this.storeLoad.setVisible(false)
          this.entities = data.entities.data
        })
  },

  methods: {
    openEntity(entityId){
      this.showEntities = false
      this.selectedEntity = this.entities.find(entity => entity.id === entityId)
    },
    goBack(){
      this.$emit("goBack");
    }
  }
}

</script>

<style scoped>

</style>