<template>
  <div class="docz">
    <div v-if="screenMode=== 'all-docs'" class="all-docs-section">
      <div class="table-area">
        <table id="datatable" class="table table-hover non-editable"></table>
      </div>
    </div>

    <div class="documents-wizard">
      <documents-wizard v-if="screenMode==='one-doc'" @show-all="showAll" :page-name="pageName" :sub-page-name="subPageName" :uploaded-data-blob="uploadedDataBlob" :selected-file-id="selectedFileId"></documents-wizard>
    </div>

  </div>

</template>

<script>
import "jquery/dist/jquery.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Swal from "sweetalert2";
import $ from "jquery";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons-dt";

import DocsDal from '@/dal/docs-dal'
import { useEntityStore} from "@/stores/entity"
import DocumentsWizard from "@/components/docs/DocumentsWizard.vue"
import DocumentUpload from "@/components/docs/DocumentUpload.vue"
import {useLoadStore} from "@/stores/load"
import Vue from "vue";

export default {
  name: 'ProcurementDataOnboarding',
  components:{
    DocumentsWizard
  },
  props:{
    pageName: String,
    subPageName: String
  },
  data(){
    return {
      storeLoad: useLoadStore(),
      allDocsData: null,
      screenMode: 'all-docs',
      entityStore: useEntityStore(),
      uploadedDataBlob : null,
      selectedFileId: null
    }
  },
  mounted() {

    this.storeLoad.setVisible(true);
    this.showAllDocs()
  },
  methods:{
    showAllDocs(){
      DocsDal.getEntityDocuments()//.getUserDocs()
          .then((data)=>{
            this.allDocsData = data.docs.data

            let dataColumns = [];
            let dataRows = [];
            data.docs.data.fields.forEach((field) => {
              dataColumns.push({
                title: field.name
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            });
            dataColumns.push({ title: "" }); // add column for buttons

            data.docs.data.rows.forEach((row) => {
              let dataRow = [];
              data.docs.data.fields.forEach((field) => {
                dataRow.push(row[field.name]);
              });
              dataRows.push(dataRow);
            });

            let that = this;

            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 10,
              aaSorting: [],
              columnDefs: [
                {
                  targets: -1,
                  width: "120px",
                  render: function (data, type, row) {
                    return `<button class="icon-btn grid-btn" value="edit"><i class='fa fa-edit' style='font-size: 16px; color: #334249' value="edit"></i></button>
                            <button class="icon-btn grid-btn" value="conf"><i class='fa fa-cog' style='font-size: 16px; color: #334249' value="cog"></i></button>
                            <button class="icon-btn grid-btn" value="preview"><i class='fa fa-eye' style='font-size: 16px; color: #334249' value="preview"></i></button>
                          <button class="icon-btn grid-btn" value="download"><i class='fa fa-download' style='font-size: 16px; color: #334249'  value="download"></i></button> `;
                  },
                },
                {
                  targets: 1,
                  render: function (data, type, row) {
                    return row[1] =='1' ?
                        `<p><i class='fa fa-clock' style='font-size: 20px; color: #f6931e'></i><p>`:
                        `<p><i class='fa fa-check-circle' style='font-size: 20px; color: #2ba325'></i><p>`
                  }
                },
              ],
              drawCallback: function (settings) {
                  that.initButtons();
              },
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "New Document",
                  action: function (e, dt, node, config) {
                    that.showData("add", 0);
                  },
                },
              ],
              bDestroy: true,
            });
          })

    },

    initButtons() {
      let that = this;

      function clickListener(event) {
        that.showData(
            event.target.parentNode.value||event.target.getAttribute('value'),
            event.target.closest("tr").cells[0].innerText
        )
      }

      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        let hasEventListener = false;                   // Check if the element has the event listener already attached
        let eventListeners = element._listeners || {};  // Get the list of event listeners for the element
        if (eventListeners['click']) {                  // Check if the desired event type is already in the list
          hasEventListener = true;
        }

        if(!hasEventListener){
          element.addEventListener("click", clickListener);
          element._listeners = element._listeners || {}
          element._listeners['click'] = clickListener
        }
      });

      this.storeLoad.setVisible(false);
  },

    showData(actionType, fileId) {
      switch (actionType){
        case 'add':
          this.addFile()
          break;
        case 'preview':
          this.previewFile(fileId)
          break
        case 'edit':
          this.editFile(fileId)
          break;
        case 'download':
          this.downloadFile(fileId)
          break;
        case "conf":
          this.onboardFile(fileId)
          break
        default:
          break
      }
    },

    addFile(){
      let that = this
      Swal.fire({
        title: 'Upload new document',
        html: `<div class="uploadModalContent"></div>`,
        width:600,
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          let uploadModalContent = document.getElementsByClassName('uploadModalContent')[0]
          let entityId = this.entityStore.getCurrentEntity
          let fileName = uploadModalContent.getAttribute('fileName')
          let fileData = uploadModalContent.getAttribute('fileData')
          DocsDal.uploadEntityDocument(entityId , fileName, fileData)
              .then(data => {
                if(data.result === 'SUCCESS'){
                  Swal.fire('Upload File' , 'File uploaded successfully', 'success')
                      .then(() => {
                        that.showAllDocs()
                      })
                }else{
                  Swal.fire('Upload File', 'Failed to upload new file ', 'error')
                }
              })
        }
      })

      let documentUpload = Vue.extend(
          Vue.component('DocumentUpload', DocumentUpload)
      )

      let documentUploadInstance = new documentUpload()
      documentUploadInstance.$mount()
      documentUploadInstance.$on('file-uploaded', (args)=> {
        document.getElementsByClassName('uploadModalContent')[0].setAttribute('fileName', args.fileName)
        document.getElementsByClassName('uploadModalContent')[0].setAttribute('fileData', args.fileData)
      })
      document.getElementsByClassName('uploadModalContent')[0].appendChild(documentUploadInstance.$el)
    },

    previewFile(fileId){
      this.storeLoad.setVisible(true);
      DocsDal.getEntityDocument(fileId)
          .then(data => {
            const blob = this.base64ToBlob( data.data.docContent, 'application/pdf' );

            if(blob){
              const url = URL.createObjectURL( blob );
              const pdfWindow = window.open("");
              pdfWindow.document.write('<html><head></head><body style="margin: 0px; "></body>');
              pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
            }

            this.storeLoad.setVisible(false);
          })
    },

    base64ToBlob( base64, type = "application/octet-stream" ) {
      try{
        const binStr = window.atob( base64 );
        const len = binStr.length;
        const arr = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          arr[ i ] = binStr.charCodeAt( i );
        }
        return new Blob( [ arr ], { type: type } );
      }catch (e) {
        return null
      }

    },

    onboardFile(fileId){
      this.storeLoad.setVisible(true)
      // let documentName = this.allDocsData.rows.find(file => file.id == fileId).source_email_attachment_name

      this.$emit('addBreadCrumb', 'Document approval')
      DocsDal.getEntityDocument(fileId)
          .then(data => {
            this.uploadedDataBlob = this.base64ToBlob( data.data.docContent, 'application/pdf' );
            this.selectedFileId = fileId
            this.screenMode = 'one-doc'
            this.storeLoad.setVisible(false)

          })
    },

    editFile(fileId){
      let that = this
      let documentStatus = this.allDocsData.rows.find(file => file.id == fileId).status
      Swal.fire({
        title: 'Update File',
        width: '500px',
        html: `<select style="width:80%" class="swal2-input" id="document-status">
                  <option value='1' ${documentStatus == 1 ? 'selected' : ''}>Open</option>
                  <option value='2' ${documentStatus == 2 ? 'selected' : ''}>Done</option>
                </select>`,
        showCancelButton: true,
        confirmButtonText: 'Update',
        preConfirm: () => {
          const documentStatus = Swal.getPopup().querySelector('#document-status').value
          return { documentStatus, documentId: fileId }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          DocsDal.updateEntityDocument(result.value.documentStatus, result.value.documentId)
              .then(data => {
                if(data.result === 'SUCCESS'){
                  Swal.fire('Edit File' , 'File updated successfully', 'success')
                      .then(() => {
                        that.showAllDocs()
                      })
                }else{
                  Swal.fire('Upload File', 'Failed to edit file ', 'error')
                }
              })
        }
      })
    },

    downloadFile(fileId){
      this.storeLoad.setVisible(true);
      DocsDal.getEntityDocument(fileId)
          .then(data => {
            const linkSource = `data:application/pdf;base64,${data.data.docContent}`;
            const downloadLink = document.createElement("a");
            const fileName = data.data.fileName;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            this.storeLoad.setVisible(false);
          })

      // let file = this.allDocsData.rows.find(file => file.id == fileId)
      // let link=document.createElement("a");
      // link.id = 'downloadFile'; //give it an ID!
      // link.href="http://localhost:8899/sendfile?fileName="+file.file_name;
      //
      // document.getElementById('datatable').append(link)
      // document.getElementById('downloadFile').click();
      // link.remove()
    },

    showAll(){

      this.screenMode = 'all-docs'
      this.showAllDocs()
    }
  }
}
</script>

<style scoped>

</style>