<template>
  <div class="form-div gl-form">
    <b-form style="font-size: 13px" autocomplete="off">
<!--      {{selectedGlAccount}}-->

      <b-form-group v-if="selectedGlAccount.id" id="input-gl-account-ID" :label="'GL Account ID : ' + selectedGlAccount.id" ></b-form-group>
      <b-form-group id="input-gl-account-name" label="GL Account Name :" label-for="gl-account-name" >
        <b-form-input type="text" id="gl-account-name" v-model="selectedGlAccount.gl_account_name" placeholder="Enter Account Name" required :disabled="glType === 'system'"></b-form-input>
      </b-form-group>

      <b-form-group id="input-gl-account-description" label="GL Account Description :" label-for="gl-account-description" >
        <b-form-input type="text" id="gl-account-description" v-model="selectedGlAccount.gl_account_description" placeholder="Enter Account Description" ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-report-types" label="Report Name:" label-for="input-report-types" >
        <input type="text" id="input-report-types" v-model="selectedGlAccount.report_name" list="report-types" :disabled="glType === 'system'" @change="reportNameChanged">
        <datalist id="report-types">
          <option v-for="report in reportTypes" :data-value="report.id" >{{report.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-account-types" label="Account Type:" label-for="input-account-types" >
        <input type="text" id="input-account-types"  v-model="selectedGlAccount.account_type_name" list="account-types" disabled>
        <datalist id="account-types">
          <option v-for="accType in accountTypes" :data-value="accType.id" >{{accType.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-economic-category" label="Economic Category:" label-for="input-economic-category" >
        <input type="text" id="input-economic-category"  v-model="selectedGlAccount.economic_category_name" list="economic-categories" :disabled="glType === 'system' || selectedGlAccount.report_name == null">
        <datalist id="economic-categories">
          <option v-for="category in economicCategories" :data-value="category.id" >{{category.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-system-category" label="System Category:" label-for="input-system-category" >
        <input type="text" id="input-system-category" ref="input-system-category"  v-model="selectedGlAccount.system_category_name" list="system-categories" :disabled="glType === 'system' || selectedGlAccount.report_name == null" @change="systemCategoryNameChanged">
        <datalist id="system-categories">
          <option v-for="category in systemCategories" :data-value="category.id" >{{category.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-level1-category" label="Level 1 Category:" label-for="input-level1-category" >
        <input type="text" id="input-level1-category"  v-model="selectedGlAccount.user_level1_category_name" list="level1-categories" :disabled="glType === 'system' || selectedGlAccount.system_category_name == null" @change="userLevel1CategoryNameChanged">
        <datalist id="level1-categories">
          <option v-for="category in userLevel1Categories" :data-value="category.id" >{{category.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-level2-category" label="Level 2 Category:" label-for="input-level2-category" >
        <input type="text" id="input-level2-category"  v-model="selectedGlAccount.user_level2_category_name" list="level2-categories" :disabled="glType === 'system'" @change="userLevel2CategoryNameChanged">
        <datalist id="level2-categories">
          <option v-for="category in userLevel2Categories" :data-value="category.id" >{{category.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-level3-category" label="Level 3 Category:" label-for="input-level3-category" >
        <input type="text" id="input-level3-category"  v-model="selectedGlAccount.user_level3_category_name" list="level3-categories" :disabled="glType === 'system'">
        <datalist id="level3-categories">
          <option v-for="category in userLevel3Categories" :data-value="category.id" >{{category.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-cashflow-bucket" label="Cash Flow Bucket :" label-for="input-cashflow-bucket" >
        <input type="text" id="input-cashflow-bucket"  v-model="selectedGlAccount.cashflow_bucket_category_name" list="cashflow-buckets" >
        <datalist id="cashflow-buckets">
          <option v-for="cashflowBucket in cashFlowBuckets" :data-value="cashflowBucket.id" >{{cashflowBucket.name}}</option>
        </datalist>
      </b-form-group>

      <b-form-group id="input-group-direct-cashflow-bucket" label="Direct Cash Flow Bucket :" label-for="input-direct-cashflow-bucket" >
        <input type="text" id="input-direct-cashflow-bucket"  v-model="selectedGlAccount.direct_cashflow_bucket_category_name" list="direct-cashflow-buckets" >
        <datalist id="direct-cashflow-buckets">
          <option v-for="directCashflowBucket in directCashFlowBuckets" :data-value="directCashflowBucket.id" >{{directCashflowBucket.name}}</option>
        </datalist>
      </b-form-group>

    </b-form>

    <figure class="part-footer">
      <b-button variant="primary" @click="approveGlForm">{{selectedGlAccount.id ? 'Update' : 'Add'}}</b-button>
    </figure>
  </div>

</template>

<script >
import GlDal from "@/dal/gl_dal"
import { useLoadStore } from "@/stores/load"
import { useAuthStore } from "@/stores/auth"
import { useGlAccountStore } from "@/stores/gl"
import Swal from "sweetalert2"
import Multiselect from "vue-multiselect"

export default {
  name: "glForm",
  components: {
    Multiselect
  },
  props : {
    glAccountsConfigData: Array,
    selectedGlAccount: Object,
    entityId: Number
  },
  data(){
    return {
      glType: 'system',
      storeLoad: useLoadStore(),
      authStore: useAuthStore(),
      glAccountStore: useGlAccountStore(),
      reportTypes: this.removeDuplicates(this.glAccountsConfigData.map(x => {return {id: x.report_id, name: x.report_name}})),
      accountTypes: [],
      economicCategories: [],
      systemCategories: [],
      userLevel1Categories: [],
      userLevel2Categories: [],
      userLevel3Categories: [],
      selectedReportId: null,
      selectedSystemCategoryId: null,
      selectedEconomicCategoryId: null,
      selectedUserLevel1CategoryId: null,
      selectedUserLevel2CategoryId: null,
      selectedUserLevel3CategoryId: null,
      cashFlowBuckets: null,
      directCashFlowBuckets: null,
      selectedReportName: null
    }
  },

  mounted() {

    if(this.selectedGlAccount){
      this.selectedReportId = this.removeDuplicates(this.glAccountsConfigData.filter(x=> x.report_name == this.selectedGlAccount.report_name).map(x => {return x.report_id}))[0]

      if(!this.selectedGlAccount.id || this.selectedGlAccount.is_editable == 1){
        this.glType = 'user-defined'
        this.selectedGlAccount.account_type_name = 'Customer defined'
      }else{
        this.glType = 'system'
      }

      this.systemCategories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x=> x.report_name == this.selectedGlAccount.report_name)
              .map(x => {
                return {id: x.system_category_id, name: x.system_category_name}
              })
      )

      this.userLevel1Categories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x => x.system_category_name == this.selectedGlAccount.system_category_name)
              .map( x => {
                return {id: x.user_level1_category_id, name: x.user_level1_category_name}
              })
      )

      this.userLevel2Categories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x => x.user_level1_category_name == this.selectedGlAccount.user_level1_category_name)
              .map( x => {
                return {id: x.user_level2_category_id, name: x.user_level2_category_name}
              })
      )

      this.userLevel3Categories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x => x.user_level2_category_name == this.selectedGlAccount.user_level2_category_name)
              .map( x => {
                return {id: x.user_level3_category_id, name: x.user_level3_category_name}
              })
      )

      this.storeLoad.setVisible(true)
      GlDal.getEconomyCategories(this.selectedReportId)
          .then(data => {
            this.economicCategories = data.data.map( x => {return {id: x.id , name: x.economic_category_name}})
            GlDal.getGlCashflowBuckets()
                .then(data => {
                  this.storeLoad.setVisible(false)

                  this.cashFlowBuckets = data.data.filter(x => x.bucket_type == 'cashflow_bucket_categories')
                  this.directCashFlowBuckets = data.data.filter(x => x.bucket_type == 'direct_cashflow_bucket_categories')
                })
          })
    }

  },

  methods: {

    reportNameChanged() {
      this.selectedGlAccount.system_category_name = ''
      this.selectedGlAccount.user_level1_category_name = ''
      this.selectedGlAccount.user_level2_category_name = ''
      this.selectedGlAccount.user_level3_category_name = ''

      this.selectedReportId = this.removeDuplicates(this.glAccountsConfigData.filter(x=> x.report_name == this.selectedGlAccount.report_name).map(x => {return x.report_id}))[0]
      this.storeLoad.setVisible(true)

      GlDal.getEconomyCategories(this.selectedReportId)
          .then(data => {
            this.storeLoad.setVisible(false)
            this.economicCategories = data.data.map(x => {
              return {id: x.id, name: x.economic_category_name}
            })
          })

      this.systemCategories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x=> x.report_name == this.selectedGlAccount.report_name)
              .map(x => {
                return {id: x.system_category_id, name: x.system_category_name}
              })
      )
    },

    systemCategoryNameChanged(){
      this.selectedGlAccount.user_level1_category_name = ''
      this.selectedGlAccount.user_level2_category_name = ''
      this.selectedGlAccount.user_level3_category_name = ''

      this.userLevel1Categories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x => x.system_category_name == this.selectedGlAccount.system_category_name)
              .map( x => {
                return {id: x.user_level1_category_id, name: x.user_level1_category_name}
              })
      )
    },

    userLevel1CategoryNameChanged() {
      this.selectedGlAccount.user_level2_category_name = ''
      this.selectedGlAccount.user_level3_category_name = ''

      this.userLevel2Categories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x => x.user_level1_category_name == this.selectedGlAccount.user_level1_category_name)
              .map( x => {
                return {id: x.user_level2_category_id, name: x.user_level2_category_name}
              })
      )
    },

    userLevel2CategoryNameChanged: function(newVal, oldVal) {
      this.selectedGlAccount.user_level3_category_name = ''

      this.userLevel3Categories = this.removeDuplicates(
          this.glAccountsConfigData.filter(x => x.user_level2_category_name == this.selectedGlAccount.user_level2_category_name)
              .map( x => {
                return {id: x.user_level3_category_id, name: x.user_level3_category_name}
              })
      )
    },

    approveGlForm(){
      let validationResult = this.validateUserInput()

      if( validationResult !== ''){
        Swal.fire(`${this.selectedGlAccount.id ? 'Update' : 'Create'} GL Account`, validationResult, 'warning')
      }else{
        let selectedUserLevel1CategoryId = this.userLevel1Categories.filter( x=> x.name == this.selectedGlAccount.user_level1_category_name).map(x => {return x.id})[0]
        let selectedUserLevel2CategoryId = this.userLevel2Categories.filter( x=> x.name == this.selectedGlAccount.user_level2_category_name).map(x => {return x.id})[0]
        let selectedUserLevel3CategoryId = this.userLevel3Categories.filter( x=> x.name == this.selectedGlAccount.user_level3_category_name).map(x => {return x.id})[0]
        let selectedEconomicCategoryId = this.economicCategories.filter( x=> x.name == this.selectedGlAccount.economic_category_name).map(x => {return x.id})[0]
        let cashFlowBucketId = this.cashFlowBuckets.filter( x=> x.name == this.selectedGlAccount.cashflow_bucket_category_name).map(x => {return x.id})[0]
        let directCashFlowBucketId = this.directCashFlowBuckets.filter( x=> x.name == this.selectedGlAccount.direct_cashflow_bucket_category_name).map(x => {return x.id})[0]

        Swal.fire({
          title: `${this.selectedGlAccount.id ? 'Update' : 'Create'} GL Account`,
          html: `Please approve to ${this.selectedGlAccount.id ? 'update' : 'create'} new account`,
          showCancelButton: true,
          icon: "question"
        }).then(result => {
          if (result.isConfirmed) {
            if(!this.selectedGlAccount.id){
              GlDal.addUserDefinedGlAccount(this.entityId, selectedUserLevel1CategoryId, selectedUserLevel2CategoryId, selectedUserLevel3CategoryId,
                  this.selectedGlAccount.gl_account_name, this.selectedGlAccount.gl_account_description, this.authStore.getUserData.userId, selectedEconomicCategoryId,
                  cashFlowBucketId, directCashFlowBucketId)
                  .then(data => {
                    if(data.result === 'SUCCESS'){
                      Swal.fire('New GL Account', 'The new GL Account added successfully', 'success')
                          .then(()=> {
                            this.$emit('refresh-grid')
                          })
                    }else{
                      Swal.fire('New GL Account', 'The new GL Account creation failed . Please contact your system administrator', 'error')
                    }
                  })
            }else{
              GlDal.updateUserDefinedGlAccount(this.entityId, selectedUserLevel1CategoryId, selectedUserLevel2CategoryId, selectedUserLevel3CategoryId,
                  this.selectedGlAccount.gl_account_name, this.selectedGlAccount.gl_account_description, this.authStore.getUserData.userId, selectedEconomicCategoryId,
                  cashFlowBucketId, directCashFlowBucketId, this.selectedGlAccount.id)
                  .then(data => {
                    if(data.result === 'SUCCESS'){
                      Swal.fire('Update GL Account', 'The GL Account updated successfully', 'success')
                          .then(()=> {
                            this.$emit('refresh-grid')
                          })
                    }else{
                      Swal.fire('Update GL Account', 'GL Account update failed . Please contact your system administrator', 'error')
                    }
                  })
            }
          }
        })
      }
    },

    validateUserInput(){
      if(!this.selectedGlAccount.gl_account_name || this.selectedGlAccount.gl_account_name == ''){
        return "Please set account name"
      }

      if(!this.selectedGlAccount.report_name || this.selectedGlAccount.report_name == ''){
        return "Please set report name"
      }

      if(!this.selectedGlAccount.economic_category_name || this.selectedGlAccount.economic_category_name == ''){
        return "Please select economic category"
      }

      if(!this.selectedGlAccount.system_category_name || this.selectedGlAccount.system_category_name == ''){
        return "Please set system category name"
      }

      if(!this.selectedGlAccount.user_level1_category_name || this.selectedGlAccount.user_level1_category_name == ''){
        return "Please select user level-1 category"
      }

      if(!this.selectedGlAccount.cashflow_bucket_category_name || this.selectedGlAccount.cashflow_bucket_category_name == ''){
        return "Please set system cash-flow bucket category name"
      }

      if(!this.selectedGlAccount.direct_cashflow_bucket_category_name || this.selectedGlAccount.direct_cashflow_bucket_category_name == ''){
        return "Please set system direct cash-flow bucket category name"
      }

      return ""
    },

    removeDuplicates(arr){
      const seen = new Set();
      return arr.filter((obj) => {
        const stringified = JSON.stringify(obj)
        if (seen.has(stringified)) {
          return false
        }
        seen.add(stringified)
        return true
      })
    },

  }
}

</script>

<style scoped>
.gl-form{
  padding: 20px;
}

.form-group{
  padding-bottom: 20px;
}

.form-control:disabled {
  background-color: #fafafa;
}
</style>