<template>
  <div class="docz-component">
    <div class="table-area">
      <table id="datatable" class="table table-hover non-editable"
      ></table>
    </div>
  </div>
</template>

<script>
import BanksDal from "@/dal/banks_dal"
import AccountDal from "@/dal/account_dal";
import { useLoadStore } from "@/stores/load"
import $ from "jquery"
import Swal from "sweetalert2";
import { useEntityStore} from "@/stores/entity"
import readXlsxFile from 'read-excel-file'
import JSZip from "jszip";
import * as XLSX from "@formulajs/formulajs";

export default {
  name: 'AccountingDataOnBoarding',
  data(){
    return {
      storeLoad: useLoadStore(),
      entityStore: useEntityStore(),
      allFilesData: []
    }
  },
  mounted() {
    this.storeLoad.setVisible(true)
    this.getLedgerUploadedFiles()
  },
  methods: {
    getLedgerUploadedFiles(){
      this.storeLoad.setVisible(true)
      AccountDal.getLedgerUploadedFiles(this.entityStore.getCurrentEntity)
          .then(data => {
            this.storeLoad.setVisible(false)
            this.allFilesData = data.data
            let dataColumns = [];
            let dataRows = [];
            data.columnList.forEach((title) => {
              dataColumns.push({
                title: title
                    .split("_")
                    .join(" ")
                    .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                        letter.toUpperCase()
                    ),
              });
            });
            this.allFilesData.forEach((row) => {
              let dataRow = [];
              data.columnList.forEach((value) => {
                dataRow.push(row[value]);
              });
              dataRows.push(dataRow);
            });

            let that = this;
            $("#datatable").DataTable({
              data: dataRows,
              columns: dataColumns,
              pageLength: 5,
              //stateSave: true,
              "aaSorting": [],
              dom: "Blfrtip",
              buttons: [
                "copy",
                "csv",
                "print",
                "excel",
                {
                  text: "New File",
                  action: function (e, dt, node, config) {
                    that.proceedAction("add", 0);
                  },
                },
              ],
              bDestroy: true,
            });
          })
    },

    proceedAction(actionType, id){
      let that = this

      if(actionType === 'add'){
        let entityId = this.entityStore.getCurrentEntity
        let uploadFileData
        Swal.fire({
          title: 'Upload new file',
          html: `<input class="inputFile" type="file" accept=".xlsx,.csv">
                 <label id="errorMessage" style="color: red"></label>`,
          width:600,
          showCancelButton: true,
          didOpen(popup) {

            let fileInput = document.getElementsByClassName('inputFile')[0]

            fileInput.addEventListener('change', async () => {
              that.storeLoad.setVisible(true)
              const file = fileInput.files[0]

              if(file){
                if(that.validateExcelFile(file)){
                  const isValidExcel = await that.isRealExcelFile(file)
                  if(isValidExcel){
                   // const isValidExcelContent = await that.validateExcelContent(file)
                  //  if(isValidExcelContent){
                      readXlsxFile(file).then((rows) => {
                        const isValidExcelContent = that.validateExcelContentRows(rows)
                        if(isValidExcelContent){
                          console.log(rows);                    // `rows` is an array of rows                    // each row being an array of cells.
                        }

                        that.storeLoad.setVisible(false)
                      })
                  //  }
                  }
                }else if (that.validateCSVFile(file)){
                  const isValidCsv = await that.isRealCSVFile(file)
                  if(isValidCsv){
                    const isValidCsvContent = await that.validateCSVContent(file)
                    if(isValidCsvContent){
                      const reader = new FileReader();
                      reader.onload = function (e) {
                        const csv = e.target.result;
                        const jsonArray = that.csvToJson(csv);
                        console.log(jsonArray);
                        that.storeLoad.setVisible(false)
                      };
                      reader.readAsText(file)
                    }

                  }
                }
                that.storeLoad.setVisible(false)
              }
            })

            /*
            fileInput.addEventListener('change',function (event) {
              $("#errorMessage").html('')
              let fileToLoad = event.currentTarget.files[0]
              let fileReader = new FileReader()
              let base64

              fileReader.onload = function(fileLoadedEvent) {
                base64 = fileLoadedEvent.target.result;
                base64 = base64.replace(`data:${fileToLoad.type};base64,`,'')
                uploadFileData = {
                  entityId: entityId,
                  fileName: fileToLoad.name ,
                  fileType: fileToLoad.type ,
                  fileData: base64
                }
              };
              // Convert data to base64
              fileReader.readAsDataURL(fileToLoad);
            })
            */
          },
          preConfirm(inputValue) {
            if(document.getElementsByClassName('inputFile')[0].value === ''){
              $("#errorMessage").html('Please select the file')
              return false
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(uploadFileData.entityId, uploadFileData.fileName, uploadFileData.fileType, uploadFileData.fileData, 'bank-data-on-boarding')
            this.storeLoad.setVisible(true)
            BanksDal.addBankUploadedFile(uploadFileData.entityId, null, uploadFileData.fileName, uploadFileData.fileType, uploadFileData.fileData, 'ledgerdata')
                .then(data => {
                  this.storeLoad.setVisible(false)
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Upload ledger file', 'File uploaded successfully', 'success')
                        .then(() => {
                          this.getLedgerUploadedFiles()
                        })
                  }else{
                    Swal.fire('Upload ledger file', 'File upload failed . Please contact your system administrator', 'error')
                  }
                })
          }
        })
      }
    },

    csvToJson(csv) {
      const lines = csv.split('\n');
      const result = [];

      // Assuming first row contains headers
      const headers = lines[0].split(',');

      for (let i = 1; i < lines.length; i++) {
        const obj = {};
        const currentLine = lines[i].split(',');

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j].trim()] = currentLine[j]?.trim();
        }

        // Avoid adding empty lines
        if (Object.keys(obj).length && Object.values(obj).some(value => value !== '')) {
          result.push(obj);
        }
      }

      return result;
    },

    validateCSVFile(file) {
      const validMimeTypes = ['text/csv', 'application/vnd.ms-excel'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      return validMimeTypes.includes(file.type) || fileExtension === 'csv';
    },

    validateExcelFile(file) {
      const validMimeTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      return validMimeTypes.includes(file.type) || ['xls', 'xlsx'].includes(fileExtension);
    },

    async isRealExcelFile(file) {
      try {
        const zip = await JSZip.loadAsync(file);
        // Check if required Excel files exist within the ZIP structure
        return zip.files['xl/workbook.xml'] !== undefined;
      } catch (error) {
        return false; // The file is not a valid ZIP/Excel file
      }
    },

    async isRealCSVFile(file) {
      try {
        const text = await file.text();
        // Check if the content has rows and columns separated by commas
        const lines = text.split('\n').filter(line => line.trim() !== '');

        // Basic CSV structure check: multiple rows and consistent column count
        const firstLine = lines[0];
        const numOfColumns = firstLine.split(',').length;

        for (let i = 1; i < lines.length; i++) {
          const columns = lines[i].split(',');
          if (columns.length < numOfColumns) {
            return false; // Inconsistent column count
          }
        }

        // Further validation could include ensuring no non-text binary data is present
        return true;
      } catch (error) {
        console.error('Error reading CSV file:', error);
        return false; // The file is not valid CSV
      }
    },

    async validateCSVContent(file) {
      const text = await file.text();
      const lines = text.split('\n');

      for (let line of lines) {
        if (this.containsMaliciousContent(line)) {
          return false;  // Found malicious content
        }
      }

      return true;
    },

    validateExcelContentRows(rows) {
      for (const row of rows) {
        for(const cell of row){
          if (this.containsMaliciousContent(cell)) {
            return false;  // Found malicious content
          }
        }
      }

      return true
    },

    async validateExcelContent(file) {
      try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });

        // Loop through all sheets and their contents
        for (const sheetName of workbook.SheetNames) {
          const sheet = workbook.Sheets[sheetName];
          const sheetContent = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          for (const row of sheetContent) {
            for (const cell of row) {
              if (this.containsMaliciousContent(cell)) {
                return false;  // Found malicious content
              }
            }
          }
        }

        return true;
      } catch (error) {
        console.error('Error reading Excel content:', error);
        return false;
      }
    },

    containsMaliciousContent(content) {
      const maliciousPatterns = [
        /(\b(SELECT|INSERT|DELETE|UPDATE|DROP|ALTER|TRUNCATE|CREATE|EXEC)\b)/i,  // SQL keywords
        /--|\/\*|\*\/|;/g,  // SQL comment and query termination
        /<script[\s\S]*?>[\s\S]*?<\/script>/i,  // Script tags
        /(onload|onerror|onmouseover|onclick|onfocus)=/i,  // JavaScript events
        /['"](\s|\n)*?OR(\s|\n)+['"]/i,  // SQL injection via OR condition
        /['"](\s|\n)*?UNION(\s|\n)+SELECT/i,  // UNION-based SQL injection
   //     /[`~!#$%^&*(){}[\];<>,]/g  // Characters often associated with harmful input
      ];

      // Check if any pattern matches the content
      for (const pattern of maliciousPatterns) {
        if (pattern.test(content)) {
          return true;
        }
      }

      return false;
    }
  }
}
</script>

<style scoped>

</style>