<template>
  <div>
    <input ref="inputFile" type="file" @change="convertToBase64" />
    <div ref="previewDiv" v-if="pdfData">
      <embed :src="pdfData" type="application/pdf" width="100%" height="700px">
    </div>
  </div>

</template>

<script>
import FormData from "form-data";

export default {
  name: 'DocumentUpload',
  data(){
    return {
      pdfData: null
    }
  },
  methods : {
    convertToBase64(event) {

      let that = this
      //Read File
      let selectedFile = this.$refs.inputFile.files;
      //Check File is not Empty
      if (selectedFile.length > 0) {
        // Select the very first file from list
        let fileToLoad = selectedFile[0];
        // FileReader function for read the file.
        let fileReader = new FileReader();
        let base64;
        // Onload of file read the file content
        fileReader.onload = function(fileLoadedEvent) {
          base64 = fileLoadedEvent.target.result;
          base64 = base64.replace('data:application/pdf;base64,','')
          that.showDocument(event)
          that.$emit('file-uploaded', {fileName: that.$refs.inputFile.files[0].name , fileData: base64})
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      }
    },

    showDocument(event){
      const file = event.target.files[0]
      this.fileName = file.name
      const reader = new FileReader();
      let that = this
      reader.onload = () => {
        that.pdfData = reader.result;
      };
      reader.readAsDataURL(file);
      this.fileData = new FormData();
      this.fileData.append('uploadedFile', file);
    },
  }
}
</script>

<style scoped>

</style>