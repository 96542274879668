
<template>
  <div class="docz-component">
    {{entityId}}
    <div class="table-area datatable" :class="{group1 : UserGroupId == 1 , group2: UserGroupId == 2}" ></div>
  </div>
</template>

<script >
import $ from "jquery"
import GlDal from "@/dal/gl_dal"
import { useLoadStore } from "@/stores/load"
import Swal from "sweetalert2"
import {map} from "core-js/internals/array-iteration";
import en from "vue2-datepicker/locale/es/en";

export default {
  name: "GLUserGroup",
  computed: {
    en() {
      return en
    },
    // entityIdProperty() {
    //   return this.entityId
    // }
  },
  props: {
    UserGroupId: Number,
    userDefinedSubGroups: Array,
    entityId: Number
  },

  // watch:{
  //   entityIdProperty: {
  //     immediate: this,
  //     handler(newVal, oldVal) {
  //       console.log('Prop changed: ', newVal, ' | was: ', oldVal)
  //       // this.populateUserGroup()
  //     },
  //     deep: true  // Enable deep watching
  //   }
  // },

  data(){
    return {
      userLevelGroups : this.userDefinedSubGroups,
      storeLoad: useLoadStore()
    }
  },

  mounted() {
    this.populateUserGroup()
  },

  methods:{
    populateUserGroup(){
      let that = this
      let dataColumns = [{title: 'id'}, {title : 'Value'} , {title: 'IS Default'}, {title: ''}]

      let dataRows = this.userLevelGroups.filter(x => x.sub_group_type == `sub_group_${this.UserGroupId}`).map(x => {return [x.id , x.sub_group_name, x.default_group_type_id ? 'Yes' : 'No']})

      $(`.group${this.UserGroupId}`).html("")
      $(`.group${this.UserGroupId}`).append(`<table id="datatable${this.UserGroupId}" class="table table-hover table-striped non-editable" style="font-family: Arial"></table>`)

      $(`#datatable${this.UserGroupId}`).DataTable({
        data: dataRows,
        columns: dataColumns,
        columnDefs: [
          {
            targets: -1,
            width: "80px",
            render: function (data, type, row) {
              return `<button class="icon-btn grid-btn" value="edit"  ${row[2]==='Yes' ? ' disabled ' : ''}><i class='fa fa-edit' style='font-size: 16px;  ${row[2]==='Yes' ? 'opacity: 0.5; cursor: default;' : ''}'></i></button>
                    <button class="icon-btn grid-btn" value="delete"><i class='fa fa-close' style='font-size: 16px' ></i></button>`;
            },
          }
        ],
        drawCallback: function (settings) {
          that.initButtons();
        },
        dom: "Blfrtip",
        buttons: [
          {
            text: "Add",
            action: function (e, dt, node, config) {
              that.addNewUserLevelGroup()
            },
          }
        ],
      })
    },

    initButtons(){
      let that = this;
      let allButtons = document.getElementsByClassName("icon-btn");
      Array.from(allButtons).forEach(function (element) {
        element.addEventListener("click", function (event) {
          that.proceedAction(
              element.value,
              event.target.closest("tr").cells[0].innerText,
              event.target.closest("tr").cells[1].innerText
          );
        });
      });
    },

    proceedAction(actionType,groupId, groupName){
      let that = this
      if(actionType === 'edit'){
        Swal.fire({
          title: 'Update Group Name',
          width: '500px',
          html: `<input type="text" style="width:80%" class="swal2-input" id="group-name" value="${groupName}"/>`,
          showCancelButton: true,
          confirmButtonText: 'Update',
          preConfirm: () => {
            const subGroupName = Swal.getPopup().querySelector('#group-name').value
            return { actionType, groupId, subGroupName ,entityId: that.entityId, subGroupId: that.UserGroupId }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            GlDal.updateUserDefinedGroup(result.value.entityId, result.value.subGroupName,  result.value.groupId , result.value.subGroupId,)
                .then(data => {
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Edit Group' , 'Group updated successfully', 'success')
                        .then(() => {
                          this.userLevelGroups.find( x=> x.id == result.value.groupId && x.sub_group_type == `sub_group_${this.UserGroupId}` ).sub_group_name = result.value.subGroupName
                          that.populateUserGroup()
                        })
                  }else{
                    Swal.fire('Edit Group', 'Failed to edit the group', 'error')
                  }
                })
          }
        })
      }

      if(actionType === 'delete'){
        Swal.fire({
          title: 'Delete Group Name',
          width: '500px',
          html: `<p>Approve to delete group : ${groupName}</p>`,
          showCancelButton: true,
          confirmButtonText: 'Delete'
        }).then((result) => {
          if (result.isConfirmed) {
            GlDal.deleteUserDefinedGroup(groupId).then(
                data => {
                  if(data.result === 'SUCCESS'){
                    Swal.fire('Delete Group' , 'Group delete successfully', 'success')
                        .then(() => {
                          this.userLevelGroups = this.userLevelGroups.filter(x => x.id != data.data[0].id)
                          this.$emit('user-level-groups-changed', this.userLevelGroups)
                          that.populateUserGroup()
                        })
                  }else{
                    Swal.fire('Edit Group', 'Failed to edit the group', 'error')
                  }
                }
            )
          }
        })
      }
    },

    addNewUserLevelGroup(){
      Swal.fire({
        title: `Enter The User Level #${this.UserGroupId} Group Name`,
        width: '500px',
        html: `<input type="text" style="width:80%" class="swal2-input" id="userLevelGroupInput" list="userLevelGroupDataList"/>
                <datalist id="userLevelGroupDataList"></datalist>`,
        showCancelButton: true,
        confirmButtonText: 'Add',
        preConfirm: () => {
          const userLevelGroupName = Swal.getPopup().querySelector('#userLevelGroupInput').value
          return { userLevelGroupName }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.storeLoad.setVisible(true)
          let groupName = result.value.userLevelGroupName
          let defaultGroup = this.userLevelGroups.filter(x => x.sub_group_type === 'default').find(x => x.sub_group_name === groupName)

          GlDal.addUserDefinedGroup(this.entityId, groupName, defaultGroup ? defaultGroup.id : null, this.UserGroupId)
              .then(data => {
                this.storeLoad.setVisible(false)
                if(data.result === 'SUCCESS'){
                  Swal.fire('New user-group', 'New user-group added successfully.', 'success')
                      .then(async result => {
                        console.log({sub_group_type:`sub_group_${this.UserGroupId}` , default_group_type_id: this.UserGroupId , id: data.data[0].id, sorting_id: null,  sub_group_name: groupName })
                        this.userLevelGroups.push({sub_group_type:`sub_group_${this.UserGroupId}` , default_group_type_id: defaultGroup ? this.UserGroupId : null , id: data.data[0].id, sorting_id: null,  sub_group_name: groupName })
                        this.populateUserGroup()
                      })
                }else {
                  Swal.fire('New user-group', 'New user-group adding failed . Please contact your system administrator', 'error')
                }
              })
        }
      })

      let existingGroups = this.userLevelGroups.filter(x => x.sub_group_type == `sub_group_${this.UserGroupId}`).map(x => {return x})
      console.log(existingGroups)

      let options = (this.userLevelGroups.filter(x=> x.sub_group_type == 'default'))
      options.forEach((option) => {
        let newOption = document.createElement("option")
        newOption.setAttribute("data-value", option.id)
        newOption.text = option.sub_group_name
        if(!existingGroups.find(x => x.sub_group_name === newOption.text)){
          $("#userLevelGroupDataList").append(newOption)
        }

      })
    },

    async addUserLevelGroup() {
      const {value: groupName} = await Swal.fire({
        title: `Enter The User Level #${this.UserGroupId} Group Name`,
        input: "text",
        inputLabel: "Group Name",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        }
      });
      if(groupName){
        this.storeLoad.setVisible(true)
        GlDal.addUserDefinedGroup(this.entityId, groupName, null, this.UserGroupId)
            .then(data => {
              this.storeLoad.setVisible(false)
              if(data.result === 'SUCCESS'){
                Swal.fire('New user-group', 'New user-group added successfully.', 'success')
                    .then(async result => {
                        this.userLevelGroups.push({sub_group_type:`sub_group_${this.UserGroupId}` , default_group_type_id: this.UserGroupId , id: data.data[0].id, sorting_id: null,  sub_group_name: groupName })
                        this.populateUserGroup()
                    })
              }else {
                Swal.fire('New user-group', 'New user-group adding failed . Please contact your system administrator', 'error')
              }
            })
      }
    }
  },
}
</script>


<style scoped>

</style>